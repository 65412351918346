import {
  AuthPublicGatewayCreateResponseDto,
  AuthPublicGatewayStatusDto,
  AuthPublicRegisterRequestDto,
  AuthPublicLoginRequestDto,
  AuthPublicAuthorizeResponseDto,
  ClientDto,
  ClientUpdateMeDto,
  AuthPublicGatewayCreateRequestDto,
} from 'src/types/generated';
import BaseApiModule from 'src/api/base';

class AuthApi extends BaseApiModule {
  protected baseRoute = '/auth';

  public gatewayCreate(model?: AuthPublicGatewayCreateRequestDto) {
    return this._post<AuthPublicGatewayCreateResponseDto>(`${this.baseRoute}/gateway-create`, model);
  }

  public gatewayStatus(returnToken) {
    return this._get<AuthPublicGatewayStatusDto>(`${this.baseRoute}/gateway/${returnToken}/status`);
  }

  public register(model: AuthPublicRegisterRequestDto) {
    return this._post<AuthPublicAuthorizeResponseDto>(`${this.baseRoute}/register`, model);
  }

  public login(model: AuthPublicLoginRequestDto) {
    return this._post<AuthPublicAuthorizeResponseDto>(`${this.baseRoute}/login`, model);
  }

  public me() {
    return this._get<ClientDto>(`${this.baseRoute}/me`);
  }

  public updateMe(model: ClientUpdateMeDto) {
    return this._patch<ClientDto>(`${this.baseRoute}/update-me`, model);
  }

  public syncBonusPoints() {
    return this._post<ClientDto>(`${this.baseRoute}/sync-bonus-points`);
  }
}

export default AuthApi;
