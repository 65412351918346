import { useTranslation } from 'react-i18next';

import WorkInProgressContainer from 'src/components/WorkInProgressContainer';

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <WorkInProgressContainer name={t('404. Page not found.')}>
      <a href="/">Follow to main page</a>
    </WorkInProgressContainer>
  );
};

export default NotFoundPage;
