import { useEffect } from 'react';
import { useIonViewWillEnter } from '@ionic/react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DetailsLayout from 'src/components/layout/DetailsLayout';
import ComboCampaign from 'src/components/campaign/ComboCampaign';
import ComboCampaignFooter from 'src/components/campaign/ComboCampaignFooter';
import { useBreakpoint } from 'src/hooks';

interface MenuDetailsProps extends RouteComponentProps {}

const CampaignComboPage: React.FC<MenuDetailsProps> = ({ history, location, match }) => {
  const { isDesktopView } = useBreakpoint();
  const { t } = useTranslation();

  const routerProps = { history, location, match };

  useIonViewWillEnter(() => {
    if (isDesktopView) {
      history.replace('/tabs/menu');
    }
  }, [isDesktopView]);

  useEffect(() => {
    if (isDesktopView && location?.pathname === '/tabs/menu/combo-campaign') {
      history.replace('/tabs/menu');
    }
  }, [isDesktopView, location?.pathname]);

  return (
    <DetailsLayout
      meta={{
        title: t('-SEO Combo campaign page'),
        description: t('-SEO Combo campaign page description'),
      }}
      footer={<ComboCampaignFooter />}
      sidePadding={false}
      {...routerProps}
    >
      <ComboCampaign />
    </DetailsLayout>
  );
};

export default CampaignComboPage;
