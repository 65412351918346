import classNames from 'classnames';

import ProductImage from 'src/components/menu/products/ProductImage';
import { ProductJoinInfoDto } from 'src/types/generated';
import classes from './DetailsHeader.module.scss';
import textClasses from 'src/styles/text.module.scss';

interface DetailsHeaderProps {
  product: ProductJoinInfoDto;
}

const DetailsHeader: React.FC<DetailsHeaderProps> = ({ product }) => {
  return (
    <>
      <div className={classNames(classes.header, 'ion-text-center')}>
        <h1 className={textClasses.headerLBold}>{product.name}</h1>
        <ProductImage imageSrc={product.imageThumbnailUrl} imgClass={classes.img} />
        <p className={textClasses.textM}>{product.description}</p>
      </div>
    </>
  );
};

export default DetailsHeader;
