import { ReactNode } from 'react';
import PropTypes from 'prop-types';

import 'src/components/WorkInProgressContainer.scss';

interface ContainerProps {
  name: string;
  children?: ReactNode;
}

const WorkInProgressContainer: React.FC<ContainerProps> = ({ name, children }) => {
  return (
    <div className="container">
      <strong>{name}</strong>
      <p>Work in progress!</p>
      {children}
    </div>
  );
};

WorkInProgressContainer.propTypes = {
  name: PropTypes.string.isRequired,
};

export default WorkInProgressContainer;
