import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18next';

import API from 'src/api';
import { removeStorageItem, setStorageItem } from 'src/utils/storage';
import { updateMe } from 'src/slices/auth';
import type { AppThunk } from 'src/store';
import { LanguageDto, StaticPageTemplateJoinInfoDto, SystemTranslationDto } from 'src/types/generated';

interface LoadingState {
  app?: boolean;
}

interface SelectLanguagePayload {
  languageId: number;
  saveSelection?: boolean;
}

interface SystemTranslationsGroupedByLang {
  [key: string]: SystemTranslationDto[];
}

interface SystemState {
  staticPages: StaticPageTemplateJoinInfoDto[];
  languages: LanguageDto[];
  selectedLanguageId: number;
  systemTranslations: SystemTranslationsGroupedByLang;
  isBottomNavigationDisplayed: boolean;
  loading: LoadingState;
  hasInternetConnection: boolean;
  isAppInitialized: boolean;
}

const initialState: SystemState = {
  staticPages: null,
  languages: null,
  selectedLanguageId: null,
  systemTranslations: {},
  isBottomNavigationDisplayed: true,
  hasInternetConnection: true,
  isAppInitialized: false,
  loading: {
    app: false,
  },
};

const slice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    getStaticPages(state: SystemState, action: PayloadAction<StaticPageTemplateJoinInfoDto[]>) {
      state.staticPages = action.payload;
    },
    getLanguages(state: SystemState, action: PayloadAction<LanguageDto[]>): void {
      state.languages = action.payload;
    },
    setSystemTranslations(state: SystemState, action: PayloadAction<SystemTranslationsGroupedByLang>) {
      state.systemTranslations = { ...state.systemTranslations, ...action.payload };
    },
    selectLanguage(state: SystemState, action: PayloadAction<number>): void {
      state.selectedLanguageId = action.payload;
    },
    setBottomNavigationDisplay(state: SystemState, action: PayloadAction<boolean>): void {
      state.isBottomNavigationDisplayed = action.payload;
    },
    setInternetConnection(state: SystemState, action: PayloadAction<boolean>): void {
      state.hasInternetConnection = action.payload;
    },
    setAppInitialization(state: SystemState, action: PayloadAction<boolean>): void {
      state.isAppInitialized = action.payload;
    },
    setLoading(state: SystemState, action: PayloadAction<LoadingState>): void {
      state.loading = { ...state.loading, ...action.payload };
    },
  },
});

export const { reducer } = slice;

const saveLanguageSelection =
  (languageId: number): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { isAuthenticated } = getState().auth;

    await setStorageItem('selectedLanguageId', String(languageId));
    if (isAuthenticated) {
      await dispatch(updateMe({ languageId }));
    }
  };

const removeLanguageSelection =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { isAuthenticated } = getState().auth;

    await removeStorageItem('selectedLanguageId');
    if (isAuthenticated) {
      await dispatch(updateMe({ languageId: null }));
    }
  };

export const getStaticPages =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const data = await API.staticPages.getAll();
    dispatch(slice.actions.getStaticPages(data));
  };

export const getLanguages =
  (): AppThunk =>
  async (dispatch): Promise<LanguageDto[]> => {
    let languages: LanguageDto[] = [];
    try {
      languages = await API.language.getAll();
      dispatch(slice.actions.getLanguages(languages));
    } catch (_) {
      // eslint-disable-next-line no-console
      console.log('Error during fetching languages');
    }
    return languages;
  };

export const selectLanguage =
  ({ languageId, saveSelection = true }: SelectLanguagePayload): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { languages, systemTranslations } = getState().system;
    const { id: languageExists, isoName: languageCode } = languages.find((lng) => lng.id === languageId) || {};

    if (!languageExists) {
      dispatch(slice.actions.selectLanguage(null));
      await dispatch(removeLanguageSelection());
      return;
    }

    const languageTranslations =
      systemTranslations[languageCode] || (await API.systemTranslations.getByLanguageId(languageId));
    const languageResources = languageTranslations.reduce((acc, translation) => {
      acc[translation.key] = translation.value;
      return acc;
    }, {});
    i18n.addResourceBundle(languageCode, 'translation', languageResources);
    await i18n.changeLanguage(languageCode);
    if (saveSelection) await dispatch(saveLanguageSelection(languageId));
    dispatch(slice.actions.selectLanguage(languageId));
    if (!systemTranslations[languageCode]) {
      dispatch(slice.actions.setSystemTranslations({ [languageCode]: languageTranslations }));
    }
  };

export const setBottomNavigationDisplay =
  (isDisplayed: boolean): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setBottomNavigationDisplay(isDisplayed));
  };

export const setLoading =
  (payload: LoadingState): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setLoading(payload));
  };

export const setInternetConnection =
  (hasInternetConnection: boolean): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setInternetConnection(hasInternetConnection));
    dispatch(setBottomNavigationDisplay(hasInternetConnection));
  };

export const setAppInitialization =
  (isAppInitialized: boolean): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setAppInitialization(isAppInitialized));
  };

export default slice;
