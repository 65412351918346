import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Capacitor } from '@capacitor/core';

import DetailsLayout from 'src/components/layout/DetailsLayout';
import IdentityGateway from 'src/components/auth/IdentityGateway';
import IdentityGatewayIframe from 'src/components/auth/IdentityGatewayIframe';
import RegistrationForm from 'src/components/auth/RegistrationForm';
import { useSelector } from 'src/store';

interface ProfileAuthProps extends RouteComponentProps {}

const ProfileAuthPage: React.FC<ProfileAuthProps> = ({ history, location, match }) => {
  const { t } = useTranslation();
  const gatewayStatusData = useSelector((state) => state.auth.gatewayStatusData);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const showIdentityGateway = !gatewayStatusData && !isAuthenticated;
  const showRegistrationForm = gatewayStatusData && !isAuthenticated;
  const routerProps = { history, location, match };
  const isNative = Capacitor.isNativePlatform();

  const onAuthCompleted = () => {
    history.goBack();
  };

  return (
    <DetailsLayout
      meta={{
        title: t('-SEO Profile auth page'),
        description: t('-SEO Profile auth page description'),
      }}
      {...routerProps}
    >
      {!isNative && showIdentityGateway && <IdentityGateway onCompleted={onAuthCompleted} />}
      {isNative && showIdentityGateway && <IdentityGatewayIframe onCompleted={onAuthCompleted} />}
      {showRegistrationForm && <RegistrationForm onCompleted={onAuthCompleted} />}
    </DetailsLayout>
  );
};

export default ProfileAuthPage;
