import { IonButton, IonIcon } from '@ionic/react';

import classes from './CloseButton.module.scss';
import { closeOutline } from 'ionicons/icons';

interface CloseButtonProps {
  onClose: () => void | Promise<void>;
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClose }) => (
  <IonButton color="white" onClick={onClose} shape="round" className={classes.closeBtn}>
    <IonIcon icon={closeOutline} className={classes.closeBtnIcon} />
  </IonButton>
);

export default CloseButton;
