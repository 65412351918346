import Image from 'src/components/Image';
import classes from './ProductImage.module.scss';
import pizzaPlaceholderImg from 'src/assets/img/pizzakiosk-logo.svg';

interface ProductImageProps {
  imageSrc: string;
  imgClass?: string;
  placeholderImgClass?: string;
}

const ProductImage: React.FC<ProductImageProps> = ({ imageSrc, imgClass, placeholderImgClass }) => {
  return (
    <Image
      src={imageSrc}
      placeholderSrc={pizzaPlaceholderImg}
      className={imgClass || classes.img}
      placeholderClassName={placeholderImgClass || classes.imgPlaceholder}
    />
  );
};

export default ProductImage;
