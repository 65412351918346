import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonButton, IonIcon, IonText } from '@ionic/react';
import classNames from 'classnames';

import { useDispatch } from 'src/store';
import { useAddOnSalesGetter } from 'src/hooks';
import { formatPrice } from 'src/utils';
import { addProductToCart } from 'src/slices/product';
import classes from './AddOnSales.module.scss';
import textClasses from 'src/styles/text.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';
import plusSignIcon from 'src/assets/img/plus-sign.svg';

const AddOnSales: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addOnSales, addOnSalesSubCategories } = useAddOnSalesGetter();
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(addOnSalesSubCategories?.[0]?.id);
  const addOnSalesByCategory = addOnSales.filter((addOn) => addOn.categories.some((c) => c.id === selectedCategoryId));

  const onAddOnCategoryClick = (categoryId: number) => {
    setSelectedCategoryId(categoryId);
  };

  const onAddOnClick = (productId: number) => {
    dispatch(addProductToCart(productId));
  };

  return (
    <div className="ion-margin-bottom">
      <h2 className={classNames(textClasses.titleBold, 'ion-margin-bottom')}>{t('Pizzakiosk recommends')}</h2>
      {addOnSalesSubCategories?.length > 0 &&
        addOnSalesSubCategories.map((c) => (
          <IonButton
            key={c.id}
            onClick={() => onAddOnCategoryClick(c.id)}
            color={selectedCategoryId === c.id ? 'primary' : 'light'}
            className={classNames(btnClasses.chipBtn, classes.categoryBtn)}
          >
            <IonText className={classes.categoryBtnText}>{c.name}</IonText>
          </IonButton>
        ))}
      <div className={classes.list}>
        {addOnSalesByCategory.map(({ id, name, price }) => (
          <div key={id} className={classes.item}>
            <span className={textClasses.textM}>{name}</span>
            <div>
              <IonText color="primary" className={classNames(textClasses.textL, classes.itemPrice)}>
                {formatPrice(price)}
              </IonText>
              <IonButton
                color="white"
                className={classNames(btnClasses.controlBtn, classes.itemAddBtn)}
                onClick={() => onAddOnClick(id)}
              >
                <IonIcon color="success" slot="icon-only" src={plusSignIcon} className={classes.addOnBtnIcon} />
              </IonButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddOnSales;
