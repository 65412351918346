import React from 'react';
import { Capacitor } from '@capacitor/core';
import { IonButton, IonCol, IonGrid, IonIcon, IonRippleEffect, IonRow, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { closeOutline, reloadOutline } from 'ionicons/icons';
import classNames from 'classnames';
import toast, { useToaster } from 'react-hot-toast';
import { AppUpdate } from '@capawesome/capacitor-app-update';

import classes from './AppUpdateNotification.module.scss';
import textClasses from 'src/styles/text.module.scss';

interface AppUpdateNotificationProps {
  tst: any;
  appId: string;
}

const AppUpdateNotification: React.FC<AppUpdateNotificationProps> = ({ tst, appId }) => {
  const { t } = useTranslation();
  const { handlers } = useToaster();
  const isNative = Capacitor.isNativePlatform();

  return (
    <div className={classNames('ripple-parent ion-activatable', classes.container)}>
      <IonGrid className="ion-no-padding">
        <IonRow>
          <IonCol
            size="12"
            onClick={async () => {
              if (isNative && appId) {
                try {
                  await AppUpdate.openAppStore();
                } catch (error) {
                  console.error('Failed to open app in store: ', error);
                }
              }
            }}
          >
            <div className={classNames(textClasses.titleBold, classes.header)}>
              <IonIcon icon={reloadOutline} className={classes.updateIcon} color="danger" />
              <h3 className={classNames(textClasses.titleBold, classes.title)}>
                <IonText color="danger">{t('App update title')}</IonText>
              </h3>
              <IonButton
                color="white"
                shape="round"
                className={classes.closeBtn}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toast.dismiss(tst?.id);
                  handlers.endPause();
                }}
              >
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </div>
            <p className={textClasses.textM}>{t('App update description')}</p>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonRippleEffect />
    </div>
  );
};

export default AppUpdateNotification;
