import { StaticPageTemplateJoinInfoDto } from 'src/types/generated';
import BaseReadApiModule from 'src/api/baseRead';

class StaticPageTemplateApi extends BaseReadApiModule<StaticPageTemplateJoinInfoDto | null> {
  protected baseRoute = '/static-pages';

  public async getPageBySlug(slug: string): Promise<StaticPageTemplateJoinInfoDto> {
    return this._get(`${this.baseRoute}/slug/${slug}`);
  }
}

export default StaticPageTemplateApi;
