import { orderBy, uniqBy } from 'lodash';

import { useSelector } from 'src/store';
import { getCampaignProductPrice } from 'src/utils/campaign';
import { formatNumber, numberFallback as num } from 'src/utils';
import { getCustomizedPizzaComponents } from 'src/utils/product';
import { ProductComponentQty } from 'src/slices/product';
import { CampaignCartItemDto, ProductCartItemDto, ProductVariantJoinInfoDto } from 'src/types/generated';

export const useCategoryProductsGetter = () => {
  const { categories, products, selectedCategoryId } = useSelector((state) => state.product);

  if (!products || !categories) {
    return [];
  }

  return products.filter((p) =>
    p.categories.some((c) => {
      const categoryData = categories.find((cd) => cd.id === c.id);
      return c.id === selectedCategoryId || categoryData.parentId === selectedCategoryId;
    })
  );
};

export const useComponentsGetter = () => {
  const { categories, products } = useSelector((state) => state.product);
  if (!categories || !products) return { components: [], componentCategories: [] };

  const components = products.filter((p) => p.categories.some((c) => c.isComponent));
  const categoriesOfAllComponents = uniqBy(components.map((c) => c.categories).flat(), 'id').map((c) => c.id);
  const componentCategories = categories.filter((c) => c.isComponent).map((c) => c.id);
  const componentCategoryChildren = categories.filter(
    (c) => componentCategories.includes(c.parentId) && categoriesOfAllComponents.includes(c.id)
  );

  return { components, componentCategories: componentCategoryChildren };
};

export const useAddOnSalesGetter = () => {
  const { categories, products } = useSelector((state) => state.product);
  if (!categories || !products) return { addOnSales: [], addOnSalesSubCategories: [] };

  const addOnSales = products.filter((p) => p.categories.some((c) => c.isAddOnSale));
  const isAddOnSalesCategoriesIds = categories.filter((c) => c.isAddOnSale).map((c) => c.id);
  const addOnSalesAllCategoriesIds = uniqBy(addOnSales.map((c) => c.categories).flat(), 'id').map((c) => c.id);
  const addOnSalesSubCategories = categories.filter(
    (c) => isAddOnSalesCategoriesIds.includes(c.parentId) && addOnSalesAllCategoriesIds.includes(c.id)
  );

  return { addOnSales, addOnSalesSubCategories };
};

export const useProductPriceGetter = (productId: number) => {
  const { products, selectedVariants, productComponentQuantities, productExtraComponentQuantities } = useSelector(
    (state) => state.product
  );

  const product = products.find((p) => p.id === productId);
  const selectedProductVariants = selectedVariants.filter((v) => v.productId === product.id);
  const variantPriceData = product.variants.find((v) => {
    const isSelected = selectedProductVariants.some((x) => x.variantId === v.id);
    const isAnotherSelected = selectedProductVariants.some((x) => x.groupId === v.groupId && x.variantId !== v.id);
    return v.price && (isSelected || (v.isDefault && !isAnotherSelected));
  });
  const basePrice = variantPriceData?.price || product.price;
  const removedComponentsDiscount = productComponentQuantities
    .filter((component) => component.productId === product.id && component.qty <= 0)
    .map((component) => products.find((p) => p.id === component.componentId)?.price)
    .reduce((sum, price) => {
      sum = sum + price || 0;
      return sum;
    }, 0);
  let componentsPrice = productComponentQuantities
    .filter((component) => component.productId === product.id && component.qty > 1)
    .map((component) => products.find((p) => p.id === component.componentId)?.price * (component.qty - 1))
    .reduce((sum, price) => {
      sum = sum + price || 0;
      return sum;
    }, 0);
  let extraComponents = productExtraComponentQuantities;
  if (product.isCustomized) {
    const { paidComponents } = getCustomizedPizzaComponents<ProductComponentQty>([
      ...productComponentQuantities.map((component) => ({ ...component, qty: component.qty - 1 })),
      ...productExtraComponentQuantities,
    ]);
    componentsPrice = 0;
    extraComponents = paidComponents;
  }

  const extraComponentsPrice = extraComponents
    .filter((component) => component.productId === product.id && component.qty > 0)
    .map((component) => products.find((p) => p.id === component.componentId)?.price * component.qty)
    .reduce((sum, price) => {
      sum = sum + price || 0;
      return sum;
    }, 0);
  const totalPrice = basePrice + componentsPrice + extraComponentsPrice - removedComponentsDiscount;
  return Math.max(totalPrice, basePrice);
};

export const useCartPriceGetter = () => {
  const { products } = useSelector((state) => state.product);
  const { cartItems } = useSelector((state) => state.cart);
  const productCartItems = cartItems.filter((ci) => ci.isProduct);
  const campaignCartItems = cartItems.filter((ci) => ci.isCampaign);
  const cartItemPrices = { total: 0 } as {
    [key: number]: { base: number; total: number; originalBase?: number; originalTotal?: number };
    total: number;
  };

  if (!products) return cartItemPrices;

  productCartItems.forEach((cartItem) => {
    const { uniqId: cartItemId, partials, variantIds, productId, quantity, bonusedQuantity } = cartItem || {};
    const {
      price: productBasePrice,
      originalPrice: productOriginalBasePrice,
      variants: productVariants,
      isCustomized: productIsCustomized,
    } = products.find((p) => p.id === productId) || {};
    const variantPrice = num(
      productVariants.find((pv) => {
        const isSelected = variantIds.includes(pv.id);
        return isSelected && pv?.price > 0;
      })?.price
    );
    const originalVariantPrice = num(
      productVariants.find((pv) => {
        const isSelected = variantIds.includes(pv.id);
        return isSelected && pv?.price > 0;
      })?.originalPrice
    );

    const basePrice = variantPrice || productBasePrice;
    const originalBasePrice = variantPrice ? originalVariantPrice : productOriginalBasePrice;
    const removedPartialsDiscount = partials
      .filter((partial) => partial.quantity <= 0)
      .map((partial) => {
        const { price, originalPrice } = (products || []).find((p) => p.id === partial.partialProductId) || {};
        return originalPrice || price;
      })
      .reduce((sum, price) => {
        sum += num(price);
        return sum;
      }, 0);

    let partialsData = partials;
    if (productIsCustomized) {
      const { paidComponents } = getCustomizedPizzaComponents<ProductCartItemDto['partials'][0]>(partials);
      partialsData = paidComponents;
    }

    const partialsPrice = partialsData
      .filter((partial) => partial.quantity > 0)
      .map(({ partialProductId, quantity: partialQty }) => {
        const { price: partialPrice } = products.find((p) => p.id === partialProductId) || {};
        return num(partialPrice) * partialQty;
      })
      .reduce((sum, price) => {
        sum += num(price);
        return sum;
      }, 0);
    const originalPartialsPrice = partialsData
      .filter((partial) => partial.quantity > 0)
      .map(({ partialProductId, quantity: partialQty }) => {
        const { originalPrice: partialPrice } = products.find((p) => p.id === partialProductId) || {};
        return num(partialPrice) * partialQty;
      })
      .reduce((sum, price) => {
        sum += num(price);
        return sum;
      }, 0);
    const maxPrice = Math.max(basePrice + partialsPrice - removedPartialsDiscount, basePrice);
    const basePriceQuantity = quantity - num(bonusedQuantity);
    const totalPrice = basePrice * basePriceQuantity + (maxPrice - basePrice) * quantity;
    const originalMaxPrice = Math.max(
      originalBasePrice + originalPartialsPrice - removedPartialsDiscount,
      originalBasePrice
    );
    const originalTotalPrice =
      originalBasePrice * basePriceQuantity + (originalMaxPrice - originalBasePrice) * quantity;
    cartItemPrices[cartItemId] = {
      base: formatNumber(basePrice),
      originalBase: formatNumber(originalBasePrice),
      total: formatNumber(totalPrice),
      originalTotal: formatNumber(originalTotalPrice),
    };
    cartItemPrices.total += formatNumber(totalPrice);
  });
  campaignCartItems.forEach((cartItem) => {
    const { products: campaignProducts, uniqId: cartItemId, quantity } = cartItem;
    const paidProducts = campaignProducts.filter((sp) => sp.isFreeSlot === false);
    let campaignPrice = paidProducts
      .map(({ productId, variantId, partials }) =>
        getCampaignProductPrice({ productId, variantId, products, partials })
      )
      .reduce((totalPrice, price) => totalPrice + price, 0);
    campaignPrice *= quantity;
    cartItemPrices[cartItemId] = {
      base: formatNumber(campaignPrice),
      total: formatNumber(campaignPrice),
    };
    cartItemPrices.total += formatNumber(campaignPrice);
  });
  return cartItemPrices;
};

export const useOrderedStaticPagesGetter = () => {
  const { staticPages } = useSelector((state) => state.system);
  return staticPages ? orderBy(staticPages, ['orderNumber'], ['asc']) : [];
};

export const useSelectedSalePointGetter = () => {
  const { selectedSalePointId, salePoints } = useSelector((state) => state.salePoint);
  return salePoints?.find((s) => s.id === selectedSalePointId) ?? null;
};

export const useSelectedCampaignGetter = () => {
  const { selectedCampaignId, campaigns } = useSelector((state) => state.campaign);
  return campaigns?.find((c) => c.id === selectedCampaignId) ?? null;
};

function getCampaignBonusPoints(
  campaign: Partial<ProductCartItemDto & CampaignCartItemDto>,
  variants: ProductVariantJoinInfoDto[]
): number {
  if (!campaign.isCampaign || !campaign.products) return 0;
  return campaign.products.reduce((acc: number, product) => {
    if (product.isFreeSlot) return acc; // if product is free as part of campaign no bonus points are given
    const variantData = variants.find((variant) => product.variantId === variant.id && variant.bonusPointsAccumulator);
    return acc + (variantData?.bonusPointsAccumulator || 0);
  }, 0);
}

export const useCartBonusPointsGetter = () => {
  const { cartItems } = useSelector((state) => state.cart);
  const { variants } = useSelector((state) => state.product);
  const { campaigns } = useSelector((state) => state.campaign);
  const discountCampaigns = (campaigns || []).filter((campaing) => campaing.type === 'discount');

  const costBonusPoints = cartItems
    .filter((cartItem) => cartItem.isProduct && cartItem.bonusedQuantity)
    .map((cartItem) => {
      const { variantIds, bonusedQuantity } = cartItem;
      const variantData = variants.find((variant) => variantIds.includes(variant.id) && variant.bonusPointsCost);
      return (variantData?.bonusPointsCost || 0) * bonusedQuantity;
    })
    .reduce((acc, bonusPointsCost) => acc + bonusPointsCost, 0);

  const accumulatedBonusPoints = cartItems
    .filter(
      (cartItem) =>
        cartItem.isProduct &&
        cartItem.quantity > (cartItem.bonusedQuantity || 0) && // filter products that are not payed with bonus points
        !discountCampaigns.find(
          (campaing) =>
            campaing.products.findIndex(
              (product) =>
                product.productId === cartItem.productId &&
                (!product.variantId ||
                  cartItem.variantIds.length === 0 ||
                  cartItem.variantIds.includes(product.variantId))
            ) !== -1
        ) && // filter products that are part of discount campaign (discount campaign products do not give bonus points)
        !cartItem.isCampaign
    ) // filter campaigns as campaigns are calculated separatly to separate logic
    .map((cartItem) => {
      const { variantIds, quantity, bonusedQuantity } = cartItem;
      const paidQuantity = quantity - (bonusedQuantity || 0);
      const variantData = variants.find(
        (variant) => (variantIds || []).includes(variant.id) && variant.bonusPointsAccumulator
      );
      return (variantData?.bonusPointsAccumulator || 0) * paidQuantity;
    })
    .reduce((acc, bonusPointsAccumulator) => acc + bonusPointsAccumulator, 0);

  const campaignAccumulatedBonusPoints = cartItems
    .filter((cartItem) => cartItem.isCampaign)
    .reduce((acc, campaign) => acc + getCampaignBonusPoints(campaign, variants) * (campaign.quantity || 0), 0);

  return { costBonusPoints, accumulatedBonusPoints: accumulatedBonusPoints + campaignAccumulatedBonusPoints };
};

export const useAvailableBonusPointsGetter = () => {
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  if (!isAuthenticated) return 0;

  return Math.max(user.bonusPoints - user.bookedBonusPoints, 0);
};

export const useProductsCheckoutTimeGetter = () => {
  const { cartItems } = useSelector((state) => state.cart);
  const { products } = useSelector((state) => state.product);
  let checkoutQuantityRecalculated = false;

  return (cartItems || [])
    .map((ci) => {
      if (ci.isProduct) {
        const productCheckoutTime = (products || []).find((product) => product.id === ci.productId)?.checkoutTime;
        let checkoutQuantity = ci.quantity;
        if (!checkoutQuantityRecalculated && productCheckoutTime) {
          checkoutQuantity -= 1;
          checkoutQuantityRecalculated = true;
        }
        return productCheckoutTime * checkoutQuantity;
      }

      if (ci.isCampaign) {
        return ci.products.reduce((sum, { productId }) => {
          const productCheckoutTime = (products || []).find((product) => product.id === productId)?.checkoutTime;
          let checkoutQuantity = ci.quantity;
          if (!checkoutQuantityRecalculated && productCheckoutTime) {
            checkoutQuantity -= 1;
            checkoutQuantityRecalculated = true;
          }
          return sum + productCheckoutTime * checkoutQuantity;
        }, 0);
      }

      return 0;
    })
    .reduce((sum, checkoutTime) => sum + checkoutTime, 0);
};
