import React from 'react';
import { Capacitor } from '@capacitor/core';

import { useSelector } from 'src/store';
import classes from './IosSafeAreaBottom.module.scss';

const IosSafeAreaBottom = () => {
  const platform = Capacitor.getPlatform();
  const isNative = Capacitor.isNativePlatform();
  const { isBottomNavigationDisplayed } = useSelector((state) => state.system);

  return (
    <>
      {isNative && platform === 'ios' && !isBottomNavigationDisplayed && (
        <div>
          <div className={classes.iosSafeAreaOffset} />
          <div className={classes.iosSafeAreaPlaceholder} />
        </div>
      )}
    </>
  );
};

export default IosSafeAreaBottom;
