import { ReactNode } from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';

import classes from './BackButton.module.scss';

interface BackButtonProps {
  routerLink?: string;
  onClick?: () => void | Promise<void>;
  children?: ReactNode;
}

const BackButton: React.FC<BackButtonProps> = ({ routerLink, onClick, children }) => {
  const onBackButtonClickMobile = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <IonButton
      color="white"
      shape="round"
      className={classes.backBtn}
      routerLink={routerLink}
      onClick={onBackButtonClickMobile}
    >
      <IonIcon slot="icon-only" icon={arrowBack} className={classes.backBtnIcon} />
      {children}
    </IonButton>
  );
};

export default BackButton;
