import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ProfilePageLayout from 'src/components/layout/ProfilePageLayout';
import MyData from 'src/components/profile/MyData';

interface ProfileMyDataPageProps extends RouteComponentProps {}

const ProfileMyDataPage: React.FC<ProfileMyDataPageProps> = ({ history, location, match }) => {
  const { t } = useTranslation();
  const routerProps = { history, location, match };

  return (
    <ProfilePageLayout
      title={t('My data')}
      meta={{
        title: t('-SEO Profile my data page'),
        description: t('-SEO Profile my data page description'),
      }}
      {...routerProps}
    >
      <MyData />
    </ProfilePageLayout>
  );
};

export default ProfileMyDataPage;
