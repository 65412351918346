import { add, getISODay, set } from 'date-fns';
import { SalePointAvailableTimeDto, SalePointWorkingDayDto } from 'src/types/generated';
import { toSQLDate } from './time';

export const getTimeDate = (date: Date, startTime: string) => {
  const hours = Number(startTime.split(':')?.[0]);
  const minutes = Number(startTime.split(':')?.[1]);
  const timeDate = set(date, { hours, minutes });
  return timeDate;
};

export const getNextWorkingDayData = (
  workingDays: SalePointWorkingDayDto[],
  availableTimes: SalePointAvailableTimeDto[],
  selectedDate: Date
) => {
  const weekDay = getISODay(selectedDate);
  const {
    start: nextDayStart,
    end: nextDayEnd,
    isoWeekDate: nextWeekDay,
  } = workingDays
    .filter((x) => x.isoWeekDate !== weekDay && x.isoWeekDate > weekDay)
    .concat(workingDays.filter((x) => x.isoWeekDate !== weekDay && x.isoWeekDate < weekDay))
    .map((workingDay) => {
      const { isoWeekDate } = workingDay;
      const additionalDays = isoWeekDate > weekDay ? isoWeekDate - weekDay : 7 - weekDay + isoWeekDate;
      const date = toSQLDate(add(selectedDate, { days: additionalDays }));
      const availableTime = availableTimes.find((at) => at.date === date);
      const { start, end } = availableTime || workingDay;
      return { ...workingDay, start, end };
    })
    .find((x) => x.start && x.end) || {};
  if (!nextDayStart || !nextDayEnd) return { nextStartWorkingDate: null, nextEndWorkingDate: null };
  const addDays = nextWeekDay > weekDay ? nextWeekDay - weekDay : 7 - weekDay + nextWeekDay;
  const nextWorkingDate = add(selectedDate, { days: addDays });
  return {
    nextStartWorkingDate: getTimeDate(nextWorkingDate, nextDayStart),
    nextEndWorkingDate: getTimeDate(nextWorkingDate, nextDayEnd),
  };
};
