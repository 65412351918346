import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { IonButton, IonCheckbox, IonLabel, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import toast from 'react-hot-toast';

import FormInput from 'src/components/FormInput';
import { updateMe } from 'src/slices/auth';
import { useDispatch, useSelector } from 'src/store';
import { formatDate } from 'src/utils/time';
import { DESKTOP_VIEW } from 'src/constants';
import classes from './MyData.module.scss';
import textClasses from 'src/styles/text.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';

interface FormValuesDto {
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  checkedNewsletter: boolean;
}

const MyData: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { birthDate, phoneNumber, email, firstName, lastName, isSubscribedToNewsletter } =
    useSelector((state) => state.auth.user) || {};

  const onMyDataFormSubmit = async (values: FormValuesDto) => {
    await dispatch(
      updateMe({
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        email: values.email?.trim(),
        isSubscribedToNewsletter: Boolean(values.email) && values.checkedNewsletter,
      })
    );
    toast.success(t('Your data was successfully updated'));
  };

  return (
    <div>
      <h3 className={classNames(textClasses.titleBold, classes.header, DESKTOP_VIEW)}>{t('My data')}</h3>
      <Formik
        enableReinitialize
        initialValues={{
          phoneNumber,
          email,
          firstName,
          lastName,
          birthDate: formatDate(new Date(birthDate)),
          checkedNewsletter: isSubscribedToNewsletter,
        }}
        validationSchema={Yup.object().shape({
          phoneNumber: Yup.string()
            .matches(/^\+[1-9]{1}\d{3,49}$/, t('Invalid format, enter +[country code][phone number]'))
            .max(50)
            .required(t('This is mandatory field')),
          email: Yup.string().trim().email(t('Email should be valid')).max(255),
          firstName: Yup.string()
            .min(3, t('First name must be at least 3 characters'))
            .max(255)
            .required(t('This is mandatory field')),
          lastName: Yup.string()
            .min(3, t('Last name must be at least 3 characters'))
            .max(255)
            .required(t('This is mandatory field')),
          birthDate: Yup.string().matches(
            /^([0-2]\d|3[0-1])\.(0\d|1[0-2])\.\d\d\d\d$/,
            t('Invalid date format - dd.mm.yyyy')
          ),
          checkedNewsletter: Yup.bool(),
        })}
        onSubmit={onMyDataFormSubmit}
      >
        {({ errors, handleBlur, setFieldValue, touched, values }): JSX.Element => (
          <Form>
            <FormInput
              value={values.firstName}
              name="firstName"
              placeholder={t('Name')}
              error={Boolean(errors.firstName) && errors.firstName}
              onChange={(value) => setFieldValue('firstName', value)}
              onBlur={handleBlur}
            />
            <FormInput
              value={values.lastName}
              name="lastName"
              placeholder={t('Surname')}
              error={Boolean(errors.lastName) && errors.lastName}
              onChange={(value) => setFieldValue('lastName', value)}
              onBlur={handleBlur}
            />
            <FormInput
              value={values.birthDate}
              name="birthDate"
              placeholder={t('Birth date')}
              error={Boolean(errors.birthDate) && errors.birthDate}
              disabled
            />
            <FormInput
              value={values.phoneNumber}
              name="phoneNumber"
              placeholder={t('Phone number')}
              error={Boolean(touched.phoneNumber && errors.phoneNumber) && errors.phoneNumber}
              onChange={(value) => setFieldValue('phoneNumber', value)}
              onBlur={handleBlur}
            />
            <FormInput
              value={values.email}
              name="email"
              placeholder={t('Email')}
              error={Boolean(touched.email && errors.email) && errors.email}
              onChange={(value) => setFieldValue('email', value)}
              onBlur={handleBlur}
            />
            {values.email && (
              <div className={btnClasses.checkboxContainer}>
                <IonCheckbox
                  checked={values.checkedNewsletter}
                  onIonChange={(e) => setFieldValue('checkedNewsletter', e.detail.checked)}
                />
                <IonLabel>{t('I want to receive newsletters')}</IonLabel>
              </div>
            )}
            <IonButton className={btnClasses.primaryBtn} type="submit" expand="block">
              <IonText className={textClasses.title}>{t('Confirm')}</IonText>
            </IonButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MyData;
