import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { omit, sortBy } from 'lodash';
import toast from 'react-hot-toast';

import API from 'src/api';
import { i18next } from 'src/hooks/useDataLoading';
import { ProductJoinInfoDto, ProductCategoryJoinInfoDto, ProductVariantJoinInfoDto } from 'src/types/generated';
import type { AppThunk } from 'src/store';
import { addCartItem } from 'src/slices/cart';
import { trackProductView } from 'src/utils/analytics';

interface SelectedVariant {
  productId: number;
  groupId: number;
  variantId: number;
}

export interface ProductComponentQty {
  productId: number;
  componentId: number;
  qty: number;
}

interface SelectedExtraComponentCategories {
  [key: string]: number;
}

interface ProductQuantities {
  [key: string]: number;
}

interface LoadingState {
  products?: boolean;
  categories?: boolean;
}

interface ProductState {
  products: ProductJoinInfoDto[] | null;
  categories: ProductCategoryJoinInfoDto[] | null;
  variants: ProductVariantJoinInfoDto[] | null;
  selectedProductId: number | null;
  selectedCategoryId: number | null;
  selectedVariants: SelectedVariant[];
  productComponentQuantities: ProductComponentQty[];
  productExtraComponentQuantities: ProductComponentQty[];
  selectedExtraComponentCategories: SelectedExtraComponentCategories;
  productQuantities: ProductQuantities;
  loading: LoadingState;
  isProductDetailsOpened: boolean;
}

const initialState: ProductState = {
  products: null,
  categories: null,
  variants: null,
  selectedProductId: null,
  selectedCategoryId: null,
  selectedVariants: [],
  productComponentQuantities: [],
  productExtraComponentQuantities: [],
  selectedExtraComponentCategories: {},
  productQuantities: {},
  isProductDetailsOpened: false,
  loading: {
    products: false,
    categories: false,
  },
};

const mapProductOptions = (
  state: ProductState,
  payloadData: any,
  optionsName: string,
  idColumn: string,
  modifiedColumn: string
) => {
  const findOption = (x) => x.productId === payloadData.productId && x[idColumn] === payloadData[idColumn];
  const stateOption = state[optionsName].find(findOption);
  return stateOption
    ? state[optionsName]
        .map((x) => {
          const isModifiedOption = x.productId === payloadData.productId && x[idColumn] === payloadData[idColumn];
          const modifiedValue = payloadData[modifiedColumn];
          if (isModifiedOption && optionsName === 'productComponentQuantities' && modifiedValue === 1) {
            return null;
          }
          if (isModifiedOption && optionsName === 'productExtraComponentQuantities' && modifiedValue === 0) {
            return null;
          }
          return { ...x, [modifiedColumn]: isModifiedOption ? modifiedValue : x[modifiedColumn] };
        })
        .filter((option) => option)
    : state[optionsName].concat(payloadData);
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    getProducts(state: ProductState, action: PayloadAction<ProductJoinInfoDto[]>): void {
      state.products = action.payload;
    },
    getCategories(state: ProductState, action: PayloadAction<ProductCategoryJoinInfoDto[]>): void {
      state.categories = action.payload;
      const defaultCategory = action.payload.find((c) => c.isDefault);
      if (!state.selectedCategoryId && defaultCategory) {
        state.selectedCategoryId = defaultCategory.id;
      }
    },
    getVariants(state: ProductState, action: PayloadAction<ProductVariantJoinInfoDto[]>): void {
      state.variants = action.payload;
    },
    selectCategory(state: ProductState, action: PayloadAction<number>): void {
      state.selectedCategoryId = action.payload;
    },
    selectDefaultCategory(state: ProductState): void {
      const defaultCategoryId = state.categories ? state.categories.find((c) => c.isDefault)?.id : null;
      if (defaultCategoryId) {
        state.selectedCategoryId = defaultCategoryId;
      }
    },
    selectProduct(state: ProductState, action: PayloadAction<number>): void {
      state.selectedProductId = action.payload;
    },
    selectProductVariant(state: ProductState, action: PayloadAction<SelectedVariant>): void {
      state.selectedVariants = mapProductOptions(state, action.payload, 'selectedVariants', 'groupId', 'variantId');
    },
    setProductComponents(state: ProductState, action: PayloadAction<ProductComponentQty[]>): void {
      state.productComponentQuantities = action.payload;
    },
    setProductComponentQty(state: ProductState, action: PayloadAction<ProductComponentQty>): void {
      state.productComponentQuantities = mapProductOptions(
        state,
        action.payload,
        'productComponentQuantities',
        'componentId',
        'qty'
      );
    },
    setProductExtraComponents(state: ProductState, action: PayloadAction<ProductComponentQty[]>): void {
      state.productExtraComponentQuantities = action.payload;
    },
    setProductExtraComponentQty(state: ProductState, action: PayloadAction<ProductComponentQty>): void {
      state.productExtraComponentQuantities = mapProductOptions(
        state,
        action.payload,
        'productExtraComponentQuantities',
        'componentId',
        'qty'
      );
    },
    selectExtraComponentCategory(state: ProductState, action: PayloadAction<SelectedExtraComponentCategories>): void {
      state.selectedExtraComponentCategories = { ...state.selectedExtraComponentCategories, ...action.payload };
    },
    setProductQty(state: ProductState, action: PayloadAction<ProductQuantities>): void {
      state.productQuantities = { ...state.productQuantities, ...action.payload };
    },
    setLoading(state: ProductState, action: PayloadAction<LoadingState>): void {
      state.loading = { ...state.loading, ...action.payload };
    },
    openProductDetails(state: ProductState, action: PayloadAction<boolean>): void {
      state.isProductDetailsOpened = action.payload;
    },
    resetAllProductsSelections(state: ProductState): void {
      state.selectedProductId = null;
      state.selectedVariants = [];
      state.productComponentQuantities = [];
      state.productExtraComponentQuantities = [];
      state.selectedExtraComponentCategories = {};
      state.productQuantities = {};
    },
    resetProductSelections(state: ProductState, action: PayloadAction<number>): void {
      const productId = action.payload;
      state.selectedVariants = state.selectedVariants.filter((sv) => sv.productId !== productId);
      state.productComponentQuantities = state.productComponentQuantities.filter((sv) => sv.productId !== productId);
      state.productExtraComponentQuantities = state.productExtraComponentQuantities.filter(
        (sv) => sv.productId !== productId
      );
      state.selectedExtraComponentCategories = omit(state.selectedExtraComponentCategories, [productId]);
      state.productQuantities = omit(state.selectedExtraComponentCategories, [productId]);
    },
  },
});

export const { reducer } = slice;

export const getProducts =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setLoading({ products: true }));
    try {
      const products = await API.product.getAll();
      dispatch(slice.actions.getProducts(sortBy(products, ['name'])));
    } finally {
      dispatch(slice.actions.setLoading({ products: false }));
    }
  };

export const getCategories =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setLoading({ categories: true }));
    try {
      const data = await API.productCategory.getAll();

      dispatch(slice.actions.getCategories(data));
    } finally {
      dispatch(slice.actions.setLoading({ categories: false }));
    }
  };

export const getVariants =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setLoading({ products: true }));
    try {
      const data = await API.productVariant.getAll();

      dispatch(slice.actions.getVariants(data));
    } finally {
      dispatch(slice.actions.setLoading({ products: false }));
    }
  };

export const selectCategory =
  (id?: number): AppThunk =>
  async (dispatch): Promise<void> => {
    const categoryId = Number.isFinite(id) && id > 0 ? id : null;
    dispatch(slice.actions.selectCategory(categoryId));
  };

export const selectDefaultCategory =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.selectDefaultCategory());
  };

export const selectProduct =
  (id?: number): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const productId = Number.isFinite(id) && id > 0 ? id : null;
    const selectedProduct = getState().product.products.find((product) => product.id === id);
    trackProductView(selectedProduct);
    dispatch(slice.actions.selectProduct(productId));
  };

export const selectProductVariant =
  (selectedVariant: SelectedVariant): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.selectProductVariant(selectedVariant));
  };

export const setProductComponents =
  (productComponents: ProductComponentQty[]): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setProductComponents(productComponents));
  };

export const setProductComponentQty =
  (productComponentQty: ProductComponentQty): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setProductComponentQty(productComponentQty));
  };

export const setProductExtraComponents =
  (productExtraComponents: ProductComponentQty[]): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setProductExtraComponents(productExtraComponents));
  };

export const setProductExtraComponentQty =
  (productExtraComponentQty: ProductComponentQty): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setProductExtraComponentQty(productExtraComponentQty));
  };

export const selectExtraComponentCategory =
  (selectedExtraComponentCategory: SelectedExtraComponentCategories): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.selectExtraComponentCategory(selectedExtraComponentCategory));
  };

export const setProductQty =
  (qty: ProductQuantities): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setProductQty(qty));
  };

export const openProductDetails =
  (state?: boolean): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.openProductDetails(state));
  };

export const resetAllProductsSelections =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.resetAllProductsSelections());
  };

export const resetProductSelections =
  (productId: number): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.resetProductSelections(productId));
  };

export const addProductToCart =
  (productId: number): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const {
      productComponentQuantities,
      productExtraComponentQuantities,
      selectedVariants,
      productQuantities,
      products,
    } = getState().product;
    const product = products.find((p) => p.id === productId);
    if (!product) return;
    const productQty = productQuantities[productId] || 1;
    const productComponents = productComponentQuantities
      .filter((pcq) => pcq.productId === productId && pcq.qty !== 1)
      .map((pcq) => ({ ...pcq, qty: pcq.qty > 0 ? pcq.qty - 1 : 0 }));
    const productExtraComponents = productExtraComponentQuantities.filter(
      (pcq) => pcq.productId === productId && pcq.qty > 0
    );
    const selectedProductVariants = selectedVariants.filter((sv) => sv.productId === productId);
    const variantIds = product.variants
      .filter((pv) => {
        const isGroupSelected = selectedProductVariants.some((sv) => sv.groupId === pv.groupId);
        const isSelected = selectedProductVariants.some((sv) => sv.groupId === pv.groupId && sv.variantId === pv.id);
        return isSelected || (!isGroupSelected && pv.isDefault);
      })
      .map((pv) => pv.id);
    const cartItem = {
      productId,
      quantity: productQty,
      partials: [...productComponents, ...productExtraComponents].map((c) => ({
        partialProductId: c.componentId,
        quantity: c.qty,
      })),
      variantIds,
      uniqId: uuidv4(),
      isProduct: true,
    };
    dispatch(addCartItem(cartItem));
    dispatch(slice.actions.resetProductSelections(productId));
    toast.success(i18next.t('Product added to cart'));
  };

export default slice;
