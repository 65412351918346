import { IonContent, IonModal } from '@ionic/react';
import classNames from 'classnames';

import AddVip from 'src/components/vip/AddVip';
import AddVipGuestFallback from 'src/components/vip/AddVipGuestFallback';
import CloseButton from 'src/components/CloseButton';
import { useDispatch, useSelector } from 'src/store';
import { openModal } from 'src/slices/vip';
import classes from './AddVipModal.module.scss';

const AddVipModal: React.FC = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isAddVipModalOpened = useSelector((state) => state.vip.isAddVipModalOpened);

  const onCloseBtnClick = () => {
    dispatch(openModal(false));
  };

  return (
    <IonModal isOpen={isAddVipModalOpened} backdropDismiss={false} cssClass={classNames(classes.modal)}>
      <IonContent>
        <div className={classes.closeBtnContainer}>
          <CloseButton onClose={onCloseBtnClick} />
        </div>
        {isAuthenticated && <AddVip />}
        {!isAuthenticated && <AddVipGuestFallback />}
      </IonContent>
    </IonModal>
  );
};

export default AddVipModal;
