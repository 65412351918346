import React from 'react';
import { chain } from 'lodash';
import { useTranslation } from 'react-i18next';
import { IonGrid, IonList, IonRow } from '@ionic/react';
import classNames from 'classnames';

import CampaignProductItem from 'src/components/campaign/CampaignProductItem';
import { useBreakpoint, useSelectedCampaignGetter } from 'src/hooks';
import { useDispatch, useSelector } from 'src/store';
import {
  ComboCampaignModalViews,
  openComboCampaignProducts,
  selectSlotProduct,
  setComboCampaignModalView,
} from 'src/slices/campaign';
import { CampaignJoinInfoDto } from 'src/types/generated';
import classes from './ComboCampaignProductList.module.scss';
import textClasses from 'src/styles/text.module.scss';

const ComboCampaignProductList: React.FC = () => {
  const campaign = useSelectedCampaignGetter();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isDesktopView, isMobileView } = useBreakpoint();
  const products = useSelector((state) => state.product.products);
  const { slotParameters, selectedSlotProducts } = useSelector((state) => state.campaign);

  if (!campaign) return null;

  const { products: campaignProducts } = campaign;
  const { isFreeSlot, slotNumber } = slotParameters;
  const selectedSlot = selectedSlotProducts.find((sp) => sp.isFreeSlot === isFreeSlot && sp.slotNumber === slotNumber);
  const slotCampaignProducts = campaignProducts.filter(
    (cp) => cp.isFreeSlot === isFreeSlot && cp.slotNumber === slotNumber
  );

  const onItemSelect = (campaignProduct) => {
    dispatch(selectSlotProduct(campaignProduct));

    if (isDesktopView) {
      dispatch(setComboCampaignModalView(ComboCampaignModalViews.MAIN));
    }

    if (isMobileView) {
      dispatch(openComboCampaignProducts(false));
    }
  };

  return (
    <div className={classes.content}>
      <h1 className={classNames(textClasses.headerLBold, 'ion-text-center')}>{t('Edit')}</h1>
      <IonList>
        <IonGrid className="ion-no-padding">
          <IonRow>
            {slotCampaignProducts.map((campaignProduct) => {
              const { productId, variantId } = campaignProduct;
              const product = (products || []).find((p) => p.id === campaignProduct.productId);

              if (productId && (variantId || !product?.variants?.length)) {
                const isSelected =
                  selectedSlot?.productId === campaignProduct.productId &&
                  selectedSlot?.variantId === campaignProduct.variantId;
                return (
                  <CampaignProductItem
                    key={`${productId}${variantId}`}
                    campaignProduct={campaignProduct}
                    product={product}
                    onItemSelect={onItemSelect}
                    isSelected={isSelected}
                  />
                );
              }

              if (productId && !variantId && product?.variants?.length > 0) {
                const productVariants = product.variants.map((v) => ({ ...campaignProduct, variantId: v.variantId }));
                return productVariants.map((campaignVariantProduct) => {
                  const isSelected =
                    selectedSlot?.productId === campaignVariantProduct.productId &&
                    selectedSlot?.variantId === campaignVariantProduct.variantId;
                  return (
                    <CampaignProductItem
                      key={`${productId}${campaignVariantProduct.variantId}`}
                      campaignProduct={campaignVariantProduct}
                      product={product}
                      onItemSelect={onItemSelect}
                      isSelected={isSelected}
                    />
                  );
                });
              }

              if (!productId && variantId) {
                const variantProducts = products.filter((p) => p.variants.some((pv) => pv.variantId === variantId));
                const campaignVariantProducts: CampaignJoinInfoDto['products'] = chain(variantProducts)
                  .map('variants')
                  .flatten()
                  .filter({ variantId })
                  .map((pv) => ({ ...campaignProduct, productId: pv.productId, variantId: pv.variantId }))
                  .value();
                return campaignVariantProducts.map((campaignVariantProduct) => {
                  const variantProduct = products.find((p) => p.id === campaignVariantProduct.productId);
                  const isSelected =
                    selectedSlot?.productId === campaignVariantProduct.productId &&
                    selectedSlot?.variantId === campaignVariantProduct.variantId;
                  return (
                    <CampaignProductItem
                      key={`${campaignVariantProduct.productId}${variantId}`}
                      campaignProduct={campaignVariantProduct}
                      product={variantProduct}
                      onItemSelect={onItemSelect}
                      isSelected={isSelected}
                    />
                  );
                });
              }

              return null;
            })}
          </IonRow>
        </IonGrid>
      </IonList>
    </div>
  );
};

export default ComboCampaignProductList;
