import { isInteger } from 'lodash';
import numeral from 'numeral';

interface Point {
  longitude: number;
  latitude: number;
}

/**
 * Calculates distance between two points, returns distance in meters.
 */
export const getDistanceBetweenTwoPoints = (pointA: Point, pointB: Point) => {
  const DEGREES_IN_ONE_RADIAN = 57.2958;
  const EARTH_RADIUS = 6378.1;
  const convertedPointA = {
    latitude: pointA.latitude / DEGREES_IN_ONE_RADIAN,
    longitude: pointA.longitude / DEGREES_IN_ONE_RADIAN,
  };
  const convertedPointB = {
    latitude: pointB.latitude / DEGREES_IN_ONE_RADIAN,
    longitude: pointB.longitude / DEGREES_IN_ONE_RADIAN,
  };
  const distance =
    EARTH_RADIUS *
    Math.acos(
      Math.sin(convertedPointA.latitude) * Math.sin(convertedPointB.latitude) +
        Math.cos(convertedPointA.latitude) *
          Math.cos(convertedPointB.latitude) *
          Math.cos(convertedPointB.longitude - convertedPointA.longitude)
    ) *
    1000;
  return distance;
};

export const toSlug = (text: string | number) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[õö]/gi, 'o')
    .replace(/ä/gi, 'a')
    .replace(/ü/gi, 'u')
    .replace(/š/gi, 's')
    .replace(/ž/gi, 'z')
    .replace(/а/gi, 'a')
    .replace(/б/gi, 'b')
    .replace(/в/gi, 'v')
    .replace(/г/gi, 'g')
    .replace(/д/gi, 'd')
    .replace(/е/gi, 'e')
    .replace(/ё/gi, 'jo')
    .replace(/ж/gi, 'zh')
    .replace(/з/gi, 'z')
    .replace(/и/gi, 'i')
    .replace(/й/gi, 'ij')
    .replace(/к/gi, 'k')
    .replace(/л/gi, 'l')
    .replace(/м/gi, 'm')
    .replace(/н/gi, 'n')
    .replace(/о/gi, 'o')
    .replace(/п/gi, 'p')
    .replace(/р/gi, 'r')
    .replace(/с/gi, 's')
    .replace(/т/gi, 't')
    .replace(/у/gi, 'u')
    .replace(/ф/gi, 'f')
    .replace(/х/gi, 'h')
    .replace(/ц/gi, 'z')
    .replace(/ч/gi, 'ch')
    .replace(/ш/gi, 'sh')
    .replace(/щ/gi, 'shch')
    .replace(/ъ/gi, 'q')
    .replace(/ы/gi, 'y')
    .replace(/ь/gi, 'j')
    .replace(/э/gi, 'e')
    .replace(/ю/gi, 'ju')
    .replace(/я/gi, 'ja')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};

export const getBirthDateFromPersonalCode = (client: { countryCode: string; personalCode: string }) => {
  const { countryCode, personalCode } = client || {};
  if ((countryCode !== 'ee' && countryCode !== 'lt') || !personalCode) return new Date();

  const centuryYear = personalCode[0] === '3' || personalCode[0] === '4' ? '19' : '20';
  const year = personalCode.slice(1, 3);
  const month = personalCode.slice(3, 5);
  const day = personalCode.slice(5, 7);
  return new Date(Number(`${centuryYear}${year}`), Number(month) - 1, Number(day));
};

export const formatPrice = (price: string | number) => {
  if (isInteger(Number(price))) {
    return numeral(price).format('0 $');
  }
  return numeral(price).format('0.00 $');
};

export const isIntId = (id: string | number) => {
  return Number.isFinite(Number(id)) && Number(id) > 0;
};

export const formatNumber = (number: number, numbersAfterComa: number = 2) => {
  if (!number) return number;

  return parseFloat(number.toFixed(numbersAfterComa));
};

export const checkInternetConnection = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_PIZZAKIOSK_API_URI}/server-status`);
    return response.status >= 200 && response.status < 300;
  } catch (error) {
    return false;
  }
};

export const isMobileUserAgent = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const numberFallback = (value: number | null | undefined) => {
  return value || 0;
};
