import React, { useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Swiper as SwiperJS, Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useDispatch, useSelector } from 'src/store';
import { useBreakpoint } from 'src/hooks';
import { openComboCampaign, selectCampaign, initComboCampaign } from 'src/slices/campaign';
import { CampaignJoinInfoDto } from 'src/types/generated';
import { CampaignTypes, CampaignUrlTarget } from 'src/types/interfaces';
import classes from './Campaigns.module.scss';
import { selectCategory } from 'src/slices/product';

SwiperJS.use([Autoplay, Navigation, Pagination]);

interface CampaignsProps extends RouteComponentProps {}

const _getSwiperPagination = (isDesktopView: boolean) => {
  return isDesktopView
    ? {
        clickable: true,
        bulletClass: `swiper-pagination-bullet ${classes.swiperBullet}`,
      }
    : false;
};

const _getImageType = (isDesktopView: boolean) => {
  return isDesktopView ? 'desktop' : 'mobile';
};

const Campaigns: React.FC<CampaignsProps> = ({ history }) => {
  const { isDesktopView, isMobileView } = useBreakpoint();
  const dispatch = useDispatch();
  const swiperRef = useRef(null);
  const focusAreaBtnRef = useRef<HTMLButtonElement>(null);
  const campaigns = useSelector((state) => state.campaign.campaigns);
  const staticPages = useSelector((state) => state.system.staticPages);
  const selectedLanguageId = useSelector((state) => state.system.selectedLanguageId);
  const imageType = _getImageType(isDesktopView);

  const onCampaignImgLoad = () => {
    swiperRef?.current?.swiper?.update();
  };

  const onCampaignClick = async (campaign: CampaignJoinInfoDto) => {
    const { type } = campaign;

    if (type === CampaignTypes.STATIC_PAGE) {
      const { staticPageId } = campaign;
      const staticPage = staticPages.find((page) => page.id === staticPageId);

      if (staticPage?.slug) {
        history.push(`/tabs/contact/${staticPage.slug}`);
      }
      return;
    }

    if (type === CampaignTypes.URL) {
      const { url, urlTarget } = campaign;
      if (url) {
        window.open(url, urlTarget === CampaignUrlTarget.Self ? '_self' : '_blank');
      }
      return;
    }

    if (type === CampaignTypes.CATEGORY) {
      const { categoryId } = campaign;
      if (categoryId) {
          await dispatch(selectCategory(campaign.categoryId));
        }
      return;
    }

    if (type === CampaignTypes.COMBO) {
      await dispatch(selectCampaign(campaign.id));
      await dispatch(initComboCampaign());
      if (isDesktopView) {
        focusAreaBtnRef?.current?.focus?.();
        await dispatch(openComboCampaign(true));
      }
      if (isMobileView) {
        history.push('/tabs/menu/combo-campaign');
      }
      return;
    }

    return null;
  };

  // function to determine will we be able to show at least 1 campaign after picture filter based on current language
  // EXAMPLE campaigns array consists of 1 campaign, however there is no picture suitable for current language so no need to show campaigns swiper
  const hasCampaignsForUserLocale =
    campaigns?.some((c) => c?.images?.some((i) => i?.languageId === selectedLanguageId)) ?? false;

  return (
    hasCampaignsForUserLocale && (
      <div className={classes.campaigns}>
        <button className={classes.focusAreaBtn} type="button" aria-hidden ref={focusAreaBtnRef} />
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          navigation
          pagination={_getSwiperPagination(isDesktopView)}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          autoHeight
          // @ts-ignore
          ref={swiperRef}
        >
          {campaigns &&
            campaigns.map((campaign) => {
              const image = campaign.images.find(
                (i) => i.imageType === imageType && i.languageId === selectedLanguageId
              );
              if (!image) return null;
              return (
                <SwiperSlide key={campaign.id} onClick={() => onCampaignClick(campaign)}>
                  <img
                    src={image.imageFullUrl}
                    alt={`Campaign ${campaign.name}`}
                    className={classes.swiperImg}
                    onLoad={onCampaignImgLoad}
                  />
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    )
  );
};

export default Campaigns;
