import { groupBy, orderBy } from 'lodash';
import { IonButton, IonText } from '@ionic/react';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'src/store';
import { selectProductVariant } from 'src/slices/product';
import { ProductJoinVariantsDto } from 'src/types/generated';
import classes from './ProductVariants.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';

interface ProductVariantsProps {
  productVariants: ProductJoinVariantsDto[];
}

const ProductVariants: React.FC<ProductVariantsProps> = ({ productVariants }) => {
  const dispatch = useDispatch();
  const { selectedVariants } = useSelector((state) => state.product);
  const groupedVariants = groupBy(productVariants, 'groupId');

  const onVariantClick = (variant: ProductJoinVariantsDto) => {
    dispatch(selectProductVariant({ productId: variant.productId, groupId: variant.groupId, variantId: variant.id }));
  };

  return (
    <div className="ion-text-center">
      {Object.values(groupedVariants).map((variants) => (
        <div key={variants[0].groupId} className={classNames(classes.variantButtons)}>
          {orderBy(variants, ['id'], ['asc']).map((v, index) => {
            const selectedVariant = selectedVariants.find(
              (x) => x.productId === v.productId && x.groupId === v.groupId
            )?.variantId;
            const hasDefault = variants.some((x) => x.isDefault);
            const isSelected =
              selectedVariant === v.id ||
              (!selectedVariant && v.isDefault) ||
              (!selectedVariant && !hasDefault && index === 0);
            return (
              <IonButton
                key={v.id}
                onClick={() => onVariantClick(v)}
                color={isSelected ? 'primary' : 'light'}
                className={classNames(btnClasses.chipBtn, classes.variantBtn)}
              >
                <IonText className={classes.variantBtnText}>{v.name}</IonText>
              </IonButton>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default ProductVariants;
