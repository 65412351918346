import { useEffect, useRef, useState } from 'react';
import { Swiper as SwiperJS, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import { IonIcon, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';

import DetailsLayout from 'src/components/layout/DetailsLayout';
import ProductDetails from 'src/components/menu/ProductDetails';
import DetailsFooter from 'src/components/menu/details/DetailsFooter';
import { useBreakpoint, useCategoryProductsGetter } from 'src/hooks';
import { useDispatch, useSelector } from 'src/store';
import { resetAllProductsSelections, selectProduct } from 'src/slices/product';
import classes from './MenuDetails.module.scss';

interface MenuDetailsProps extends RouteComponentProps {}

SwiperJS.use([Virtual]);

const MenuDetails: React.FC<MenuDetailsProps> = ({ history, location, match }) => {
  const swiperRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedProductId } = useSelector((state) => state.product);
  const { isDesktopView } = useBreakpoint();
  const [scrollToTop, setScrollToTop] = useState<number>(0);
  const categoryProducts = useCategoryProductsGetter();
  const productIndex = categoryProducts.findIndex((p) => p.id === selectedProductId);
  const product = categoryProducts.find((p) => p.id === selectedProductId);
  const routerProps = { history, location, match };

  const onDetailsHeightChange = () => {
    if (swiperRef) {
      swiperRef?.current?.swiper?.update();
    }
  };

  const onSlideChange = (swiper) => {
    setScrollToTop(Date.now());
    const slidedToProduct = categoryProducts.find((_, index) => index === swiper?.activeIndex);
    if (slidedToProduct) {
      dispatch(selectProduct(slidedToProduct.id));
    }
  };

  useIonViewWillEnter(() => {
    swiperRef?.current?.swiper?.slideTo(productIndex, 0);
    swiperRef?.current?.swiper?.update();
  }, [productIndex]);

  useIonViewWillEnter(() => {
    if (isDesktopView) {
      history.replace('/tabs/menu');
    }
  }, [isDesktopView]);

  useEffect(() => {
    if (isDesktopView && location?.pathname === '/tabs/menu/details') {
      history.replace('/tabs/menu');
    }
  }, [isDesktopView, location?.pathname]);

  useIonViewWillLeave(() => {
    dispatch(resetAllProductsSelections());
  }, []);

  const layoutFooter = <>{product && <DetailsFooter product={product} />}</>;

  return (
    <DetailsLayout
      meta={{
        title: t('-SEO Menu details page'),
        description: t('-SEO Menu details page description'),
      }}
      footer={layoutFooter}
      sidePadding={false}
      {...routerProps}
      scrollToTop={scrollToTop}
    >
      <IonIcon icon={chevronBackOutline} color="medium" className={classNames(classes.arrowIcon, classes.backIcon)} />
      <IonIcon
        icon={chevronForwardOutline}
        color="medium"
        className={classNames(classes.arrowIcon, classes.forwardIcon)}
      />
      <Swiper
        slidesPerView={1}
        pagination={false}
        autoHeight={Boolean(true)}
        onSlideChange={onSlideChange}
        // @ts-ignore
        ref={swiperRef}
        virtual
      >
        {categoryProducts.map((p, index) => (
          <SwiperSlide key={p.id} virtualIndex={index}>
            <ProductDetails product={p} onContentHeightChange={onDetailsHeightChange} />
          </SwiperSlide>
        ))}
      </Swiper>
    </DetailsLayout>
  );
};

export default MenuDetails;
