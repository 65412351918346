import {
  SalePointAvailableTimeDto,
  SalePointWorkingDayDto,
  SalepointProductDto,
  SalePointDto,
} from 'src/types/generated';

import BaseReadApiModule from 'src/api/baseRead';

class SalePointApi extends BaseReadApiModule<SalePointDto> {
  protected baseRoute = '/sale-points';

  public getAvailableTimes(id: string) {
    return this._get<SalePointAvailableTimeDto[]>(`${this.baseRoute}/${id}/available-times`);
  }

  public getWorkingDays(id: string) {
    return this._get<SalePointWorkingDayDto[]>(`${this.baseRoute}/${id}/working-days`);
  }

  public getProducts(id: string) {
    return this._get<SalepointProductDto[]>(`${this.baseRoute}/${id}/products`);
  }
}

export default SalePointApi;
