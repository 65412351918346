import { Capacitor } from '@capacitor/core';
import { LocationAccuracy } from '@ionic-native/location-accuracy';
import { Geolocation } from '@capacitor/geolocation';
import { Device } from '@capacitor/device';

import { DEFAULT_LOCALE } from 'src/constants';
import { LanguageDto } from 'src/types/generated';

export interface Position {
  longitude: number;
  latitude: number;
}

export class LocationService {
  static async askToTurnOnGPS(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      try {
        const canRequest = await LocationAccuracy.canRequest();
        if (canRequest) await LocationAccuracy.request(LocationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY);
      } catch (error) {
        throw Error(error?.message || error);
      }
    }
  }

  static async getPosition(): Promise<Position | null> {
    try {
      await this.askToTurnOnGPS(); // For native platforms only
      const position = await Geolocation.getCurrentPosition();
      const { latitude, longitude } = position?.coords || {};

      return latitude && longitude ? { latitude, longitude } : null;
    } catch (error) {
      throw Error(error?.message || error);
    }
  }

  static async askForPosition(): Promise<Position | null> {
    try {
      const { location: permissionStatus } = await Geolocation.checkPermissions();

      if (permissionStatus === 'granted') {
        return await this.getPosition();
      }

      if (permissionStatus === 'prompt' || permissionStatus === 'prompt-with-rationale') {
        if (Capacitor.isNativePlatform()) {
          const { location: requestStatus } = await Geolocation.requestPermissions();
          if (requestStatus === 'granted') {
            return await this.getPosition();
          }

          return null;
        }

        return await this.getPosition();
      }

      return null;
    } catch (error) {
      throw Error(error?.message || error);
    }
  }
}

export class DeviceService {
  static async getDeviceLanguageCode() {
    if (Capacitor.isNativePlatform()) {
      const languageCode = await Device.getLanguageCode();
      return languageCode;
    }
  }

  static getBrowserLocales() {
    const { languages, language } = navigator || {};
    const browserLocales: string[] = Array.isArray(languages) ? languages : [language || DEFAULT_LOCALE];
    return browserLocales.map((locale) => {
      const trimmedLocale = locale.trim();
      return trimmedLocale.split(/[-_]/)[0];
    });
  }

  static async getLocales() {
    const browserLocales = this.getBrowserLocales();
    const { value: deviceLanguageCode } = (await this.getDeviceLanguageCode()) || {};
    return deviceLanguageCode ? [deviceLanguageCode, ...browserLocales] : browserLocales;
  }

  static async getDeviceLanguage(languages: LanguageDto[]) {
    const deviceLocales = await this.getLocales();
    const devicePreferredLocale = deviceLocales.find((loc) => languages.some((lang) => lang.isoName === loc));
    const devicePreferredLanguage = languages.find((lang) => lang.isoName === devicePreferredLocale);
    const defaultLanguage = languages.find((lang) => lang.isDefault);
    const clientLanguage = devicePreferredLanguage || defaultLanguage;
    return clientLanguage;
  }
}
