import { IonButton, IonIcon, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'src/store';
import { setProductComponentQty } from 'src/slices/product';
import { ProductJoinComponentsDto } from 'src/types/generated';
import {
  COMPONENT_MAX_COUNT,
  CUSTOMIZED_MAX_AMOUNT,
  EXTRA_COMPONENTS_MAX_AMOUNT,
  REMOVED_COMPONENT_MAX_AMOUNT,
} from 'src/constants';
import classes from './ProductComponents.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';
import textClasses from 'src/styles/text.module.scss';
import minusSignIcon from 'src/assets/img/minus-sign.svg';
import plusSignIcon from 'src/assets/img/plus-sign.svg';

interface ProductComponentsProps {
  productComponents: ProductJoinComponentsDto[];
}

const ProductComponents: React.FC<ProductComponentsProps> = ({ productComponents }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { products, productComponentQuantities, productExtraComponentQuantities } = useSelector(
    (state) => state.product
  );
  const productId = productComponents[0]?.productId;
  const product = products.find((p) => p.id === productId);

  const componentsAmount = productComponentQuantities
    .filter((x) => x.productId === productId && x.qty > 1)
    .reduce((acc, pec) => acc + (pec.qty - 1), 0);
  const extraComponentsAmount = productExtraComponentQuantities
    .filter((x) => x.productId === productId && x.qty > 0)
    .reduce((acc, pec) => acc + pec.qty, 0);
  const removedComponentsAmount = productComponentQuantities.filter(
    (x) => x.productId === productId && x.qty <= 0
  ).length;
  const componentsTotalAmount = componentsAmount + extraComponentsAmount - removedComponentsAmount;
  const componentsMaxAmount = EXTRA_COMPONENTS_MAX_AMOUNT + (product?.isCustomized ? CUSTOMIZED_MAX_AMOUNT : 0);

  const onAddComponentClick = (component: ProductJoinComponentsDto, qty: number) => {
    if (qty >= (component.maxAmount || COMPONENT_MAX_COUNT)) return;
    dispatch(setProductComponentQty({ componentId: component.id, productId: component.productId, qty: qty + 1 }));
  };

  const onRemoveComponentClick = (component: ProductJoinComponentsDto, qty: number) => {
    if (qty <= 0) return;
    dispatch(setProductComponentQty({ componentId: component.id, productId: component.productId, qty: qty - 1 }));
  };

  return (
    <>
      {!!productComponents.length && (
        <div>
          <h3 className={textClasses.titleBold}>{t('Components')}</h3>
          <IonText color="medium">
            <p className={textClasses.textS}>
              {t('Remove up to amount pcs', { amount: REMOVED_COMPONENT_MAX_AMOUNT })}
            </p>
          </IonText>
          <div className={classes.list}>
            {productComponents.map((component) => {
              const componentQuantityData = productComponentQuantities.find(
                (x) => x.productId === component.productId && x.componentId === component.id
              );
              const { qty } = componentQuantityData || { qty: 1 };
              const disabledAddBtn =
                qty >= (component.maxAmount || COMPONENT_MAX_COUNT) || componentsTotalAmount >= componentsMaxAmount;
              const disabledRemoveBtn =
                qty <= 0 || (removedComponentsAmount >= REMOVED_COMPONENT_MAX_AMOUNT && qty <= 1);
              return (
                <div key={component.id} className={classes.item}>
                  <span className={classNames(textClasses.textM, classes.itemName)}>{component.name}</span>
                  <div className={classes.controlButtons}>
                    <IonButton
                      color="white"
                      className={btnClasses.controlBtn}
                      onClick={() => onRemoveComponentClick(component, qty)}
                      disabled={disabledRemoveBtn}
                    >
                      <IonIcon
                        color="primary"
                        slot="icon-only"
                        src={minusSignIcon}
                        className={classes.componentBtnIcon}
                      />
                    </IonButton>
                    <span className={classNames(textClasses.textSBold, classes.itemQuantity)}>{qty}</span>
                    <IonButton
                      color="white"
                      className={btnClasses.controlBtn}
                      onClick={() => onAddComponentClick(component, qty)}
                      disabled={disabledAddBtn}
                    >
                      <IonIcon
                        color="success"
                        slot="icon-only"
                        src={plusSignIcon}
                        className={classes.componentBtnIcon}
                      />
                    </IonButton>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default ProductComponents;
