import { useState } from 'react';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { IonAlert } from '@ionic/react';

import ProductOrderSummaryItem from 'src/components/cart/ProductOrderSummaryItem';
import CampaignOrderSummaryItem from 'src/components/cart/CampaignOrderSummaryItem';
import { useDispatch, useSelector } from 'src/store';
import { removeCartItem } from 'src/slices/cart';
import { ProductCartItemDto } from 'src/types/generated';
import { getCustomizedPizzaComponents } from 'src/utils/product';
import { getCampaignProductName, getCampaignProductPrice } from 'src/utils/campaign';
import classes from './OrderSummary.module.scss';
import textClasses from 'src/styles/text.module.scss';

const OrderSummary: React.FC = () => {
  const { t } = useTranslation();
  const { products } = useSelector((state) => state.product);
  const { campaigns } = useSelector((state) => state.campaign);
  const { cartItems } = useSelector((state) => state.cart);
  const productCartItems = cartItems.filter((ci) => ci.isProduct);
  const campaignCartItems = cartItems.filter((ci) => ci.isCampaign);
  const dispatch = useDispatch();
  const [showRemoveItemAlert, setShowRemoveItemAlert] = useState<boolean>(false);
  const [removeItemId, setRemoveItemId] = useState<string>(null);

  const onOrderItemRemove = (cartItemId: string) => {
    setRemoveItemId(cartItemId);
    setShowRemoveItemAlert(true);
  };

  const onAlertDismiss = (e: CustomEvent) => {
    setShowRemoveItemAlert(false);
    if (removeItemId && e.detail.role === 'confirm') {
      dispatch(removeCartItem(removeItemId));
    }
  };

  if (!products || !campaigns) return null;

  return (
    <div className={classes.content}>
      <h2 className={textClasses.titleBold}>{t('Order summary')}</h2>
      <ul className={classes.list}>
        {campaignCartItems.map((campaignCartItem) => {
          const { uniqId, campaignId, products: campaignProducts, quantity } = campaignCartItem;
          const { name: campaignName } = campaigns.find((c) => c.id === campaignId) || {};

          const paidProducts = campaignProducts.filter((sp) => sp.isFreeSlot === false);
          const freeProducts = campaignProducts.filter((sp) => sp.isFreeSlot === true);

          const mappedPaidProducts = paidProducts.map(({ id, productId, variantId, partials }) => {
            const product = products.find((p) => p.id === productId);
            const { name: productName, variants, isCustomized } = product || {};
            const variant = (variants || []).find((pv) => pv.variantId === variantId);
            const variantsText = variant?.name || '';
            const campaignProductName = getCampaignProductName({ productName, variantsText });
            const price = getCampaignProductPrice({ productId, variantId, product, variant, products, partials });
            const mappedPartials = (partials || []).map((partial) => {
              const partialInfo = products.find((p) => p.id === partial.partialProductId);
              return {
                id: partial.partialProductId,
                name: partialInfo?.name,
                price: partialInfo?.price,
                originalPrice: partialInfo?.originalPrice,
                quantity: partial.quantity,
              };
            });
            return { id, name: campaignProductName, price, partials: mappedPartials, isCustomized };
          });

          const mappedFreeProducts = freeProducts
            .filter(({ productId }) => {
              const { categories } = products.find((p) => p.id === productId) || {};
              return categories && categories.every((c) => !c.isComponent);
            })
            .map(({ id, productId, variantId }) => {
              const product = products.find((p) => p.id === productId);
              const { name: productName, variants } = product || {};
              const variant = variants.find((pv) => pv.variantId === variantId);
              return { id, name: `${variant?.name || ''} ${productName}`.trim() };
            });
          const mappedFreePartials = freeProducts
            .filter(({ productId }) => {
              const { categories } = products.find((p) => p.id === productId) || {};
              return categories && categories.some((c) => c.isComponent);
            })
            .map(({ id, productId }) => {
              const product = products.find((p) => p.id === productId);
              const { name: productName } = product || {};
              return { id, name: productName };
            });
          return (
            <CampaignOrderSummaryItem
              key={uniqId}
              cartItemId={uniqId}
              qty={quantity}
              onRemove={onOrderItemRemove}
              orderItemProps={{
                campaignName,
                paidProducts: mappedPaidProducts,
                freeProducts: mappedFreeProducts,
                freePartials: mappedFreePartials,
              }}
            />
          );
        })}
        {productCartItems.map((cartItem) => {
          const {
            name,
            variants: productVariants,
            isCustomized,
          } = products.find((p) => p.id === cartItem.productId) || {};
          const variantsData = cartItem.variantIds
            .map((variantId) => productVariants.find((pv) => pv.id === variantId))
            .filter((variantData) => !!variantData);
          const variantsText = variantsData.map((variantData) => variantData?.name).join(', ');
          const { bonusPointsCost, bonusPointsAccumulator } = variantsData?.[0] || {};

          let freePartialsData = [];
          let paidPartialsData = cartItem.partials;
          if (isCustomized) {
            const customizedPizzaComponents = getCustomizedPizzaComponents<ProductCartItemDto['partials'][0]>(
              cartItem.partials
            );
            freePartialsData = customizedPizzaComponents.freeComponents;
            paidPartialsData = customizedPizzaComponents.paidComponents;
          }
          const mapPartialsData = (partialsData) =>
            orderBy(
              partialsData.map((partial) => {
                const partialData = products.find((p) => p.id === partial.partialProductId);
                return {
                  name: partialData?.name || '-',
                  price: partialData?.price || 0,
                  originalPrice: partialData?.originalPrice || 0,
                  quantity: partial.quantity,
                  id: partial.partialProductId,
                };
              }),
              ['quantity', 'name'],
              ['asc', 'asc']
            );
          const freePartials = mapPartialsData(freePartialsData);
          const partials = mapPartialsData(paidPartialsData);
          return (
            <ProductOrderSummaryItem
              key={cartItem.uniqId}
              cartItemId={cartItem.uniqId}
              qty={cartItem.quantity}
              bonusPointsCost={bonusPointsCost}
              bonusPointsAccumulator={bonusPointsAccumulator}
              onRemove={onOrderItemRemove}
              orderItemProps={{
                productName: name,
                variantsText,
                partials,
                freePartials,
              }}
            />
          );
        })}
      </ul>
      <IonAlert
        isOpen={showRemoveItemAlert}
        onDidDismiss={onAlertDismiss}
        header={t('Warning')}
        message={t('Do you really want to remove this item?')}
        buttons={[
          {
            text: t('Cancel'),
            role: 'cancel',
          },
          {
            text: t('Confirm'),
            role: 'confirm',
          },
        ]}
      />
    </div>
  );
};

export default OrderSummary;
