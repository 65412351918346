import { useState } from 'react';
import classNames from 'classnames';
import { IonImg, IonThumbnail } from '@ionic/react';

import classes from 'src/components/Image.module.scss';
import imagePlaceholder from 'src/assets/img/image-placeholder.svg';

interface ImageProps {
  src: string;
  placeholderSrc?: string;
  className?: string;
  placeholderClassName?: string;
  innerClassName?: string;
  onLoad?: () => void;
}

const Image: React.FC<ImageProps> = ({
  src,
  placeholderSrc,
  className,
  placeholderClassName,
  innerClassName,
  onLoad,
}) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  const onImgDidLoad = () => {
    setImageLoaded(true);
    if (onLoad) {
      onLoad();
    }
  };

  return (
    <IonThumbnail className={classNames(className, classes.imgThumbNail)}>
      {!placeholderSrc && (
        <IonImg
          src={imagePlaceholder}
          className={classNames(placeholderClassName, classes.imgPlaceholder, { 'ion-hide': imageLoaded })}
        />
      )}
      {placeholderSrc && (
        <IonImg
          src={placeholderSrc}
          className={classNames(placeholderClassName, classes.imgPlaceholder, { 'ion-hide': imageLoaded })}
        />
      )}
      <IonImg
        src={src}
        className={classNames(innerClassName, { [classes.imgHidden]: !imageLoaded })}
        onIonImgDidLoad={onImgDidLoad}
      />
    </IonThumbnail>
  );
};

export default Image;
