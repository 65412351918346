import {
  ProductImageDto,
  ProductTranslationDto,
  ProductJoinInfoDto,
  ProductRowCategoryDto,
  ProductComponentJoinInfoDto,
  ProductRowVariantJoinInfoDto,
} from 'src/types/generated';

import BaseReadApiModule from 'src/api/baseRead';

class ProductApi extends BaseReadApiModule<ProductJoinInfoDto> {
  protected baseRoute = '/products';

  public getTranslations(id: number) {
    return this._get<ProductTranslationDto[]>(`${this.baseRoute}/${id}/translations`);
  }

  public getImages(productId: number) {
    return this._get<ProductImageDto[]>(`${this.baseRoute}/${productId}/images`);
  }

  public getCategories(productId: number) {
    return this._get<ProductRowCategoryDto[]>(`${this.baseRoute}/${productId}/categories`);
  }

  public getComponents(productId: number) {
    return this._get<ProductComponentJoinInfoDto[]>(`${this.baseRoute}/${productId}/components`);
  }

  public getVariants(productId: number) {
    return this._get<ProductRowVariantJoinInfoDto[]>(`${this.baseRoute}/${productId}/variants`);
  }
}

export default ProductApi;
