import { uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { IonButton, IonIcon, IonText } from '@ionic/react';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'src/store';
import { setProductExtraComponentQty, selectExtraComponentCategory } from 'src/slices/product';
import { ProductJoinInfoDto } from 'src/types/generated';
import { useComponentsGetter } from 'src/hooks';
import { COMPONENT_MAX_COUNT, EXTRA_COMPONENTS_MAX_AMOUNT, CUSTOMIZED_MAX_AMOUNT } from 'src/constants';
import classes from './ProductComponents.module.scss';
import extraClasses from './ProductExtraComponents.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';
import textClasses from 'src/styles/text.module.scss';
import minusSignIcon from 'src/assets/img/minus-sign.svg';
import plusSignIcon from 'src/assets/img/plus-sign.svg';

interface ProductExtraComponentsProps {
  productId: number;
}

const ProductExtraComponents: React.FC<ProductExtraComponentsProps> = ({ productId }) => {
  const { t } = useTranslation();
  const { products, productExtraComponentQuantities, selectedExtraComponentCategories, productComponentQuantities } =
    useSelector((state) => state.product);
  const { components, componentCategories } = useComponentsGetter();

  const product = products.find((p) => p.id === productId);
  const productComponentIds = (product?.components || []).map((c) => c.id);
  const productExtraComponentsData = components.filter((component) => !productComponentIds.includes(component.id));

  const extraComponentCategoryIds = uniqBy(productExtraComponentsData.map((ec) => ec.categories).flat(), 'id').map(
    (c) => c.id
  );
  const extraComponentCategoriesData = componentCategories.filter((c) => extraComponentCategoryIds.includes(c.id));
  const selectedCategoryId = selectedExtraComponentCategories[productId] || extraComponentCategoriesData[0]?.id;

  const extraComponentsByCategory = productExtraComponentsData.filter(
    (component) => !selectedCategoryId || component.categories.some((cat) => cat.id === selectedCategoryId)
  );
  const componentsAmount = productComponentQuantities
    .filter((pec) => pec.productId === productId && pec.qty > 1)
    .reduce((acc, pec) => acc + (pec.qty - 1), 0);
  const extraComponentsAmount = productExtraComponentQuantities
    .filter((pec) => pec.productId === productId && pec.qty > 0)
    .reduce((acc, pec) => acc + pec.qty, 0);
  const removedComponentsAmount = productComponentQuantities.filter(
    (x) => x.productId === productId && x.qty <= 0
  ).length;
  const componentsTotalAmount = componentsAmount + extraComponentsAmount - removedComponentsAmount;
  const componentsMaxAmount = EXTRA_COMPONENTS_MAX_AMOUNT + (product?.isCustomized ? CUSTOMIZED_MAX_AMOUNT : 0);
  const extraComponentsMaxAmount = componentsMaxAmount - componentsAmount + removedComponentsAmount;
  const dispatch = useDispatch();

  const onComponentCategoryClick = (categoryId: number | null) => {
    dispatch(selectExtraComponentCategory({ [productId]: categoryId }));
  };

  const onAddComponentClick = (component: ProductJoinInfoDto, qty: number) => {
    if (qty >= (component.maxAmount || COMPONENT_MAX_COUNT)) return;
    dispatch(setProductExtraComponentQty({ componentId: component.id, productId, qty: qty + 1 }));
  };

  const onRemoveComponentClick = (component: ProductJoinInfoDto, qty: number) => {
    if (qty <= 0) return;
    dispatch(setProductExtraComponentQty({ componentId: component.id, productId, qty: qty - 1 }));
  };

  return (
    <>
      {!!productExtraComponentsData.length && (
        <div>
          <h3 className={textClasses.titleBold}>{t('Add the ingredient')}</h3>
          {extraComponentsMaxAmount > 0 && (
            <IonText color="medium">
              <p className={textClasses.textS}>{t('Add up to amount pcs', { amount: extraComponentsMaxAmount })}</p>
            </IonText>
          )}
          {!!extraComponentCategoriesData.length && (
            <div className={extraClasses.categoryBtnContainer}>
              {extraComponentCategoriesData.map((c) => (
                <IonButton
                  key={c.id}
                  onClick={() => onComponentCategoryClick(c.id)}
                  color={selectedCategoryId === c.id ? 'primary' : 'light'}
                  className={classNames(btnClasses.chipBtn, extraClasses.categoryBtn)}
                >
                  <IonText className={extraClasses.categoryBtnText}>{c.name}</IonText>
                </IonButton>
              ))}
            </div>
          )}
          <div className={extraClasses.list}>
            {extraComponentsByCategory.map((component) => {
              const componentQuantityData = productExtraComponentQuantities.find(
                (x) => x.productId === productId && x.componentId === component.id
              );
              const { qty } = componentQuantityData || { qty: 0 };
              const disabledAddBtn =
                qty >= (component.maxAmount || COMPONENT_MAX_COUNT) || componentsTotalAmount >= componentsMaxAmount;
              return (
                <div key={component.id} className={classes.item}>
                  <div className={extraClasses.itemNameContainer}>
                    {qty > 0 && (
                      <IonText color="primary">
                        <span className={classNames(textClasses.textMBold, extraClasses.selectedItemPlusSign)}>+</span>
                      </IonText>
                    )}
                    <span
                      className={classNames(textClasses.textM, classes.itemName, { [textClasses.textMBold]: qty > 0 })}
                    >
                      {component.name}
                    </span>
                  </div>
                  <div className={classes.controlButtons}>
                    {qty > 0 && (
                      <IonButton
                        color="white"
                        className={btnClasses.controlBtn}
                        onClick={() => onRemoveComponentClick(component, qty)}
                      >
                        <IonIcon
                          color="primary"
                          slot="icon-only"
                          src={minusSignIcon}
                          className={classes.componentBtnIcon}
                        />
                      </IonButton>
                    )}
                    {qty > 0 && <span className={classes.itemQuantity}>{qty}</span>}
                    <IonButton
                      color="white"
                      className={btnClasses.controlBtn}
                      onClick={() => onAddComponentClick(component, qty)}
                      disabled={disabledAddBtn}
                    >
                      <IonIcon
                        color="success"
                        slot="icon-only"
                        src={plusSignIcon}
                        className={classes.componentBtnIcon}
                      />
                    </IonButton>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default ProductExtraComponents;
