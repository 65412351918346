import { useTranslation } from 'react-i18next';
import { IonButton, IonIcon, IonText } from '@ionic/react';
import classNames from 'classnames';

import { useDispatch } from 'src/store';
import { openModal } from 'src/slices/auth';
import { DESKTOP_VIEW, MOBILE_VIEW } from 'src/constants';
import { useBreakpoint } from 'src/hooks';
import btnClasses from 'src/styles/buttons.module.scss';
import textClasses from 'src/styles/text.module.scss';
import classes from './SignInButton.module.scss';
import profileIcon from 'src/assets/img/profile.svg';

interface SignInButtonProps {
  onClick?: () => void | Promise<void>;
}

const SignInButton: React.FC<SignInButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();
  const { isMobileView, isDesktopView } = useBreakpoint();
  const dispatch = useDispatch();

  const ionButtonProps = {} as { routerLink?: string };

  if (isMobileView) {
    ionButtonProps.routerLink = '/tabs/profile/auth';
  }

  const onSignInBtnClick = async () => {
    if (onClick) {
      await onClick();
    }
    if (isDesktopView) {
      dispatch(openModal(true));
    }
  };

  return (
    <IonButton
      color="white"
      shape="round"
      onClick={onSignInBtnClick}
      className={classNames(btnClasses.outlineBtn, classes.btn)}
      {...ionButtonProps}
    >
      <IonIcon slot="start" src={profileIcon} className={classes.btnIcon} />
      <IonText className={classNames(classes.btnText, textClasses.textMBold, DESKTOP_VIEW)}>{t('Sign in')}</IonText>
      <IonText className={classNames(classes.btnText, textClasses.headerS, MOBILE_VIEW)}>{t('Sign in')}</IonText>
    </IonButton>
  );
};

export default SignInButton;
