import { ProductJoinInfoDto } from 'src/types/generated';
import { getCustomizedPizzaComponents } from './product';

interface IPartial {
  partialProductId: number;
  quantity: number;
}

export const getCampaignProductPrice = ({
  productId,
  variantId,
  products,
  product,
  variant,
  partials = [],
}: {
  productId: number;
  variantId: number;
  products: ProductJoinInfoDto[];
  partials: IPartial[];
  product?: ProductJoinInfoDto;
  variant?: ProductJoinInfoDto['variants'][0];
}) => {
  const {
    price: productPrice,
    originalPrice: productOriginalPrice,
    variants = [],
    isCustomized: productIsCustomized,
  } = product || products?.find((p) => p.id === productId) || {};
  const { price: variantPrice, originalPrice: variantOriginalPrice } =
    variant || variants?.find((pv) => pv.variantId === variantId) || {};
  const removedPartialsDiscount = partials
    .filter((partial) => partial.quantity <= 0)
    .map((partial) => {
      const { price, originalPrice } = (products || []).find((p) => p.id === partial.partialProductId) || {};
      return originalPrice || price;
    })
    .reduce((sum, price) => {
      sum += price || 0;
      return sum;
    }, 0);
  let partialsData = partials;

  if (productIsCustomized) {
    const { paidComponents } = getCustomizedPizzaComponents<IPartial>(partials);
    partialsData = paidComponents;
  }

  const partialsPrice = partialsData
    .filter((partial) => partial.quantity > 0)
    .map((partial) => {
      const { price, originalPrice } = (products || []).find((p) => p.id === partial.partialProductId) || {};
      return (originalPrice || price || 0) * partial.quantity;
    })
    .reduce((sum, price) => {
      sum += price || 0;
      return sum;
    }, 0);

  // Return original price first, to skip possible discount. If there is a "discount campaign" for same product,
  // then product will have 2 price fields: "price" - price with discount, "originalPrice" -  price without discount
  // Since combo campaign is giving free products, then "discount" should be ignored.
  const basePrice = variantOriginalPrice || variantPrice || productOriginalPrice || productPrice;
  const campaignProductPrice = Math.max(basePrice + partialsPrice - removedPartialsDiscount, basePrice);
  return campaignProductPrice;
};

export const getCampaignProductName = ({
  productName,
  variantsText,
}: {
  productName: string;
  variantsText: string;
}) => {
  return `${productName} ${variantsText}`.trim();
};
