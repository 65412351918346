import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react';
import { setDay } from 'date-fns';
import classNames from 'classnames';

import { formatDate } from 'src/utils/time';
import { useSelector } from 'src/store';
import classes from './OpeningsHours.module.scss';
import textClasses from 'src/styles/text.module.scss';

const WorkingDays: React.FC = () => {
  const { t } = useTranslation();
  const { salePointWorkingDays } = useSelector((state) => state.salePoint);

  if (!salePointWorkingDays?.length) return null;

  return (
    <>
      <h3 className={classNames(textClasses.titleBold, classes.heading, 'ion-padding-bottom')}>{t('Opening hours')}</h3>
      <IonGrid className="ion-no-padding">
        {orderBy(salePointWorkingDays, ['isoWeekDate'], ['asc']).map(({ isoWeekDate, start, end }) => {
          const dayFormatted = formatDate(setDay(new Date(), isoWeekDate), 'EEEE');
          const hours = start && end ? `${start}-${end}` : t('Closed');
          return (
            <IonRow key={isoWeekDate} className="ion-justify-content-between ion-margin-vertical">
              <IonCol className="ion-text-left ion-text-capitalize ion-no-padding" size="auto">
                <IonText className={textClasses.title}>{dayFormatted}</IonText>
              </IonCol>
              <IonCol className="ion-text-right ion-no-padding">
                <IonText color="medium" className={textClasses.title}>
                  {hours}
                </IonText>
              </IonCol>
            </IonRow>
          );
        })}
      </IonGrid>
    </>
  );
};

export default WorkingDays;
