import { useTranslation } from 'react-i18next';
import { IonIcon, IonText } from '@ionic/react';
import classNames from 'classnames';

import { formatPrice } from 'src/utils';
import minusSignIcon from 'src/assets/img/minus-sign.svg';
import plusSignIcon from 'src/assets/img/plus-sign.svg';
import classes from './OrderItemPartials.module.scss';
import textClasses from 'src/styles/text.module.scss';

export interface IOrderItemPartial {
  id: number;
  name: string;
  price: number;
  originalPrice?: number;
  quantity: number;
}
interface OrderItemPartialsProps {
  partials: IOrderItemPartial[];
  title?: string;
  isFree?: boolean;
}

const OrderItemPartials: React.FC<OrderItemPartialsProps> = ({ partials, title, isFree }) => {
  const { t } = useTranslation();

  if (!partials?.length) return null;

  return (
    <div className={classes.partialsContainer}>
      <h4 className={classNames(textClasses.textS, classes.partialsTitle)}>{title || t('Extras')}</h4>

      {partials.map(({ quantity: partialQty, name, price: partialPrice, originalPrice: partialOriginalPrice, id }) => (
        <div key={id} className={classes.partialRow}>
          <IonText color={partialQty > 0 ? 'dark' : 'danger'} className={classes.partialRowText}>
            <IonIcon src={partialQty > 0 ? plusSignIcon : minusSignIcon} className={classes.partialRowSign} />
            <span className={textClasses.textSBold}>
              {name}
              {partialQty > 0 ? ` (x${partialQty})` : ''}
            </span>
          </IonText>
          {!isFree && partialQty > 0 && (
            <IonText color="primary" className={classes.partialRowPrice}>
              {Boolean(partialOriginalPrice) && (
                <span className={classNames(textClasses.textSBold, classes.originalPriceText)}>
                  {formatPrice(partialQty * partialOriginalPrice)}
                </span>
              )}
              <span className={textClasses.textSBold}>{formatPrice(partialQty * partialPrice)}</span>
            </IonText>
          )}
        </div>
      ))}
    </div>
  );
};

export default OrderItemPartials;
