export enum BreakPoints {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

export enum CampaignTypes {
  STATIC_PAGE = 'static_page',
  DISCOUNT = 'discount',
  COMBO = 'combo',
  URL = 'url',
  CATEGORY = 'category',
}

export enum CampaignUrlTarget {
  Self = 'self',
  New = 'new',
}

export interface IApiPage {
  size?: number;
  number?: number;
}

export interface IApiFilter {
  field: string;
  value: string;
  operator: string;
}

export interface IApiSort {
  field: string;
  ascending: boolean;
}

export interface IPagedResult<T> {
  items: T[];
  total: number;
}
