import { IonContent, IonModal } from '@ionic/react';

import ComboCampaignProductList from 'src/components/campaign/ComboCampaignProductList';
import CloseButton from 'src/components/CloseButton';
import { useDispatch, useSelector } from 'src/store';
import { openComboCampaignProducts } from 'src/slices/campaign';
import classes from './ComboCampaignModals.module.scss';

const ComboCampaignProductsModal: React.FC = () => {
  const dispatch = useDispatch();
  const { isComboCampaignProductsOpened } = useSelector((state) => state.campaign);

  const onCloseBtnClick = async () => {
    dispatch(openComboCampaignProducts(false));
  };

  const onModalDismiss = () => {
    dispatch(openComboCampaignProducts(false));
  };

  return (
    <IonModal isOpen={isComboCampaignProductsOpened} onDidDismiss={onModalDismiss} cssClass={classes.editModal}>
      <IonContent>
        <div className={classes.content}>
          <ComboCampaignProductList />
        </div>
        <div className={classes.closeBtnContainer}>
          <CloseButton onClose={onCloseBtnClick} />
        </div>
      </IonContent>
    </IonModal>
  );
};

export default ComboCampaignProductsModal;
