import { useEffect } from 'react';

import LoadingOverlay from 'src/components/LoadingOverlay';
import { useDispatch, useSelector } from 'src/store';
import { gatewayCreate, getGatewayStatus } from 'src/slices/auth';
import { useBreakpoint } from 'src/hooks';
import { AuthPublicGatewayCreateRequestDto } from 'src/types/generated';
import { DEFAULT_LOCALE } from 'src/constants';
import classes from './IdentityGateway.module.scss';
import logo from 'src/assets/img/pizzakiosk-logo-colored.svg';

interface IdentityGatewayProps {
  onCompleted?: () => void | Promise<void>;
}

const IdentityGateway: React.FC<IdentityGatewayProps> = ({ onCompleted }) => {
  const dispatch = useDispatch();
  const { gatewaySessionToken, loading } = useSelector((state) => state.auth);
  const { languages, selectedLanguageId } = useSelector((state) => state.system);
  const { isoName: selectedLanguageCode } = languages
    ? languages.find((lang) => lang.id === selectedLanguageId) || {}
    : { isoName: DEFAULT_LOCALE };
  const { gateway } = loading;
  const { isDesktopView } = useBreakpoint();

  useEffect(() => {
    const gatewayCreateData: AuthPublicGatewayCreateRequestDto = {
      authenticationMethods: isDesktopView ? ['mobile', 'smartid', 'smartcard'] : ['mobile', 'smartid'],
    };
    dispatch(gatewayCreate(gatewayCreateData));
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (gatewaySessionToken && window?.DokobitIdentity) {
      const options = {
        sessionToken: gatewaySessionToken,
        callback: async (returnToken: string) => {
          const isRegistered = (await dispatch(getGatewayStatus(returnToken))) as unknown as boolean;
          if (isRegistered && onCompleted) {
            onCompleted();
          }
        },
        primaryColor: '#cc0000',
        logo,
        locale: selectedLanguageCode || DEFAULT_LOCALE,
      };
      // @ts-ignore
      new DokobitIdentity(options).init();
    }
  }, [gatewaySessionToken]);

  return (
    <>
      {gateway && <LoadingOverlay backdrop spinnerColor="primary" />}
      <div id="Dokobit-identity-container" className={classes.dokobitContainer} />
    </>
  );
};

export default IdentityGateway;
