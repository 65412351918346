import { IonCol, IonGrid, IonRow, IonSkeletonText } from '@ionic/react';

import classes from 'src/components/loaders/loaders.module.scss';

const ContactWorkingDaysLoader: React.FC = () => (
  <IonGrid>
    {[...Array(7)].map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <IonRow key={index}>
        <IonCol>
          <IonSkeletonText animated className={classes.workingDaysLoaderItemLeft} />
        </IonCol>
        <IonCol>
          <IonSkeletonText animated className={classes.workingDaysLoaderItemRight} />
        </IonCol>
      </IonRow>
    ))}
  </IonGrid>
);

export default ContactWorkingDaysLoader;
