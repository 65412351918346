import { IonContent, IonPage } from '@ionic/react';

import NotFound from 'src/components/NotFound';

const NotFoundPage: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <NotFound />
      </IonContent>
    </IonPage>
  );
};

export default NotFoundPage;
