import { useTranslation } from 'react-i18next';
import { IonButton, IonIcon, IonText } from '@ionic/react';

import { useDispatch } from 'src/store';
import { openModal } from 'src/slices/auth';
import profileIcon from 'src/assets/img/profile.svg';
import classes from './LoginNotification.module.scss';
import textClasses from 'src/styles/text.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';
import classNames from 'classnames';
import { useBreakpoint } from 'src/hooks';

const LoginNotification: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobileView, isDesktopView } = useBreakpoint();

  const ionButtonProps = {} as { routerLink: string };

  if (isMobileView) {
    ionButtonProps.routerLink = '/tabs/profile/auth';
  }

  const onLogInBtnClick = () => {
    if (isDesktopView) {
      dispatch(openModal(true));
    }
  };

  return (
    <div className={classes.container}>
      <h2 className={textClasses.headerLBold}>{t('Create an account')}</h2>
      <h3 className={textClasses.title}>{t('Collect points get personalized offers and more')}</h3>
      <IonButton
        className={classNames(btnClasses.outlineBtn, classes.btn)}
        color="primary"
        shape="round"
        onClick={onLogInBtnClick}
        {...ionButtonProps}
      >
        <IonIcon slot="start" src={profileIcon} color="white" className={classes.btnIcon} />
        <IonText className={classNames(classes.btnText, textClasses.headerS)}>{t('Log in')}</IonText>
      </IonButton>
      <p className={textClasses.textS}>{t('Or continue as a guest')}</p>
    </div>
  );
};

export default LoginNotification;
