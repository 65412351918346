import { ClientVipJoinGeneralDto, ClientVipJoinDetailsDto, ClientVipJoinDetailsCreateDto } from 'src/types/generated';
import BaseCrudApiModule from 'src/api/baseCrud';

class VipAPI extends BaseCrudApiModule<ClientVipJoinDetailsDto, ClientVipJoinDetailsCreateDto> {
  protected baseRoute = '/client-vips/my';

  public getMyVips() {
    return this._get<ClientVipJoinGeneralDto[]>(`${this.baseRoute}`);
  }
}

export default VipAPI;
