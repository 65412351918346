import isAfter from 'date-fns/isAfter';
import { getStorageItem } from './storage';

interface IPartial {
  quantity: number;
  price: number;
}

interface ITotalPriceOpts {
  productPrice: number;
  variantPrice: number;
  partialsPrice: number;
  removedPartialsDiscount: number;
  quantity: number;
}

export const getRemovedPartialsDiscount = (partials: IPartial[]) => {
  return partials
    .filter((p) => p.quantity <= 0)
    .map((p) => p.price)
    .reduce((sum, price) => sum + price, 0);
};

export const getPartialsPrice = (partials: IPartial[]) => {
  return partials
    .filter((p) => p.quantity > 0)
    .map((p) => p.price * p.quantity)
    .reduce((sum, price) => sum + price, 0);
};

export const getTotalPrice = ({
  productPrice,
  variantPrice,
  partialsPrice,
  removedPartialsDiscount,
  quantity,
}: ITotalPriceOpts) => {
  const basePrice = variantPrice || productPrice;
  const calculatedPrice = basePrice + partialsPrice - removedPartialsDiscount;
  const maxPrice = Math.max(calculatedPrice, basePrice);
  const totalPrice = maxPrice * quantity;
  return parseFloat(totalPrice.toFixed(2));
};

export const shouldRestoreCartFromStorage = async () => {
  const cartItems = await getStorageItem('cartItems');
  const cartItemsExpired = await getStorageItem('cartItemsExpired');
  const cartItemsExpiredDate = cartItemsExpired ? new Date(cartItemsExpired) : null;
  const currentDate = new Date();
  return JSON.parse(cartItems)?.length > 0 && cartItemsExpiredDate && isAfter(cartItemsExpiredDate, currentDate);
};
