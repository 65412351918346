import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IonButton, IonCol, IonGrid, IonRow, useIonViewWillEnter } from '@ionic/react';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';

import DetailsLayout from 'src/components/layout/DetailsLayout';
import PickUpTimePicker from 'src/components/cart/PickUpTimePicker';
import OrderSummary from 'src/components/cart/OrderSummary';
import AddOnSales from 'src/components/cart/AddOnSales';
import SaveOrderVIP from 'src/components/cart/SaveOrderVIP';
import TotalPrice from 'src/components/cart/TotalPrice';
import CartFooter from 'src/components/cart/CartFooter';
import LoginNotification from 'src/components/cart/LoginNotification';
import LoadingOverlay from 'src/components/LoadingOverlay';
import BonusPoints from 'src/components/BonusPoints';
import { useDispatch, useSelector } from 'src/store';
import { getCategories, getProducts } from 'src/slices/product';
import { getCampaigns } from 'src/slices/campaign';
import { syncBonusPoints } from 'src/slices/auth';
import { getSalePointOpeningHours } from 'src/slices/salePoint';
import { adjustBonusedQuantityProducts, setProductsCheckoutTime, validateDeadline } from 'src/slices/cart';
import {
  useBreakpoint,
  usePageLanguageReload,
  useProductsCheckoutTimeGetter,
  useSelectedSalePointGetter,
} from 'src/hooks';
import classes from './CartTab.module.scss';
import textClasses from 'src/styles/text.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';
import layoutClasses from 'src/styles/layout.module.scss';

interface CartTabProps extends RouteComponentProps {}

const CartTab: React.FC<CartTabProps> = ({ history, location, match }) => {
  const { t } = useTranslation();
  const salePoint = useSelectedSalePointGetter();
  const productsCheckoutTime = useProductsCheckoutTimeGetter();
  const dispatch = useDispatch();
  const { cartItems, deadline, minDeadline, maxDeadline } = useSelector((state) => state.cart);
  const { loading } = useSelector((state) => state.order);
  const { salePoints, salePointWorkingDays, salePointAvailableTimes } = useSelector((state) => state.salePoint);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { categories, products } = useSelector((state) => state.product);
  const campaigns = useSelector((state) => state.campaign.campaigns);
  const routerProps = { history, location, match };
  const { isMobileView, isDesktopView } = useBreakpoint();
  const { orderCreate: isOrderCreating } = loading;
  const salePointIsAvailable = deadline && minDeadline && maxDeadline;
  const hasCampaignInCart = cartItems.some((ci) => ci.isCampaign);
  const meta = {
    title: t('-SEO Cart page'),
    description: t('-SEO Cart page description'),
  };

  const onLanguageChange = async () => {
    dispatch(getProducts());
  };

  const onReturnToMenuClick = async () => {
    history.replace('/tabs/menu');
  };

  usePageLanguageReload({ routerProps, callback: onLanguageChange });

  useIonViewWillEnter(() => {
    if (!categories) {
      dispatch(getCategories());
    }
    if (!products) {
      dispatch(getProducts());
    }
    if (!campaigns) {
      dispatch(getCampaigns());
    }
    if (!salePointWorkingDays || !salePointAvailableTimes) {
      dispatch(getSalePointOpeningHours());
    }
    if (cartItems.length > 0 && isAuthenticated) {
      dispatch(syncBonusPoints());
      dispatch(adjustBonusedQuantityProducts());
    }
  }, [products, categories, campaigns, cartItems, isAuthenticated, salePointWorkingDays, salePointAvailableTimes]);

  useEffect(() => {
    if (
      location?.pathname === '/tabs/cart' &&
      cartItems?.length > 0 &&
      products &&
      salePointWorkingDays &&
      salePointAvailableTimes
    ) {
      const setup = async () => {
        await dispatch(setProductsCheckoutTime(productsCheckoutTime));
        await dispatch(validateDeadline({ showWarningIfClosed: true }));
      };

      setup();
    }
  }, [products, salePointWorkingDays, salePointAvailableTimes, productsCheckoutTime, location?.pathname]);

  const emptyCartMessage =
    salePoints?.length <= 0
      ? t('At the moment there is no any available sale point')
      : t('Your cart is currently empty');
  const isEmptyCart = salePoints?.length <= 0 || cartItems?.length <= 0;

  return (
    <DetailsLayout
      headerTitle={salePoint?.name}
      contentHeaderTitle={`${t('Cart')} (${cartItems.length})`}
      meta={meta}
      {...routerProps}
    >
      {isOrderCreating && <LoadingOverlay backdrop spinnerColor="primary" />}
      <div className={layoutClasses.container}>
        {isEmptyCart && (
          <div className="ion-text-center">
            <h2 className={classNames(textClasses.titleBold, 'ion-margin-top', 'ion-margin-bottom')}>
              {emptyCartMessage}.
            </h2>
            <IonButton onClick={onReturnToMenuClick} className={classNames(btnClasses.primaryBtn, textClasses.title)}>
              {t('Return to menu')}
            </IonButton>
          </div>
        )}
        {!isEmptyCart && (
          <>
            {isDesktopView && (
              <div className={classes.desktopContainer}>
                <IonGrid>
                  <IonRow>
                    <IonCol className={classes.cartColumn}>
                      {!isAuthenticated && <LoginNotification />}
                      <div className={classes.salePointHeader}>
                        <h2 className={textClasses.titleBold}>{t('Sale point')}</h2>
                        <h3 className={classNames(textClasses.headerMBold, classes.salePointHeaderName)}>
                          {salePoint?.name}
                        </h3>
                      </div>
                      <PickUpTimePicker {...routerProps} />
                      {!hasCampaignInCart && <SaveOrderVIP />}
                      {isAuthenticated && <BonusPoints showCartBonusPoints />}
                    </IonCol>
                    <IonCol className={classes.cartColumn}>
                      <OrderSummary />
                      <AddOnSales />
                      <TotalPrice />
                      {salePointIsAvailable && <CartFooter {...routerProps} />}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            )}

            {isMobileView && (
              <div>
                {!isAuthenticated && <LoginNotification />}
                <PickUpTimePicker {...routerProps} />
                <OrderSummary />
                {!hasCampaignInCart && <SaveOrderVIP />}
                <AddOnSales />
                <TotalPrice />
                {isAuthenticated && <BonusPoints showCartBonusPoints />}
                {salePointIsAvailable && <CartFooter {...routerProps} />}
              </div>
            )}
          </>
        )}
      </div>
    </DetailsLayout>
  );
};

export default CartTab;
