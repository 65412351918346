import React, { ReactNode, useImperativeHandle, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonContent, IonPage } from '@ionic/react';
import { ScrollDetail } from '@ionic/core';
import classNames from 'classnames';

import MetaHead, { MetaHeadProps } from 'src/components/MetaHead';
import DesktopTopNavigation from 'src/components/layout/DesktopTopNavigation';
import DesktopBottomNavigation from 'src/components/layout/DesktopBottomNavigation';
import IosSafeAreaTop from 'src/components/IosSafeAreaTop';
import IosSafeAreaBottom from 'src/components/IosSafeAreaBottom';
import classes from './MainLayout.module.scss';

interface MainLayoutProps extends RouteComponentProps {
  children?: ReactNode;
  meta?: MetaHeadProps;
  centerContent?: boolean;
  contentClassName?: string;
  onScroll?: (event: CustomEvent<ScrollDetail>) => void;
}

export type MainLayoutRef = {
  scrollToPoint: (x: number, y: number, duration: number) => void;
};

const MainLayout = React.forwardRef<MainLayoutRef, MainLayoutProps>(
  ({ children, meta, centerContent, contentClassName = '', onScroll, ...routeProps }, ref) => {
    const ionContentProps = {} as { scrollEvents?: boolean; onIonScroll?: (event: CustomEvent<ScrollDetail>) => void };
    const showMeta = routeProps.match.url === routeProps.location.pathname;
    const contentRef = useRef<HTMLIonContentElement>(null);

    if (onScroll) {
      ionContentProps.scrollEvents = true;
      ionContentProps.onIonScroll = onScroll;
    }

    useImperativeHandle(ref, () => ({
      scrollToPoint: (x: number, y: number, duration: number) => {
        contentRef?.current?.scrollToPoint(x, y, duration);
      },
    }));

    return (
      <IonPage>
        {showMeta && <MetaHead {...meta} />}

        <IonContent {...ionContentProps} ref={contentRef}>
          <div className={classes.layout}>
            <IosSafeAreaTop />

            <DesktopTopNavigation {...routeProps} />

            <div className={classNames({ [classes.centerContent]: centerContent }, contentClassName)}>{children}</div>

            <DesktopBottomNavigation />

            <IosSafeAreaBottom />
          </div>
        </IonContent>
      </IonPage>
    );
  }
);

export default MainLayout;
