import { useEffect, useRef } from 'react';

import ProductVariants from './details/ProductVariants';
import ProductComponents from './details/ProductComponents';
import DetailsHeader from './details/DetailsHeader';
import { useDimension } from 'src/hooks';
import classes from 'src/components/menu/ProductDetails.module.scss';
import ProductExtraComponents from './details/ProductExtraComponents';
import { ProductJoinInfoDto } from 'src/types/generated';

interface ProductDetailsProps {
  product: ProductJoinInfoDto;
  onContentHeightChange?: () => void;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({ product, onContentHeightChange }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const hasProductDetails = product.variants.length > 0 || product.components.length > 0;
  const { height } = useDimension(contentRef);

  useEffect(() => {
    if (onContentHeightChange) {
      onContentHeightChange();
    }
  }, [height]);

  return (
    <div className={classes.content} ref={contentRef}>
      <DetailsHeader product={product} />
      <ProductVariants productVariants={product.variants} />
      <ProductComponents productComponents={product.components} />
      {hasProductDetails && <ProductExtraComponents productId={product.id} />}
    </div>
  );
};

export default ProductDetails;
