import { useTranslation } from 'react-i18next';
import { IonText } from '@ionic/react';
import classNames from 'classnames';

import { ProductJoinInfoDto } from 'src/types/generated';
import { formatPrice } from 'src/utils';
import classes from './ProductInfo.module.scss';
import textClasses from 'src/styles/text.module.scss';

interface ProductInfoProps {
  product: ProductJoinInfoDto;
  hasProductDetails?: boolean;
}

const ProductInfo: React.FC<ProductInfoProps> = ({ product, hasProductDetails = true }) => {
  const { t } = useTranslation();
  const { name, description, price, variants = [] } = product || {};
  const minimumVariantPrice = variants
    .filter((variant) => variant.price > 0)
    .reduce((min, variant) => Math.min(min, variant.price), Infinity);
  const groupIdOfVariantsWithPrice = variants.find((v) => v.price > 0)?.groupId;
  const variantsWithPrice = variants.filter((v) => v.groupId === groupIdOfVariantsWithPrice);
  const allVariantsHavePrice = variantsWithPrice?.length > 0 && variantsWithPrice.every((v) => v.price > 0);
  const minimumPrice = allVariantsHavePrice ? minimumVariantPrice : Math.min(price, minimumVariantPrice || Infinity);
  const priceText = hasProductDetails ? `${t('from')} ${formatPrice(minimumPrice)}` : formatPrice(minimumPrice);
  return (
    <>
      <h3 className={classNames(textClasses.headerMBold, classes.title)}>{name}</h3>
      <IonText color="medium" className={classNames(textClasses.textSBold, classes.description)}>
        {description}
      </IonText>
      <IonText color="primary" className={classNames(textClasses.textMBold, classes.price)}>
        {priceText}
      </IonText>
    </>
  );
};

export default ProductInfo;
