import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { IonButton, IonCheckbox, IonLabel, IonText } from '@ionic/react';
import classNames from 'classnames';

import LoadingOverlay from 'src/components/LoadingOverlay';
import FormInput from 'src/components/FormInput';
import { formatDate } from 'src/utils/time';
import { useDispatch, useSelector } from 'src/store';
import { register } from 'src/slices/auth';
import { getBirthDateFromPersonalCode } from 'src/utils';
import { useBreakpoint } from 'src/hooks';
import classes from './RegistrationForm.module.scss';
import textClasses from 'src/styles/text.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';

interface FormValuesDto {
  phoneNumber: string;
  email: string;
  name: string;
  birthDate: string;
  checkedNewsletter: boolean;
  checkedTermsAndConditions: boolean;
}

interface RegistrationFormProps {
  onCompleted?: () => void | Promise<void>;
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({ onCompleted }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobileView, isDesktopView } = useBreakpoint();
  const { gatewayStatusData, loading } = useSelector((state) => state.auth);
  const { personalCode, firstName, lastName, phoneNumber, countryCode } = gatewayStatusData || {};
  const { register: isLoading } = loading;
  const ionButtonProps = {} as { routerLink?: string; href?: string; target?: string };

  if (isMobileView) {
    ionButtonProps.routerLink = '/tabs/contact/tingimused';
  }

  if (isDesktopView) {
    ionButtonProps.href = '/tabs/contact/tingimused';
    ionButtonProps.target = '_blank';
  }

  const onFormSubmit = async (values: FormValuesDto) => {
    await dispatch(
      register({
        phoneNumber: values.phoneNumber,
        email: values.email?.trim() || null,
        isSubscribedToNewsletter: Boolean(values.email) && values.checkedNewsletter,
      })
    );
    if (onCompleted) {
      onCompleted();
    }
  };

  return (
    <div className={classes.content}>
      {isLoading && <LoadingOverlay backdrop spinnerColor="primary" />}
      <h2 className={classNames(textClasses.headerLBold, 'ion-text-center')}>{t('Confirm data')}</h2>
      <Formik
        enableReinitialize
        initialValues={{
          phoneNumber: phoneNumber || '+372',
          email: '',
          name: `${firstName} ${lastName}`,
          birthDate: formatDate(getBirthDateFromPersonalCode({ countryCode, personalCode })),
          checkedNewsletter: false,
          checkedTermsAndConditions: false,
        }}
        validationSchema={Yup.object().shape({
          phoneNumber: Yup.string()
            .matches(/^\+[1-9]{1}\d{3,49}$/, t('Invalid phone number format, enter +[country code][phone number]'))
            .max(50)
            .required(t('This is mandatory field')),
          email: Yup.string().trim().email(t('Email should be valid')).max(255),
          name: Yup.string(),
          birthDate: Yup.string().matches(
            /^([0-2]\d|3[0-1])\.(0\d|1[0-2])\.\d\d\d\d$/,
            t('Invalid date format - dd.mm.yyyy')
          ),
          checkedNewsletter: Yup.bool(),
          checkedTermsAndConditions: Yup.bool(),
        })}
        onSubmit={onFormSubmit}
      >
        {({ errors, handleBlur, setFieldValue, touched, values }): JSX.Element => (
          <Form className={classes.form}>
            <FormInput
              value={values.name}
              name="name"
              placeholder={t('Name')}
              error={Boolean(errors.name) && errors.name}
              disabled
            />
            <FormInput
              value={values.birthDate}
              name="birthDate"
              placeholder={t('Birth date')}
              error={Boolean(errors.birthDate) && errors.birthDate}
              disabled
            />
            <FormInput
              value={values.phoneNumber}
              name="phoneNumber"
              placeholder={t('Phone number')}
              error={Boolean(touched.phoneNumber && errors.phoneNumber) && errors.phoneNumber}
              onChange={(value) => setFieldValue('phoneNumber', value)}
              onBlur={handleBlur}
              required
            />
            <FormInput
              value={values.email}
              name="email"
              placeholder={t('Email')}
              error={Boolean(touched.email && errors.email) && errors.email}
              onChange={(value) => setFieldValue('email', value)}
              onBlur={handleBlur}
            />
            {values.email && (
              <div className={btnClasses.checkboxContainer}>
                <IonCheckbox
                  checked={values.checkedNewsletter}
                  onIonChange={(e) => setFieldValue('checkedNewsletter', e.detail.checked)}
                />
                <IonLabel>{t('I want to receive newsletters')}</IonLabel>
              </div>
            )}
            <div className={btnClasses.checkboxContainer}>
              <IonCheckbox
                checked={values.checkedTermsAndConditions}
                onIonChange={(e) => setFieldValue('checkedTermsAndConditions', e.detail.checked)}
              />
              <IonLabel>
                <span className={classes.checkboxLabelText}>{t('I have read and I agree with')} </span>
                <IonButton
                  color="primary"
                  fill="outline"
                  shape="round"
                  className={classNames(btnClasses.linkBtn, textClasses.textM, classes.checkboxLabelLink)}
                  {...ionButtonProps}
                >
                  {t('the terms and conditions')}.
                </IonButton>
              </IonLabel>
            </div>
            <IonButton
              className={btnClasses.primaryBtn}
              type="submit"
              expand="block"
              disabled={!values.checkedTermsAndConditions}
            >
              <IonText className={textClasses.title}>{t('Confirm')}</IonText>
            </IonButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RegistrationForm;
