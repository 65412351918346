import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';

import AppUpdateNotification from 'src/components/AppUpdateNotification';

const useAppUpdateNotification = (appInitialized: boolean) => {
  const isNative = Capacitor.isNativePlatform();

  useEffect(() => {
    const setup = async () => {
      const appInfo = await App.getInfo();
      const appUpdateInfo = await AppUpdate.getAppUpdateInfo();

      if (appUpdateInfo.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE) {
        toast((tst) => <AppUpdateNotification appId={appInfo.id} tst={tst} />, {
          duration: Infinity,
          position: 'bottom-center',
          style: {
            width: '100%',
          },
        });
      }
    };

    if (isNative && appInitialized) {
      setup();
    }
  }, [isNative, appInitialized]);
};

export default useAppUpdateNotification;
