import { useEffect } from 'react';
import { IonButton, IonIcon, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { closeOutline, timeOutline } from 'ionicons/icons';
import classNames from 'classnames';
import toast, { useToaster } from 'react-hot-toast';

import { useSelector } from 'src/store';
import classes from './ClosedSalePointWarning.module.scss';
import textClasses from 'src/styles/text.module.scss';

interface ClosedSalePointWarningProps {
  tst: any;
  title: string;
}

const ClosedSalePointWarning: React.FC<ClosedSalePointWarningProps> = ({ tst, title }) => {
  const { t } = useTranslation();
  const { showClosedSalePointWarning, showSalePointNotAvailableWarning, showTooLongCheckoutTimeWarning } = useSelector(
    (state) => state.cart
  );
  const { handlers } = useToaster();

  useEffect(() => {
    if (showClosedSalePointWarning || showSalePointNotAvailableWarning || showTooLongCheckoutTimeWarning) {
      toast.dismiss(tst?.id);
    }
  }, [showClosedSalePointWarning, showSalePointNotAvailableWarning, showTooLongCheckoutTimeWarning]);

  return (
    <div>
      <div slot="start" className={classNames(textClasses.titleBold, classes.warningHeader)}>
        <IonIcon icon={timeOutline} className={classes.clockIcon} color="danger" />
        <h3 className={classNames(textClasses.titleBold, classes.warningTitle)}>
          <IonText color="danger">{t('Warning')}!</IonText>
        </h3>
        <IonButton
          color="white"
          shape="round"
          className={classes.closeBtn}
          onClick={() => {
            toast.dismiss(tst?.id);
            handlers.endPause();
          }}
        >
          <IonIcon slot="icon-only" icon={closeOutline} />
        </IonButton>
      </div>
      <p className={textClasses.textM}>{title}</p>
    </div>
  );
};

export default ClosedSalePointWarning;
