import { IonList, IonItem, IonGrid, IonRow, IonCol, IonRippleEffect } from '@ionic/react';
import classNames from 'classnames';

import ProductImage from 'src/components/menu/products/ProductImage';
import ProductInfo from 'src/components/menu/products/ProductInfo';
import ProductQuickAdd from 'src/components/menu/products/ProductQuickAdd';
import { useDispatch } from 'src/store';
import { useBreakpoint, useCategoryProductsGetter } from 'src/hooks';
import { openProductDetails, selectProduct } from 'src/slices/product';
import classes from './Products.module.scss';
import { ProductJoinInfoDto } from 'src/types/generated';

interface ViewListItemProps {
  product: ProductJoinInfoDto;
  onClick: (id: number) => void | Promise<void>;
  tabIndex?: number;
}

const DesktopViewListItem: React.FC<ViewListItemProps> = ({ tabIndex, product, onClick }) => {
  const colProps = {} as any;
  const hasProductDetails = product.variants?.length > 0 || product.components?.length > 0;
  if (hasProductDetails && onClick) {
    colProps.onClick = () => onClick(product.id);
  }
  return (
    <IonCol
      size="12"
      size-md="6"
      size-lg="4"
      size-xl="3"
      className={classNames(classes.desktopListItemCol, {
        [classes.desktopListItemColClickable]: hasProductDetails,
        'ion-activatable': hasProductDetails,
        'ripple-parent': hasProductDetails,
      })}
      tabIndex={tabIndex}
      {...colProps}
    >
      <ProductImage imageSrc={product.imageThumbnailUrl} />
      <ProductInfo product={product} hasProductDetails={hasProductDetails} />
      {hasProductDetails && <IonRippleEffect />}
      {!hasProductDetails && <ProductQuickAdd productId={product.id} />}
    </IonCol>
  );
};

const MobileViewListItem: React.FC<ViewListItemProps> = ({ product, onClick }) => {
  const hasProductDetails = product.variants?.length > 0 || product.components?.length > 0;
  const itemProps = {} as any;
  if (hasProductDetails) {
    itemProps.onClick = () => onClick(product.id);
    itemProps.routerLink = '/tabs/menu/details';
    itemProps.button = true;
  }
  return (
    <IonItem lines="none" detail={false} {...itemProps} className={classes.mobileListItem}>
      <IonGrid className={classNames(classes.mobileListItemGrid, 'ion-no-padding')}>
        <IonRow className="ion-nowrap ion-align-items-start">
          <IonCol size="auto" className="ion-no-padding">
            <ProductImage imageSrc={product.imageThumbnailUrl} />
          </IonCol>
          <IonCol className={classNames(classes.mobileListItemInfo, 'ion-no-padding')}>
            <ProductInfo product={product} hasProductDetails={hasProductDetails} />
            {!hasProductDetails && <ProductQuickAdd productId={product.id} />}
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

const Products: React.FC = () => {
  const dispatch = useDispatch();
  const { isMobileView, isDesktopView } = useBreakpoint();
  const categoryProducts = useCategoryProductsGetter();

  const onProductDesktopClick = (id: number) => {
    dispatch(selectProduct(id));
    dispatch(openProductDetails(true));
  };

  const onProductMobileClick = (id: number) => {
    dispatch(selectProduct(id));
  };

  return (
    <div>
      {isDesktopView && (
        <IonGrid className="ion-no-padding">
          <IonRow>
            {categoryProducts.map((p, index) => (
              <DesktopViewListItem key={p.id} tabIndex={index} product={p} onClick={onProductDesktopClick} />
            ))}
          </IonRow>
        </IonGrid>
      )}
      {isMobileView && (
        <IonList>
          {categoryProducts.map((p) => (
            <MobileViewListItem key={p.id} product={p} onClick={onProductMobileClick} />
          ))}
        </IonList>
      )}
    </div>
  );
};

export default Products;
