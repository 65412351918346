import { IonContent, IonModal } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import ProductDetails from 'src/components/menu/ProductDetails';
import DetailsFooter from 'src/components/menu/details/DetailsFooter';
import CloseButton from 'src/components/CloseButton';
import { useDispatch, useSelector } from 'src/store';
import { openProductDetails, resetAllProductsSelections } from 'src/slices/product';
import classes from './ProductDetailsModal.module.scss';

const ProductDetailsModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isProductDetailsOpened, selectedProductId, products } = useSelector((state) => state.product);
  const product = products ? products.find((p) => p.id === selectedProductId) : null;

  const onCloseBtnClick = async () => {
    dispatch(openProductDetails(false));
  };
  const onModalDismiss = () => {
    dispatch(openProductDetails(false));
    dispatch(resetAllProductsSelections());
  };

  return (
    <IonModal isOpen={isProductDetailsOpened} onDidDismiss={onModalDismiss} cssClass={classes.modal}>
      <IonContent>
        <div className={classes.closeBtnContainer}>
          <CloseButton onClose={onCloseBtnClick} />
        </div>
        <div className={classes.content}>
          {product && <ProductDetails product={product} />}
          {!product && <p>{t('Product data was not found')}</p>}
        </div>
      </IonContent>
      {product && <DetailsFooter product={product} />}
    </IonModal>
  );
};

export default ProductDetailsModal;
