import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { SalePointAvailableTimeDto, SalePointDto, SalePointWorkingDayDto } from 'src/types/generated';
import type { AppThunk } from 'src/store';
import API from 'src/api';
import { setStorageItem, removeStorageItem } from 'src/utils/storage';
import { updateMe } from 'src/slices/auth';
import { resetCart } from 'src/slices/cart';
import { getProducts } from './product';

interface SelectSalePointPayload {
  salePointId: number;
  saveSelection?: boolean;
}

interface LoadingState {
  salePoints?: boolean;
  workingDays?: boolean;
}

interface SalePointState {
  salePoints: SalePointDto[];
  salePointWorkingDays: SalePointWorkingDayDto[];
  salePointAvailableTimes: SalePointAvailableTimeDto[];
  selectedSalePointId: number | null;
  isSalePointPickerOpened: boolean;
  loading: LoadingState;
}

const initialState: SalePointState = {
  salePoints: [],
  salePointWorkingDays: null,
  salePointAvailableTimes: null,
  selectedSalePointId: null,
  isSalePointPickerOpened: false,
  loading: {
    salePoints: false,
    workingDays: false,
  },
};

const slice = createSlice({
  name: 'salePoint',
  initialState,
  reducers: {
    getSalePoints(state: SalePointState, action: PayloadAction<SalePointDto[]>): void {
      state.salePoints = action.payload;
    },
    getSalePointWorkingDays(state: SalePointState, action: PayloadAction<SalePointWorkingDayDto[]>): void {
      state.salePointWorkingDays = action.payload;
    },
    getSalePointAvailableTimes(state: SalePointState, action: PayloadAction<SalePointAvailableTimeDto[]>): void {
      state.salePointAvailableTimes = action.payload;
    },
    selectSalePoint(state: SalePointState, action: PayloadAction<number>): void {
      state.selectedSalePointId = action.payload;
    },
    setLoading(state: SalePointState, action: PayloadAction<LoadingState>): void {
      state.loading = { ...state.loading, ...action.payload };
    },
    openModal(state: SalePointState, action: PayloadAction<boolean>): void {
      state.isSalePointPickerOpened = action.payload;
    },
  },
});

export const { reducer } = slice;

const saveSalePointSelection =
  (salePointId: number): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { isAuthenticated } = getState().auth;

    await setStorageItem('selectedSalePointId', String(salePointId));
    if (isAuthenticated) {
      await dispatch(updateMe({ favoriteSalePointId: salePointId }));
    }
  };

const removeSalePointSelection =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { isAuthenticated } = getState().auth;

    await removeStorageItem('selectedSalePointId');
    if (isAuthenticated) {
      await dispatch(updateMe({ favoriteSalePointId: null }));
    }
  };

export const getSalePoints =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setLoading({ salePoints: true }));
    try {
      const data = await API.salePoint.getAll();

      dispatch(slice.actions.getSalePoints(data));
    } finally {
      dispatch(slice.actions.setLoading({ salePoints: false }));
    }
  };

export const getSalePointOpeningHours =
  (salePointId?: number): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { selectedSalePointId } = getState().salePoint;
    dispatch(slice.actions.setLoading({ workingDays: true }));
    if (!salePointId && !selectedSalePointId) return;

    try {
      const workingDays = await API.salePoint.getWorkingDays(String(salePointId || selectedSalePointId));
      const availableTimes = await API.salePoint.getAvailableTimes(String(salePointId || selectedSalePointId));

      dispatch(slice.actions.getSalePointWorkingDays(workingDays));
      dispatch(slice.actions.getSalePointAvailableTimes(availableTimes));
    } finally {
      dispatch(slice.actions.setLoading({ workingDays: false }));
    }
  };

export const selectSalePoint =
  ({ salePointId, saveSelection = true }: SelectSalePointPayload): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { cartItems } = getState().cart;
    const { salePoints, selectedSalePointId } = getState().salePoint;
    const selectedSalePointExists = salePoints.some((s) => s.id === salePointId);
    const selectedSameSalePoint = salePointId === selectedSalePointId;

    if (selectedSameSalePoint) {
      return;
    }

    if (!selectedSalePointExists) {
      dispatch(slice.actions.selectSalePoint(null));
      await dispatch(removeSalePointSelection());
      return;
    }

    if (cartItems.length) {
      await dispatch(resetCart());
    }

    if (saveSelection) await dispatch(saveSalePointSelection(salePointId));

    await dispatch(getSalePointOpeningHours(salePointId));
    dispatch(slice.actions.selectSalePoint(salePointId));

    await dispatch(getProducts());
  };

export const openModal =
  (modalState: boolean): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { salePoints } = await getState().salePoint;
    if (salePoints?.length > 0) {
      dispatch(slice.actions.openModal(modalState));
    }
  };

export default slice;
