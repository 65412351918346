import API from './api';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import toast from 'react-hot-toast';

import AuthApi from './pizzaKioskModules/auth';
import SalePointApi from './pizzaKioskModules/salePoints';
import ProductApi from './pizzaKioskModules/products';
import ProductCategoryApi from './pizzaKioskModules/products/categories';
import ProductVariantApi from './pizzaKioskModules/products/variants';
import ProductVariantGroupApi from './pizzaKioskModules/products/variantGroups';
import LanguageApi from './pizzaKioskModules/languages';
import SystemTranslationsApi from './pizzaKioskModules/systemTranslations';
import StaticPageTemplateApi from './pizzaKioskModules/staticPageTemplates';
import OrderApi from './pizzaKioskModules/orders';
import PaymentApi from './pizzaKioskModules/payment';
import VipApi from './pizzaKioskModules/vips';
import CampaignApi from './pizzaKioskModules/campaigns';
import store from 'src/store';
import { getStorageItem } from 'src/utils/storage';
import { checkInternetConnection } from 'src/utils';
import { setInternetConnection } from 'src/slices/system';
import { ERROR_CODES } from 'src/constants';
import { i18next } from 'src/hooks/useDataLoading';
import CartApi from './pizzaKioskModules/cart';

class PizzaKioskAPI extends API {
  public auth: AuthApi;

  public salePoint: SalePointApi;

  public language: LanguageApi;

  public systemTranslations: SystemTranslationsApi;

  public product: ProductApi;

  public productCategory: ProductCategoryApi;

  public productVariant: ProductVariantApi;

  public productVariantGroup: ProductVariantGroupApi;

  public staticPages: StaticPageTemplateApi;

  public order: OrderApi;

  public payment: PaymentApi;

  public vip: VipApi;

  public campaign: CampaignApi;

  public cart: CartApi;

  constructor() {
    super(`${process.env.REACT_APP_PIZZAKIOSK_API_URI}/api/public`);

    this.auth = new AuthApi(this.instance);
    this.salePoint = new SalePointApi(this.instance);
    this.language = new LanguageApi(this.instance);
    this.systemTranslations = new SystemTranslationsApi(this.instance);
    this.product = new ProductApi(this.instance);
    this.productCategory = new ProductCategoryApi(this.instance);
    this.productVariant = new ProductVariantApi(this.instance);
    this.productVariantGroup = new ProductVariantGroupApi(this.instance);
    this.staticPages = new StaticPageTemplateApi(this.instance);
    this.order = new OrderApi(this.instance);
    this.payment = new PaymentApi(this.instance);
    this.vip = new VipApi(this.instance);
    this.campaign = new CampaignApi(this.instance);
    this.cart = new CartApi(this.instance);
  }

  public async onRequest(request: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    const accessToken = await getStorageItem('accessToken');
    const { selectedLanguageId: systemStoreLanguageId } = store.getState().system || {};
    const { languageId: storeLanguageId, favoriteSalePointId: storeSalePointId } = store.getState().auth.user || {};
    const { hasInternetConnection } = store.getState().system;
    const userLanguageId = storeLanguageId || (await getStorageItem('selectedLanguageId')) || systemStoreLanguageId;
    const salePointId = storeSalePointId || (await getStorageItem('selectedSalePointId'));

    if (!hasInternetConnection) {
      return {
        ...request,
        cancelToken: new axios.CancelToken((cancel) => cancel(`Cancel ${request.method} ${request.url} request`)),
      };
    }

    if (accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    }
    if (userLanguageId) {
      request.headers['x-user-language-id'] = userLanguageId;
    }
    if (salePointId) {
      request.headers['x-sale-point-id'] = salePointId;
    }
    return request;
  }

  public async onRequestResponseError(error: AxiosError) {
    const { response } = error;
    if (!response) {
      const hasInternetConnection = await checkInternetConnection();
      store.dispatch(setInternetConnection(hasInternetConnection));
    }
    const errorMessage = response?.data?.error || response?.data || error?.message || 'Network request failed';
    const errorCode = response?.status === 401 ? ERROR_CODES.AUTHENTICATION_ERROR : response?.data?.code;
    let errorText = '';
    switch (errorCode) {
      case ERROR_CODES.CLIENT_PERSONAL_CODE_ALREADY_REGISTERED:
        errorText = i18next.t('Personal code already registered');
        break;
      case ERROR_CODES.CLIENT_PHONE_NUMBER_ALREADY_REGISTERED:
        errorText = i18next.t('Phone number already registered');
        break;
      case ERROR_CODES.CLIENT_EMAIL_ALREADY_REGISTERED:
        errorText = i18next.t('Email already registered');
        break;
      case ERROR_CODES.AUTHENTICATION_ERROR:
        errorText = i18next.t('User login session has expired. Please log in again');
        break;
      case ERROR_CODES.SALEPOINT_CLOSED:
        errorText = i18next.t('Selected sale point is closed and not available for orders!');
        break;
      default:
        errorText = errorMessage;
    }
    if (process.env.NODE_ENV !== 'production' || Object.values(ERROR_CODES).some((errCode) => errCode === errorCode)) {
      toast.error(errorText);
      console.error(errorMessage);
    }
    return Promise.reject(error);
  }

  public initApi() {
    this.configureInterceptors();
  }
}

const pizzaKioskAPI = new PizzaKioskAPI();
pizzaKioskAPI.initApi();

// Make the instance debuggable
if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
  (window as any).API = pizzaKioskAPI;
}

export default pizzaKioskAPI;
