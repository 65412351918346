import { useTranslation } from 'react-i18next';
import { IonText } from '@ionic/react';
import classNames from 'classnames';

import textClasses from 'src/styles/text.module.scss';

const NoSalePointPlaceholder: React.FC = () => {
  const { t } = useTranslation();
  return (
    <IonText className="ion-text-center">
      <h3 className={classNames(textClasses.title, 'ion-margin-vertical')}>{t('Sale point is not selected!')}</h3>
      <p className={classNames(textClasses.regularText, 'ion-margin-vertical')}>
        {t('Please set location of sale point.')}
      </p>
    </IonText>
  );
};

export default NoSalePointPlaceholder;
