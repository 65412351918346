import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonRow, IonText } from '@ionic/react';
import { createOutline } from 'ionicons/icons';

import ProductImage from 'src/components/menu/products/ProductImage';
import { ProductJoinInfoDto } from 'src/types/generated';
import { SelectedCampaignProduct } from 'src/slices/campaign';
import classes from './CampaignProductItem.module.scss';
import textClasses from 'src/styles/text.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';

interface CampaignProductItemProps {
  campaignProduct: SelectedCampaignProduct;
  product: ProductJoinInfoDto;
  onItemSelect?: (campaignProduct: SelectedCampaignProduct) => void | Promise<void>;
  onSlotEdit?: (isFreeSlot: boolean, slotNumber: number) => void | Promise<void>;
  onProductEdit?: (campaignProduct: SelectedCampaignProduct) => void | Promise<void>;
  isFreeSlot?: boolean;
  isSelected?: boolean;
  showEditButtons?: boolean;
}

const CampaignProductItem: React.FC<CampaignProductItemProps> = ({
  campaignProduct,
  product,
  onItemSelect,
  onSlotEdit,
  onProductEdit,
  isFreeSlot,
  isSelected,
  showEditButtons,
}) => {
  const { t } = useTranslation();

  if (!product) return null;

  const { variantId, slotNumber } = campaignProduct;
  const { name: productName, description: productDescription, imageThumbnailUrl, variants } = product;
  const productVariant = variantId ? variants.find((v) => v.variantId === variantId) : null;
  const title = `${productVariant?.name || ''} ${productName}`.trim();

  const onItemClick = () => {
    if (onItemSelect) {
      onItemSelect(campaignProduct);
    }
  };

  const onSlotEditClick = (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    if (onSlotEdit) {
      onSlotEdit(isFreeSlot, slotNumber);
    }
  };

  const onProductEditClick = (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    if (onProductEdit) {
      onProductEdit(campaignProduct);
    }
  };

  return (
    <IonCol size="12" size-md="6" className={classes.itemCol}>
      <IonItem
        lines="none"
        detail={false}
        button={!!onItemSelect}
        onClick={onItemClick}
        className={classNames(classes.listItem, { [classes.selectedListItem]: isSelected })}
      >
        <IonGrid className={classNames(classes.listItemGrid, 'ion-no-padding')}>
          <IonRow className="ion-nowrap ion-align-items-start">
            <IonCol size="auto" className="ion-no-padding">
              <ProductImage
                imageSrc={imageThumbnailUrl}
                imgClass={classes.itemImg}
                placeholderImgClass={classes.itemImgPlaceholder}
              />
            </IonCol>

            <IonCol className={classNames(classes.listItemInfo, 'ion-no-padding')}>
              <h3 className={textClasses.headerMBold}>{title}</h3>
              <IonText
                color="medium"
                className={classNames(textClasses.textSBold, { [classes.itemDescription]: showEditButtons })}
              >
                {productDescription}
              </IonText>
              {showEditButtons && (
                <div className={classes.editBtnsContainer}>
                  <IonButton
                    onClick={(e) => onSlotEditClick(e)}
                    color="primary"
                    className={classNames(btnClasses.chipBtn, classes.editBtn)}
                  >
                    <IonText className={classes.editBtnText}>{t('Choose')}</IonText>
                  </IonButton>
                  {!isFreeSlot && (
                    <IonButton
                      onClick={(e) => onProductEditClick(e)}
                      color="primary"
                      className={classNames(btnClasses.chipBtn, classes.editBtn, classes.productEditBtn)}
                    >
                      <IonIcon icon={createOutline} className={classes.editBtnIcon} />
                    </IonButton>
                  )}
                </div>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
    </IonCol>
  );
};

export default CampaignProductItem;
