import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonList, IonItem, IonGrid, IonRow, IonCol, IonSearchbar, IonText, IonContent, IonAlert } from '@ionic/react';
import _ from 'lodash';
import { add, format, startOfDay } from 'date-fns';
import toast from 'react-hot-toast';
import classNames from 'classnames';

import LoadingOverlay from 'src/components/LoadingOverlay';
import SignInButton from 'src/components/SignInButton';
import LanguagePicker from 'src/components/LanguagePicker';
import BackButton from 'src/components/BackButton';
import ClosedSalePointWarning from 'src/components/ClosedSalePointWarning';
import { useDispatch, useSelector } from 'src/store';
import { useBreakpoint } from 'src/hooks';
import { selectSalePoint, openModal as openSalePointPickerModal } from 'src/slices/salePoint';
import { LocationService, Position } from 'src/utils/nativeServices';
import { getDistanceBetweenTwoPoints } from 'src/utils';
import classes from 'src/components/SalePointPicker.module.scss';
import textClasses from 'src/styles/text.module.scss';
import { SalePointDto } from 'src/types/generated';

interface SortedSalePoint extends SalePointDto {
  distanceToUser?: number;
}

const SalePointPicker: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { salePoints, loading, selectedSalePointId } = useSelector((state) => state.salePoint);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const { isMobileView } = useBreakpoint();
  const [searchText, setSearchText] = useState<string>('');
  const [userPosition, setUserPosition] = useState<Position>(null);
  const [showCartEmptyAlert, setShowCartEmptyAlert] = useState(false);
  const [newSalePointId, setNewSalePointId] = useState(null);
  const isLoading = loading?.salePoints;

  useEffect(() => {
    const init = async () => {
      try {
        const position = await LocationService.askForPosition();
        setUserPosition(position);
      } catch (error) {
        console.error(error?.message || error.toString());
      }
    };

    init();
  }, []);

  const onSearchBarChange = (e: CustomEvent) => {
    setSearchText(e.detail.value);
  };

  const onSalePointClick = async (salePointId: number, enabled: boolean) => {
    if (salePointId === selectedSalePointId) {
      await dispatch(openSalePointPickerModal(false));
      return;
    }

    if (cartItems.length) {
      setNewSalePointId(salePointId);
      setShowCartEmptyAlert(true);
      return;
    }

    await dispatch(openSalePointPickerModal(false));
    await dispatch(selectSalePoint({ salePointId }));

    if (!enabled) {
      toast(
        (tst) => (
          <ClosedSalePointWarning tst={tst} title={t('Selected sale point is closed and not available for orders!')} />
        ),
        { duration: Infinity, position: isMobileView ? 'bottom-center' : 'top-center' }
      );
    }
  };

  const onSignInBtnClick = () => {
    dispatch(openSalePointPickerModal(false));
  };

  const onAlertDismiss = async (e: CustomEvent) => {
    setShowCartEmptyAlert(false);
    if (e.detail.role === 'confirm' && newSalePointId) {
      await dispatch(selectSalePoint({ salePointId: newSalePointId }));
      await dispatch(openSalePointPickerModal(false));
    }
  };

  const onBackButtonClick = () => {
    dispatch(openSalePointPickerModal(false));
  };

  const matchRegExp = new RegExp(searchText, 'gi');
  const filteredSalePoints = salePoints.filter((s) => s.name.match(matchRegExp));
  let sortedSalePoints: SortedSalePoint[] = _.orderBy(filteredSalePoints, ['name', 'asc']);
  if (userPosition) {
    sortedSalePoints = filteredSalePoints.map((s) => {
      const distanceToUser = getDistanceBetweenTwoPoints(userPosition, {
        latitude: s.coordinateLat,
        longitude: s.coordinateLng,
      });
      return { ...s, distanceToUser };
    });
    sortedSalePoints = _.orderBy(sortedSalePoints, ['distanceToUser', 'asc']);
  }

  return (
    <IonContent>
      <div className={classes.container}>
        {isLoading && <LoadingOverlay />}
        <IonGrid className={classes.header}>
          <IonRow className="ion-justify-content-between ion-align-items-center">
            {selectedSalePointId && (
              <IonCol size="auto">
                <BackButton onClick={onBackButtonClick} />
              </IonCol>
            )}
            <IonCol size="auto">
              <LanguagePicker />
            </IonCol>
            {!isAuthenticated && (
              <IonCol className="ion-text-right">
                <SignInButton onClick={onSignInBtnClick} />
              </IonCol>
            )}
          </IonRow>
          <IonRow>
            <IonCol size="auto">
              <h1 className={textClasses.headerLBold}>{t('Choose location')}</h1>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonSearchbar
          value={searchText}
          onIonChange={onSearchBarChange}
          mode="ios"
          clearIcon="close"
          searchIcon="search-sharp"
          placeholder=""
          className={classes.searchbar}
        >
          <div className={classes.searchbarBackgroundExtender} />
        </IonSearchbar>
        <IonList className={classes.list}>
          {sortedSalePoints.map((s) => {
            const isLongWaitingTime = s.minCheckoutTime >= 60;
            const waitingTimeFormat = isLongWaitingTime ? `H'${t('h')}' m'${t('m')}!'` : `m'${t('m')}'`;
            const waitingTimeDate = add(startOfDay(new Date()), { minutes: s.minCheckoutTime });
            const waitingTimeLabel = isLongWaitingTime ? t('Long waiting time') : t('Waiting time');
            const waitingTimeTitle = `${waitingTimeLabel} ${format(waitingTimeDate, waitingTimeFormat)}`;

            const getDescription = () => {
              if (!s.enabled) return t('Temporarily closed');
              if (s.isClosedToday) return t('Closed today');
              return waitingTimeTitle;
            };

            return (
              <IonItem
                key={s.id}
                onClick={() => onSalePointClick(s.id, s.enabled)}
                className={classNames(classes.listItem, 'ion-align-items-start', {
                  [classes.listItemDisabled]: !s.enabled,
                })}
                lines="none"
                button
                detail={false}
              >
                <div>
                  <p className={textClasses.textLBold}>{s.name}</p>
                  <IonText color="medium">
                    <p className={textClasses.textM}>{getDescription()}</p>
                  </IonText>
                </div>
                <IonText slot="end" color="dark">
                  {userPosition && !!s.distanceToUser && (
                    <p className={textClasses.textL}>
                      {`${(s.distanceToUser / 1000).toFixed(2).replace('.', ',')} km`}{' '}
                    </p>
                  )}
                </IonText>
              </IonItem>
            );
          })}
        </IonList>
        {Array.isArray(salePoints) && (!salePoints.length || !sortedSalePoints.length) && !isLoading && (
          <p className={classNames(textClasses.title, 'ion-text-center')}>{t('Sale point was not found')}</p>
        )}
      </div>
      <IonAlert
        isOpen={showCartEmptyAlert}
        onDidDismiss={onAlertDismiss}
        header={t('Warning')}
        message={t('Your cart has some items, on sale point change cart will get empty')}
        buttons={[
          {
            text: t('Cancel'),
            role: 'cancel',
          },
          {
            text: t('Confirm'),
            role: 'confirm',
          },
        ]}
      />
    </IonContent>
  );
};

export default SalePointPicker;
