import { OrderCreateReqDto, OrderJoinDetailsDto, OrderJoinGeneralDto, OrderDto } from 'src/types/generated';
import BaseReadApiModule from 'src/api/baseRead';
import { IPaginationOptions } from 'src/api/base';

class OrderApi extends BaseReadApiModule<OrderJoinGeneralDto> {
  protected baseRoute = '/orders';

  public getMyOrder(id: string | number) {
    return this._get<OrderJoinDetailsDto>(`${this.baseRoute}/my/${id}`);
  }

  public createOrder(model: OrderCreateReqDto) {
    return this._post<OrderDto>(`${this.baseRoute}`, model);
  }

  public getMyOrders(options?: IPaginationOptions) {
    return this.pagedRequest<OrderJoinGeneralDto>(`${this.baseRoute}/my`, options);
  }
}

export default OrderApi;
