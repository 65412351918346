import { useEffect, useRef, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { IonButton, IonCheckbox, IonIcon, IonLabel, IonText, useIonViewWillEnter } from '@ionic/react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import DetailsLayout from 'src/components/layout/DetailsLayout';
import FormInput from 'src/components/FormInput';
import LoadingOverlay from 'src/components/LoadingOverlay';
import { useDispatch, useSelector } from 'src/store';
import { useBreakpoint, useCartPriceGetter } from 'src/hooks';
import {
  createOrder,
  initGuestClientData,
  setClientPhoneNumber,
  initConfirmationData,
  setConfirmationData,
  CreateOrderResponse,
} from 'src/slices/order';
import { getBanks } from 'src/slices/payment';
import { setPaymentInvoked } from 'src/slices/cart';
import classes from './PaymentPage.module.scss';
import textClasses from 'src/styles/text.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';
import layoutClasses from 'src/styles/layout.module.scss';
import cartIcon from 'src/assets/img/cart.svg';

interface PaymentPageProps extends RouteComponentProps {}

interface FormValuesDto {
  phoneNumber: string;
  confirmationEmail: string;
  confirmationName: string;
  checkedTermsAndConditions: boolean;
  checkedOrderConfirmationEmail: boolean;
  isGuest: boolean;
}

const PaymentPage: React.FC<PaymentPageProps> = ({ history, location, match }) => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const dispatch = useDispatch();
  const { isMobileView, isDesktopView } = useBreakpoint();
  const [selectedBank, setSelectedBank] = useState<string>();
  const [orderCreated, setOrderCreated] = useState<boolean>(false);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { cartItems, wasPaymentInvokedFromCart } = useSelector((state) => state.cart);
  const { banks, loading: paymentLoading } = useSelector((state) => state.payment);
  const {
    confirmationEmail,
    confirmationName,
    clientPhoneNumber,
    loading: orderLoading,
  } = useSelector((state) => state.order);
  const { total: totalPrice } = useCartPriceGetter();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      phoneNumber: clientPhoneNumber || '+372',
      confirmationEmail,
      confirmationName,
      checkedTermsAndConditions: false,
      checkedOrderConfirmationEmail: true,
      isGuest: !isAuthenticated,
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string().when('isGuest', {
        is: true,
        then: Yup.string()
          .matches(/^\+[1-9]{1}\d{3,49}$/, t('Invalid phone number format, enter +[country code][phone number]'))
          .max(50)
          .required(t('This is mandatory field')),
      }),
      confirmationEmail: Yup.string().when('checkedOrderConfirmationEmail', {
        is: true,
        then: Yup.string().trim().email(t('Email should be valid')).max(255).required(t('This is mandatory field')),
      }),
      confirmationName: Yup.string().when('checkedOrderConfirmationEmail', {
        is: true,
        then: Yup.string().max(255).required(t('This is mandatory field')),
      }),
      checkedTermsAndConditions: Yup.bool(),
      checkedOrderConfirmationEmail: Yup.bool(),
    }),
    // eslint-disable-next-line
    onSubmit: (_values: FormValuesDto) => {},
  });

  const isLoading = orderLoading.orderCreate || paymentLoading.banks || orderCreated;
  const routerProps = { history, location, match };
  const { errors, handleBlur, setFieldValue, touched, values } = formik;
  const hasCartItems = cartItems?.length > 0;
  const paidOrder = totalPrice > 0;
  const confirmBtnDisabled = ((!values.checkedTermsAndConditions && values.isGuest) || !selectedBank) && paidOrder;
  const ionButtonProps = {} as {
    routerLink?: string;
    routerDirection?: 'root' | 'none' | 'forward' | 'back';
    href?: string;
    target?: string;
  };

  if (isMobileView) {
    ionButtonProps.routerLink = '/tabs/contact/tingimused';
    ionButtonProps.routerDirection = 'root';
  }

  if (isDesktopView) {
    ionButtonProps.href = '/tabs/contact/tingimused';
    ionButtonProps.target = '_blank';
  }

  useIonViewWillEnter(() => {
    if (!banks && hasCartItems) {
      dispatch(getBanks());
    }
    dispatch(initConfirmationData());
    dispatch(initGuestClientData());
  }, [banks, hasCartItems]);

  useEffect(() => {
    return () => {
      if (orderCreated) {
        dispatch(setPaymentInvoked(false));
      }
    };
  }, [orderCreated]);

  if (!wasPaymentInvokedFromCart) {
    return <Redirect to="/tabs/cart" />;
  }

  const onBankBtnClick = async (bank: string) => {
    setSelectedBank(bank);
  };

  const onConfirmOrderBtnClick = async () => {
    const formikErrors = await formik.validateForm();
    await formik.submitForm();
    if (!isEmpty(formikErrors)) {
      const errorFieldName = Object.keys(formikErrors)?.[0];
      const errorFieldElement = formRef.current?.querySelector?.(`[name="${errorFieldName}"]`);
      if (errorFieldElement) {
        errorFieldElement.scrollIntoView({ behavior: 'smooth' });
      } else {
        formRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
      return;
    }

    if (values.isGuest) {
      await dispatch(setClientPhoneNumber(values.phoneNumber));
    }
    await dispatch(
      setConfirmationData({
        confirmationEmail: values.confirmationEmail?.trim(),
        confirmationName: values.confirmationName,
        checkedOrderConfirmationEmail: values.checkedOrderConfirmationEmail,
      })
    );

    const { paymentLink, createdOrderId, hasUnverifiedCartItems } =
      ((await dispatch(createOrder({ selectedBank }))) as unknown as CreateOrderResponse) || {};

    if (hasUnverifiedCartItems) {
      history.goBack();
      return;
    }

    if (createdOrderId) {
      setOrderCreated(true);
    }

    if (paymentLink) {
      const url = new URL(paymentLink);
      window.open(url.href, '_self');
    }

    if (!paymentLink && createdOrderId) {
      history.replace(`/tabs/orders/${createdOrderId}/thank-you`);
    }
  };

  return (
    <DetailsLayout
      meta={{
        title: t('-SEO Payment page'),
        description: t('-SEO Payment page description'),
      }}
      headerTitle={t('Confirm order')}
      contentHeaderTitle={t('Confirm order')}
      {...routerProps}
    >
      <div className={classNames(layoutClasses.containerMedium, classes.sectionsWrapper)}>
        {!hasCartItems && (
          <div className="ion-text-center">
            <h2 className={classNames(textClasses.titleBold, 'ion-margin-vertical')}>
              {t('Your cart is currently empty')}
            </h2>
            <IonButton
              routerLink="/tabs/menu"
              routerDirection="root"
              className={classNames(btnClasses.primaryBtn, textClasses.title)}
            >
              {t('Return to menu')}
            </IonButton>
          </div>
        )}
        {hasCartItems && (
          <>
            {isLoading && <LoadingOverlay backdrop spinnerColor="primary" />}

            <div className={classes.section}>
              {errors && handleBlur && setFieldValue && touched && values && (
                <form onSubmit={formik.handleSubmit} ref={formRef}>
                  {values.isGuest && (
                    <div>
                      <h2 className={classNames(textClasses.titleBold, 'ion-margin-bottom')}>{t('Customer info')}</h2>
                      <FormInput
                        value={values.phoneNumber}
                        name="phoneNumber"
                        placeholder={t('Phone number')}
                        error={Boolean(touched.phoneNumber && errors.phoneNumber) && errors.phoneNumber}
                        onChange={(value) => setFieldValue('phoneNumber', value)}
                        onBlur={handleBlur}
                        required
                      />
                      <div className={btnClasses.checkboxContainer}>
                        <IonCheckbox
                          checked={values.checkedTermsAndConditions}
                          onIonChange={(e) => setFieldValue('checkedTermsAndConditions', e.detail.checked)}
                        />
                        <IonLabel>
                          <span className={classes.checkboxLabelText}>{t('I have read and I agree with')} </span>
                          <IonButton
                            color="primary"
                            fill="outline"
                            shape="round"
                            className={classNames(btnClasses.linkBtn, textClasses.textM, classes.checkboxLabelLink)}
                            {...ionButtonProps}
                          >
                            {t('the terms and conditions')}.
                          </IonButton>
                        </IonLabel>
                      </div>
                    </div>
                  )}
                  <div className={btnClasses.checkboxContainer}>
                    <IonCheckbox
                      checked={values.checkedOrderConfirmationEmail}
                      onIonChange={(e) => setFieldValue('checkedOrderConfirmationEmail', e.detail.checked)}
                    />
                    <IonLabel>{t('I wish to get a confirmation email')}</IonLabel>
                  </div>
                  {values.checkedOrderConfirmationEmail && (
                    <div>
                      <FormInput
                        value={values.confirmationEmail}
                        name="confirmationEmail"
                        placeholder={t('Email')}
                        error={
                          Boolean(touched.confirmationEmail && errors.confirmationEmail) && errors.confirmationEmail
                        }
                        onChange={(value) => setFieldValue('confirmationEmail', value)}
                        onBlur={handleBlur}
                        required={values.checkedOrderConfirmationEmail}
                      />
                      <FormInput
                        value={values.confirmationName}
                        name="confirmationName"
                        placeholder={`${t('Name')} / ${t('Invoice recipient')}`}
                        error={Boolean(touched.confirmationName && errors.confirmationName) && errors.confirmationName}
                        onChange={(value) => setFieldValue('confirmationName', value)}
                        onBlur={handleBlur}
                        required={values.checkedOrderConfirmationEmail}
                      />
                    </div>
                  )}
                </form>
              )}
            </div>

            {banks?.length > 0 && paidOrder && (
              <div className={classes.section}>
                <h2 className={textClasses.titleBold}>{t('Choose payment method')}</h2>
                <div className="ion-margin-vertical">
                  {banks.map((bank) => {
                    return (
                      <IonButton
                        key={bank.code}
                        className={classNames(btnClasses.outlineBtn, classes.bankBtn, {
                          [classes.selectedBankBtn]: selectedBank === bank.code,
                        })}
                        shape="round"
                        fill="outline"
                        onClick={() => onBankBtnClick(bank.code)}
                      >
                        <img src={bank.logoUrl} className={classes.bankLogo} alt="Bank logo" />
                      </IonButton>
                    );
                  })}
                </div>
              </div>
            )}
            <div className={classes.section}>
              <IonButton
                expand="block"
                disabled={confirmBtnDisabled}
                onClick={onConfirmOrderBtnClick}
                className={classNames(btnClasses.primaryBtn, classes.confirmBtn, textClasses.title)}
              >
                <IonIcon src={cartIcon} slot="start" className={classes.confirmBtnIcon} />
                <IonText className={classes.confirmBtnText}>{t('Confirm order')}</IonText>
              </IonButton>
            </div>
          </>
        )}
      </div>
    </DetailsLayout>
  );
};

export default PaymentPage;
