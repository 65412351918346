import { useTranslation } from 'react-i18next';
import { IonButton } from '@ionic/react';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'src/store';
import { openModal } from 'src/slices/salePoint';
import { SalePointDto } from 'src/types/generated';
import textClasses from 'src/styles/text.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';

interface SetLocationButtonProps {
  salePoint: SalePointDto;
}

const SetLocationButton: React.FC<SetLocationButtonProps> = ({ salePoint }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedSalePointId = useSelector((state) => state.salePoint.selectedSalePointId);

  const buttonProps = {} as { expand: 'full' | 'block' };

  if (selectedSalePointId) {
    buttonProps.expand = 'block';
  }

  const onChangeSalePointClick = () => {
    dispatch(openModal(true));
  };

  return (
    <IonButton
      {...buttonProps}
      className={classNames(textClasses.title, btnClasses.primaryBtn, {
        'ion-padding-horizontal': !selectedSalePointId,
      })}
      onClick={onChangeSalePointClick}
    >
      {salePoint ? t('Change location') : t('Set location')}
    </IonButton>
  );
};

export default SetLocationButton;
