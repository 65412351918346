import { IonContent, IonModal } from '@ionic/react';

import ComboCampaignEditComponents from 'src/components/campaign/ComboCampaignEditComponents';
import ComboCampaignEditComponentsFooter from 'src/components/campaign/ComboCampaignEditComponentsFooter';
import CloseButton from 'src/components/CloseButton';
import { useDispatch, useSelector } from 'src/store';
import { openComboCampaignProductComponents } from 'src/slices/campaign';
import classes from './ComboCampaignModals.module.scss';

const ComboCampaignProductComponentsModal: React.FC = () => {
  const dispatch = useDispatch();
  const { isComboCampaignProductComponentsOpened } = useSelector((state) => state.campaign);

  const onCloseBtnClick = async () => {
    dispatch(openComboCampaignProductComponents(false));
  };

  const onModalDismiss = () => {
    dispatch(openComboCampaignProductComponents(false));
  };

  return (
    <IonModal
      isOpen={isComboCampaignProductComponentsOpened}
      onDidDismiss={onModalDismiss}
      cssClass={classes.editModal}
    >
      <IonContent>
        <div className={classes.content}>
          <ComboCampaignEditComponents />
        </div>
        <div className={classes.closeBtnContainer}>
          <CloseButton onClose={onCloseBtnClick} />
        </div>
      </IonContent>
      <ComboCampaignEditComponentsFooter />
    </IonModal>
  );
};

export default ComboCampaignProductComponentsModal;
