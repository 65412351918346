import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { get } from 'lodash';
import { IonText } from '@ionic/react';
import classNames from 'classnames';

import API from 'src/api';
import DetailsLayout from 'src/components/layout/DetailsLayout';
import LoadingOverlay from 'src/components/LoadingOverlay';
import usePageLanguageReload from 'src/hooks/usePageLanguageReload';
import { useSelector } from 'src/store';
import { StaticPageTemplateJoinInfoDto } from 'src/types/generated';
import classes from 'src/pages/StaticPage.module.scss';
import textClasses from 'src/styles/text.module.scss';
import layoutClasses from 'src/styles/layout.module.scss';
import 'src/styles/ql-editor.scss';

interface StaticPageProps extends RouteComponentProps {}

const StaticPage: React.FC<StaticPageProps> = ({ history, location, match }) => {
  const { t } = useTranslation();
  const { staticPages } = useSelector((state) => state.system);
  const [page, setPage] = useState<StaticPageTemplateJoinInfoDto>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const routerProps = { history, location, match };
  const currentPageSlug = get(match, 'params.name');
  const layoutTitle = loading ? '' : page?.name || t('Not found');

  useEffect(() => {
    const setup = async () => {
      setLoading(true);
      try {
        let staticPage = staticPages ? staticPages.find((sp) => sp.slug === currentPageSlug) : null;
        if (!staticPage) {
          staticPage = await API.staticPages.getPageBySlug(currentPageSlug);
        }
        setPage(staticPage);
      } finally {
        setLoading(false);
      }
    };
    setup();
  }, [staticPages, currentPageSlug]);

  const onLanguageChange = async () => {
    setLoading(true);
    try {
      const newStaticPage = await API.staticPages.getPageBySlug(currentPageSlug);
      setPage(newStaticPage);
    } finally {
      setLoading(false);
    }
  };

  usePageLanguageReload({ routerProps, callback: onLanguageChange });

  return (
    <DetailsLayout
      meta={{
        title: t('-SEO Static page', { pageName: page?.name }),
        description: t('-SEO Static page description', { pageName: page?.name }),
      }}
      {...routerProps}
      headerTitle={layoutTitle}
      contentHeaderTitle={layoutTitle}
    >
      {loading && <LoadingOverlay backdrop spinnerColor="primary" />}
      {!loading && page && (
        <div
          className={classNames(layoutClasses.container, classes.content, 'ql-editor')}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: page.content }}
        />
      )}
      {!loading && !page && (
        <IonText className={classNames(textClasses.titleBold, 'ion-text-center')}>
          {t('Static page with this URL was not found.')}
        </IonText>
      )}
    </DetailsLayout>
  );
};

export default StaticPage;
