import { IonButton, IonIcon, IonText } from '@ionic/react';
import { refreshCircleOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useDispatch } from 'src/store';
import { useAvailableBonusPointsGetter, useBreakpoint, useCartBonusPointsGetter } from 'src/hooks';
import { syncBonusPoints } from 'src/slices/auth';
import classes from './BonusPoints.module.scss';
import textClasses from 'src/styles/text.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';
import logo from 'src/assets/img/pizzakiosk-logo.svg';

interface BonusPointsProps {
  showCartBonusPoints?: boolean;
}

const BonusPoints: React.FC<BonusPointsProps> = ({ showCartBonusPoints }) => {
  const { t } = useTranslation();
  const { isMobileView, isDesktopView } = useBreakpoint();
  const dispatch = useDispatch();
  const { costBonusPoints, accumulatedBonusPoints } = useCartBonusPointsGetter();
  const availableBonusPoints = useAvailableBonusPointsGetter();
  const cartBonusPoints = accumulatedBonusPoints - costBonusPoints;

  const ionButtonProps = {} as { routerLink?: string; href?: string; target?: string };

  const onBonusPointsRefreshClick = () => {
    dispatch(syncBonusPoints());
  };

  if (isMobileView) {
    ionButtonProps.routerLink = '/tabs/contact/templikaart';
  }

  if (isDesktopView) {
    ionButtonProps.href = '/tabs/contact/templikaart';
    ionButtonProps.target = '_blank';
  }

  return (
    <div className={classes.bonusPoints}>
      <div className={classes.bonusPointsTexts}>
        <div className={classes.bonusPointsHeader}>
          <h4 className={classNames(textClasses.titleBold)}>{t('Tokens')}</h4>
          <IonButton
            color="white"
            shape="round"
            className={classes.bonusPointsRefreshBtn}
            onClick={onBonusPointsRefreshClick}
          >
            <IonIcon icon={refreshCircleOutline} className={classes.bonusPointsRefreshBtnIcon} color="primary" />
          </IonButton>
        </div>
        <IonText color="primary">
          <IonButton
            color="primary"
            fill="outline"
            shape="round"
            className={classNames(btnClasses.linkBtn, textClasses.textS, classes.bonusPointsInfoLinkBtn)}
            {...ionButtonProps}
          >
            {t('Why collect?')}
          </IonButton>
        </IonText>
      </div>
      <div className={classes.bonusPointsValues}>
        <IonText className={textClasses.titleBold}>{availableBonusPoints || 0}</IonText>
        {showCartBonusPoints && Boolean(cartBonusPoints) && (
          <IonText className={textClasses.titleBold} color="primary">
            {cartBonusPoints > 0 && '+'}
            {cartBonusPoints}
          </IonText>
        )}
        <IonIcon src={logo} className={classes.logo} color="primary" />
      </div>
    </div>
  );
};

export default BonusPoints;
