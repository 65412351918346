import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IonButton, IonText } from '@ionic/react';

import { useBreakpoint } from 'src/hooks';
import { useDispatch } from 'src/store';
import { openModal as openAuthModal } from 'src/slices/auth';
import { openModal as openAddVipModal } from 'src/slices/vip';
import classes from './AddVipGuestFallback.module.scss';
import textClasses from 'src/styles/text.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';

const AddVipGuestFallback = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobileView, isDesktopView } = useBreakpoint();

  const ionButtonProps = {} as { routerLink?: string };

  if (isMobileView) {
    ionButtonProps.routerLink = '/tabs/profile/auth';
  }

  const onSignInBtnClick = () => {
    dispatch(openAddVipModal(false));
    if (isDesktopView) {
      dispatch(openAuthModal(true));
    }
  };

  return (
    <div className={classes.content}>
      <h2 className={classNames(textClasses.headerLBold, 'ion-text-center', classes.header)}>{t('VIP')}</h2>
      <div className={classes.descriptions}>
        <p className={classNames(textClasses.title, 'ion-text-center', classes.description)}>
          {t('You must be signed-in in order to use the VIP feature')}.
        </p>

        <p className={classNames(textClasses.textSBold, 'ion-text-center', classes.description)}>
          <IonText color="medium">
            {t('VIP (Very Important Pizza) helps you save your favorite orders and re-order them with a few clicks')}.
          </IonText>
        </p>
      </div>
      <IonButton className={btnClasses.primaryBtn} onClick={onSignInBtnClick} expand="block" {...ionButtonProps}>
        <IonText className={textClasses.title}>{t('Sign in')}</IonText>
      </IonButton>
    </div>
  );
};

export default AddVipGuestFallback;
