import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import toast from 'react-hot-toast';

class API {
  protected instance: AxiosInstance;

  constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  public configureInterceptors() {
    this.instance.interceptors.request.use(
      (request: AxiosRequestConfig) => this.onRequest(request),
      (error: AxiosError) => this.onRequestResponseError(error)
    );

    this.instance.interceptors.response.use(undefined, (error: AxiosError) => this.onRequestResponseError(error));
  }

  public async onRequest(request: AxiosRequestConfig) {
    return request;
  }

  public onRequestResponseError(error: AxiosError) {
    const { response } = error;
    const errorMessage = response?.data?.error || response?.data || error?.message || 'Network request failed';
    toast.error(errorMessage);
    console.error(errorMessage);
    return Promise.reject(error);
  }
}

export default API;
