import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useSelector } from 'src/store';

interface HookProps {
  routerProps: RouteComponentProps;
  callback?: (languageId: number) => void | Promise<void>;
}

const usePageLanguageReload = ({ routerProps, callback }: HookProps) => {
  const { location, match } = routerProps;
  const selectedLanguageId = useSelector((state) => state.system.selectedLanguageId);
  const [prevLanguageId, setPrevLanguageId] = useState<number>(selectedLanguageId);

  useEffect(() => {
    if (prevLanguageId && prevLanguageId !== selectedLanguageId && location.pathname === match.url) {
      if (callback) callback(selectedLanguageId);
      setPrevLanguageId(selectedLanguageId);
    }
    if (!prevLanguageId && selectedLanguageId) setPrevLanguageId(selectedLanguageId);
  }, [selectedLanguageId, prevLanguageId, location.pathname, match.url]);
};

export default usePageLanguageReload;
