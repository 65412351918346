import { useTranslation } from 'react-i18next';
import { IonCheckbox, IonLabel, IonText } from '@ionic/react';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'src/store';
import { setBonusedQty } from 'src/slices/cart';
import { formatNumber } from 'src/utils';
import classes from './BonusPointsCheckBox.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';
import textClasses from 'src/styles/text.module.scss';

interface BonusPointsCheckBoxProps {
  cartItemId: string;
  bonusedQuantityToAssign: number;
  bonusPointsCost: number;
  disabled: boolean;
}

const BonusPointsCheckBox: React.FC<BonusPointsCheckBoxProps> = ({
  cartItemId,
  bonusedQuantityToAssign,
  bonusPointsCost,
  disabled,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart);

  const assignedBonusedQuantity = (cartItems || []).find((cartItem) => cartItem.uniqId === cartItemId)?.bonusedQuantity;
  const disableAssigning = !assignedBonusedQuantity && disabled;
  const checked = Boolean(assignedBonusedQuantity);

  const onCheckBoxClick = () => {
    if (disableAssigning) return;

    if (assignedBonusedQuantity) {
      dispatch(setBonusedQty({ cartItemId, bonusedQuantity: null }));
      return;
    }
    dispatch(setBonusedQty({ cartItemId, bonusedQuantity: bonusedQuantityToAssign }));
  };

  return (
    // eslint-disable-next-line
    <div
      className={classNames(classes.container, btnClasses.checkboxContainer, {
        [classes.containerDisabled]: disableAssigning,
      })}
      onClick={onCheckBoxClick}
    >
      <IonCheckbox checked={checked} disabled={disableAssigning} />
      <IonLabel className={classNames({ [classes.disabledText]: disableAssigning })}>
        <span>{t('Use tokens')}</span>
        <IonText className={textClasses.textMBold} color="primary">
          {' '}
          {checked && (
            <span>
              (-{formatNumber((assignedBonusedQuantity || bonusedQuantityToAssign || 1) * bonusPointsCost, 0)})
            </span>
          )}
        </IonText>
      </IonLabel>
    </div>
  );
};

export default BonusPointsCheckBox;
