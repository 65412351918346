import { combineReducers } from '@reduxjs/toolkit';
import { reducer as salePointReducer } from 'src/slices/salePoint';
import { reducer as productReducer } from 'src/slices/product';
import { reducer as systemReducer } from 'src/slices/system';
import { reducer as cartReducer } from 'src/slices/cart';
import { reducer as orderReducer } from 'src/slices/order';
import { reducer as authReducer } from 'src/slices/auth';
import { reducer as vipReducer } from 'src/slices/vip';
import { reducer as paymentReducer } from 'src/slices/payment';
import { reducer as campaignReducer } from 'src/slices/campaign';

const rootReducer = combineReducers({
  salePoint: salePointReducer,
  product: productReducer,
  system: systemReducer,
  cart: cartReducer,
  order: orderReducer,
  auth: authReducer,
  vip: vipReducer,
  payment: paymentReducer,
  campaign: campaignReducer,
});

export default rootReducer;
