import { IonContent, IonModal } from '@ionic/react';
import { Capacitor } from '@capacitor/core';
import classNames from 'classnames';

import IdentityGateway from 'src/components/auth/IdentityGateway';
import IdentityGatewayIframe from 'src/components/auth/IdentityGatewayIframe';
import RegistrationForm from 'src/components/auth/RegistrationForm';
import CloseButton from 'src/components/CloseButton';
import { useDispatch, useSelector } from 'src/store';
import { openModal } from 'src/slices/auth';
import classes from './AuthModal.module.scss';

const AuthModal: React.FC = () => {
  const dispatch = useDispatch();
  const { isAuthModalOpened, gatewayStatusData, isAuthenticated } = useSelector((state) => state.auth);
  const showIdentityGateway = !gatewayStatusData && !isAuthenticated;
  const showRegistrationForm = gatewayStatusData && !isAuthenticated;
  const isNative = Capacitor.isNativePlatform();

  const onCloseBtnClick = () => {
    dispatch(openModal(false));
  };

  return (
    <IonModal
      isOpen={isAuthModalOpened}
      backdropDismiss={false}
      cssClass={classNames(classes.modal, { [classes.registrationFormModal]: showRegistrationForm })}
    >
      <IonContent>
        <div className={classes.closeBtnContainer}>
          <CloseButton onClose={onCloseBtnClick} />
        </div>
        {!isNative && showIdentityGateway && <IdentityGateway />}
        {isNative && showIdentityGateway && <IdentityGatewayIframe />}
        {showRegistrationForm && <RegistrationForm />}
      </IonContent>
    </IonModal>
  );
};

export default AuthModal;
