import { useTranslation } from 'react-i18next';
import { IonText, IonButton, IonIcon } from '@ionic/react';
import classNames from 'classnames';

import QtyPicker from 'src/components/QtyPicker';
import { useDispatch, useSelector } from 'src/store';
import { PRODUCT_MAX_COUNT } from 'src/constants';
import { addProductToCart, setProductQty } from 'src/slices/product';
import classes from './ProductQuickAdd.module.scss';
import textClasses from 'src/styles/text.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';
import cartIcon from 'src/assets/img/cart.svg';
import toast from 'react-hot-toast';

interface ProductQuickAddProps {
  productId: number;
}

const ProductQuickAdd: React.FC<ProductQuickAddProps> = ({ productId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { productQuantities } = useSelector((state) => state.product);
  const { selectedSalePointId } = useSelector((state) => state.salePoint);
  const productQty = productQuantities[productId] || 1;

  const onAddQty = () => {
    if (productQty >= PRODUCT_MAX_COUNT) return;
    dispatch(setProductQty({ [productId]: productQty + 1 }));
  };

  const onRemoveQty = () => {
    if (productQty <= 1) return;
    dispatch(setProductQty({ [productId]: productQty - 1 }));
  };

  const onAddToCartBtnClick = () => {
    if (!selectedSalePointId) {
      toast.error(t('Please select sale point'));
      return;
    }
    dispatch(addProductToCart(productId));
  };
  return (
    <div className={classes.addToCartContainer}>
      <QtyPicker qty={productQty} onAddQty={onAddQty} onRemoveQty={onRemoveQty} buttonSize="30px" />
      <IonButton className={classNames(btnClasses.primaryBtn, classes.addToCartBtn)} onClick={onAddToCartBtnClick}>
        <IonIcon src={cartIcon} slot="start" className={classes.cartIcon} />
        <IonText slot="end" className={classNames(textClasses.textM, classes.addToCartText)}>
          {t('Add')}
        </IonText>
      </IonButton>
    </div>
  );
};

export default ProductQuickAdd;
