import { ReactNode } from 'react';
import { IonText } from '@ionic/react';
import classNames from 'classnames';

import OrderItemPartials, { IOrderItemPartial } from './OrderItemPartials';
import { formatPrice } from 'src/utils';
import classes from './OrderItem.module.scss';
import textClasses from 'src/styles/text.module.scss';
import { useTranslation } from 'react-i18next';

interface OrderSummaryItemProps {
  productName: string;
  variantsText: string;
  partials: IOrderItemPartial[];
  freePartials?: IOrderItemPartial[];
  price?: number;
  originalPrice?: number;
  quantity?: number;
  rightHeader?: ReactNode;
  children?: ReactNode;
}

const OrderItem: React.FC<OrderSummaryItemProps> = ({
  productName,
  variantsText,
  partials,
  freePartials,
  price,
  originalPrice,
  quantity,
  rightHeader,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <li className={classes.item}>
      <div className={classes.itemContent}>
        <div className={classes.itemHeader}>
          <h3 className={classNames(textClasses.headerMBold, classes.itemHeaderText)}>
            {quantity ? `${quantity}x ` : ''}
            {productName}
          </h3>
          <div className={classes.rightHeader}>
            {Number.isFinite(price) && price !== null && (
              <IonText color="primary" className={classes.itemHeaderPrice}>
                {Boolean(originalPrice) && (
                  <span className={classNames(textClasses.textL, classes.originalPriceText)}>
                    {formatPrice(originalPrice)}
                  </span>
                )}
                <span className={textClasses.textL}>{formatPrice(price)}</span>
              </IonText>
            )}
            {rightHeader}
          </div>
        </div>

        <IonText className={textClasses.textSBold} color="medium">
          {variantsText}
        </IonText>

        {!!freePartials?.length && <OrderItemPartials partials={freePartials} title={t('Chosen free extras')} isFree />}
        <OrderItemPartials partials={partials} title={freePartials?.length > 0 ? t('Chosen paid extras') : null} />

        {children}
      </div>
    </li>
  );
};

export default OrderItem;
