import { useTranslation } from 'react-i18next';
import { IonList, IonItem, IonText } from '@ionic/react';
import classNames from 'classnames';

import classes from './StaticPageList.module.scss';
import textClasses from 'src/styles/text.module.scss';
import { useOrderedStaticPagesGetter } from 'src/hooks';

const StaticPageList: React.FC = () => {
  const { t } = useTranslation();
  const orderedStaticPages = useOrderedStaticPagesGetter();

  return (
    <>
      <h3 className={classNames(textClasses.titleBold, classes.title)}>{t('Other info')}</h3>
      <IonList className={classes.list}>
        {orderedStaticPages.map((staticPage) => (
          <IonItem
            key={staticPage.id}
            routerLink={`/tabs/contact/${staticPage.slug}`}
            className={classes.listItem}
            lines="none"
            button
            detail
          >
            <IonText
              slot="start"
              className={classNames(textClasses.textL, classes.listItemText)}
              color={staticPage.slug === 'allergens' ? 'primary' : 'dark'}
            >
              {staticPage.name}
            </IonText>
          </IonItem>
        ))}
      </IonList>
    </>
  );
};

export default StaticPageList;
