import {
  PaymentBanksDto,
  PaymentLinkReqDto,
  PaymentLinkResDto,
  PaymentValidateReqDto,
  PaymentValidateResDto,
} from 'src/types/generated';
import BaseApiModule from 'src/api/base';

class PaymentApi extends BaseApiModule {
  protected baseRoute = '/payment';

  public getBanks() {
    return this._get<PaymentBanksDto[]>(`${this.baseRoute}/banks`);
  }

  public getLink(model: PaymentLinkReqDto) {
    return this._post<PaymentLinkResDto>(`${this.baseRoute}/link`, model);
  }

  public validate(model: PaymentValidateReqDto) {
    return this._post<PaymentValidateResDto>(`${this.baseRoute}/validate`, model);
  }
}

export default PaymentApi;
