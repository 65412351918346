import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';

const AppUrlListener: React.FC<any> = () => {
  const history = useHistory();
  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      try {
        const urlObject = new URL(event.url);
        if (urlObject.pathname.match(/\/tabs\/orders\/\d+\/thank-you/gi)) {
          history.replace(`${urlObject.pathname}${urlObject.search}`);
        }
      } catch (error) {
        console.error('Failed to parse event url on appUrlOpen, error: ', error);
      }
    });
  }, []);

  return null;
};

export default AppUrlListener;
