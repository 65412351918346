import { RouteComponentProps } from 'react-router-dom';
import { IonButton, IonIcon, IonText } from '@ionic/react';
import { starOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import SalePointPickerButton from 'src/components/SalePointPickerButton';
import SignInButton from 'src/components/SignInButton';
import LanguagePicker from 'src/components/LanguagePicker';
import { useDispatch, useSelector } from 'src/store';
import { selectDefaultCategory } from 'src/slices/product';
import { useCartPriceGetter } from 'src/hooks';
import { formatPrice } from 'src/utils';
import { DESKTOP_VIEW } from 'src/constants';
import btnClasses from 'src/styles/buttons.module.scss';
import textClasses from 'src/styles/text.module.scss';
import classes from './DesktopTopNavigation.module.scss';
import logo from 'src/assets/img/pizzakiosk-logo.svg';
import pizzaIcon from 'src/assets/img/pizza-slice.svg';
import locationIcon from 'src/assets/img/location.svg';
import cartIcon from 'src/assets/img/cart.svg';
import profileIcon from 'src/assets/img/profile.svg';

interface LayoutHeaderProps extends RouteComponentProps {}

const DesktopTopNavigation: React.FC<LayoutHeaderProps> = ({ location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedSalePointId, salePoints } = useSelector((state) => state.salePoint);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { total: cartPrice } = useCartPriceGetter();
  const disabledCartTab = !selectedSalePointId;

  const onLogoClick = () => {
    dispatch(selectDefaultCategory());
  };

  return (
    <>
      <div className={classNames(DESKTOP_VIEW, classes.header)}>
        <div className={classes.headerLeft} data-testid="header-left-btn-wrapper">
          <IonButton
            color="white"
            shape="round"
            routerLink="/tabs/menu"
            routerDirection="root"
            onClick={onLogoClick}
            className={classes.logoBtn}
          >
            <IonIcon src={logo} className={classes.logo} color="primary" />
          </IonButton>
          {salePoints?.length > 0 && <SalePointPickerButton />}
        </div>
        <div className={classes.headerRight}>
          <IonButton
            color={location.pathname === '/tabs/menu' ? 'primary' : 'white'}
            fill={location.pathname === '/tabs/menu' ? 'outline' : 'solid'}
            shape="round"
            routerLink="/tabs/menu"
            routerDirection="root"
            className={classNames(btnClasses.outlineBtn, classes.btn)}
          >
            <IonIcon src={pizzaIcon} className={classNames(classes.btnIcon)} />
            <IonText className={classNames(textClasses.textMBold, classes.btnText)}>{t('Order')}</IonText>
          </IonButton>
          {isAuthenticated && (
            <IonButton
              color={location.pathname === '/tabs/profile/vip' ? 'primary' : 'white'}
              fill={location.pathname === '/tabs/profile/vip' ? 'outline' : 'solid'}
              shape="round"
              routerLink="/tabs/profile/vip"
              routerDirection="root"
              className={classNames(btnClasses.outlineBtn, classes.btn)}
            >
              <IonIcon src={starOutline} className={classNames(classes.btnIcon)} />
              <IonText className={classNames(textClasses.textMBold, classes.btnText)}>{t('VIP')}</IonText>
            </IonButton>
          )}
          <IonButton
            color={location.pathname === '/tabs/contact' ? 'primary' : 'white'}
            fill={location.pathname === '/tabs/contact' ? 'outline' : 'solid'}
            shape="round"
            routerLink="/tabs/contact"
            routerDirection="root"
            className={classNames(btnClasses.outlineBtn, classes.btn)}
          >
            <IonIcon src={locationIcon} className={classNames(classes.btnIcon)} />
            <IonText className={classNames(textClasses.textMBold, classes.btnText)}>{t('Contact')}</IonText>
          </IonButton>
          {isAuthenticated && (
            <IonButton
              color={location.pathname === '/tabs/profile' ? 'primary' : 'white'}
              fill={location.pathname === '/tabs/profile' ? 'outline' : 'solid'}
              shape="round"
              routerLink="/tabs/profile"
              routerDirection="root"
              className={classNames(btnClasses.outlineBtn, classes.btn)}
            >
              <IonIcon src={profileIcon} className={classNames(classes.btnIcon)} />
              <IonText className={classNames(textClasses.textMBold, classes.btnText)}>{t('Profile')}</IonText>
            </IonButton>
          )}
          {!isAuthenticated && <SignInButton />}
          <IonButton
            color={location.pathname === '/tabs/cart' ? 'primary' : 'white'}
            fill={location.pathname === '/tabs/cart' ? 'outline' : 'solid'}
            shape="round"
            routerLink="/tabs/cart"
            routerDirection="root"
            className={classNames(btnClasses.outlineBtn, classes.btn)}
            disabled={disabledCartTab}
          >
            <IonIcon src={cartIcon} className={classNames(classes.btnIcon, classes.cartIcon)} />
            <IonText className={classNames(textClasses.textMBold, classes.btnText)}>
              {t('Cart')}
              <IonText color="medium"> {formatPrice(cartPrice)}</IonText>
            </IonText>
          </IonButton>
          <div className={classes.btn}>
            <LanguagePicker />
          </div>
        </div>
      </div>
    </>
  );
};

export default DesktopTopNavigation;
