import { IonItem, IonList, useIonViewDidEnter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';

import MainLayout from 'src/components/layout/MainLayout';
import LoginNotification from 'src/components/cart/LoginNotification';
import Header from 'src/components/profile/Header';
import NavigationTabs from 'src/components/profile/NavigationTabs';
import MyData from 'src/components/profile/MyData';
import LanguagePicker from 'src/components/LanguagePicker';
import LogOutButton from 'src/components/profile/LogOutButton';
import LoadingOverlay from 'src/components/LoadingOverlay';
import { useDispatch, useSelector } from 'src/store';
import classes from './ProfileTab.module.scss';
import textClasses from 'src/styles/text.module.scss';
import layoutClasses from 'src/styles/layout.module.scss';
import { useBreakpoint } from 'src/hooks';
import { syncBonusPoints } from 'src/slices/auth';

interface ProfileTabProps extends RouteComponentProps {}
interface DesktopViewProps extends RouteComponentProps {}

const DesktopView: React.FC<DesktopViewProps> = ({ history, location, match }) => {
  const routerProps = { history, location, match };

  return (
    <>
      <NavigationTabs {...routerProps} />
      <div className={classNames(classes.profileSection, layoutClasses.containerMedium)}>
        <MyData />
      </div>
    </>
  );
};

const MobileView: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <IonList className={classes.profileList}>
        <IonItem routerLink="/tabs/profile/my-data" className={classes.profileListItem} lines="none" button detail>
          <span slot="start" className={classNames(textClasses.textL, classes.profileListItemText)}>
            {t('My data')}
          </span>
        </IonItem>
        <IonItem routerLink="/tabs/profile/orders" className={classes.profileListItem} lines="none" button detail>
          <span slot="start" className={classNames(textClasses.textL, classes.profileListItemText)}>
            {t('Orders')}
          </span>
        </IonItem>
        <IonItem routerLink="/tabs/profile/vip" className={classes.profileListItem} lines="none" button detail>
          <span slot="start" className={classNames(textClasses.textL, classes.profileListItemText)}>
            {t('VIP')}
          </span>
        </IonItem>
      </IonList>
      <LogOutButton />
    </>
  );
};

const ProfileTab: React.FC<ProfileTabProps> = ({ history, location, match }) => {
  const routerProps = { history, location, match };
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { isMobileView, isDesktopView } = useBreakpoint();
  const { t } = useTranslation();
  const { profile: isLoading } = useSelector((state) => state.auth.loading);

  const dispatch = useDispatch();

  useIonViewDidEnter(() => {
    dispatch(syncBonusPoints());
  }, []);

  return (
    <MainLayout
      centerContent={!isAuthenticated}
      meta={{
        title: t('-SEO Profile page title'),
        description: t('-SEO Profile page description'),
      }}
      {...routerProps}
    >
      {!isAuthenticated && (
        <>
          {isMobileView && (
            <div className={classes.stickLanguagePicker}>
              <LanguagePicker />
            </div>
          )}
          <LoginNotification />
        </>
      )}
      {isAuthenticated && (
        <>
          <Header />
          {isLoading && <LoadingOverlay backdrop spinnerColor="primary" />}
          {isDesktopView && <DesktopView {...routerProps} />}
          {isMobileView && <MobileView />}
        </>
      )}
    </MainLayout>
  );
};

export default ProfileTab;
