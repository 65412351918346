import { useEffect, useCallback, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { getGatewayStatus } from 'src/slices/auth';
import { useDispatch, useSelector } from 'src/store';
import { DEFAULT_LOCALE } from 'src/constants';
import LoadingOverlay from '../LoadingOverlay';

interface IdentityGatewayIframeProps {
  onCompleted?: () => void | Promise<void>;
}

const IdentityGatewayIframe: React.FC<IdentityGatewayIframeProps> = ({ onCompleted }) => {
  const [iframeIsLoaded, setIframeIsLoaded] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { languages, selectedLanguageId } = useSelector((state) => state.system);
  const locale = languages?.find((lang) => lang.id === selectedLanguageId)?.isoName || DEFAULT_LOCALE;
  const platform = Capacitor.getPlatform();
  const deviceOrigin = platform === 'ios' ? 'capacitor://localhost' : 'http://localhost';

  const onMessage = useCallback(async (e: MessageEvent) => {
    if (e.origin === process.env.REACT_APP_PIZZAKIOSK_API_URI) {
      const returnToken = e.data;
      const isRegistered = (await dispatch(getGatewayStatus(returnToken))) as unknown as boolean;
      if (isRegistered && onCompleted) {
        onCompleted();
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', onMessage, false);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  const urlParams = new URLSearchParams({
    locale,
    deviceOrigin,
    color: 'cc0000',
  });
  return (
    <>
      {!iframeIsLoaded && <LoadingOverlay backdrop spinnerColor="primary" />}
      <iframe
        title="identity-gateway"
        src={`${process.env.REACT_APP_PIZZAKIOSK_API_URI}/identity-gateway?${urlParams.toString()}`}
        width="100%"
        height="100vh"
        style={{ width: '100%', height: '100vh' }}
        onLoad={() => setIframeIsLoaded(true)}
      />
    </>
  );
};

export default IdentityGatewayIframe;
