import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ProfilePageLayout from 'src/components/layout/ProfilePageLayout';
import VIP from 'src/components/profile/VIP';
import { useDispatch } from 'src/store';
import { getVips } from 'src/slices/vip';
import { usePageLanguageReload, usePageSalePointReload } from 'src/hooks';
import { useIonViewDidEnter } from '@ionic/react';
import { syncBonusPoints } from 'src/slices/auth';

interface ProfileVIPPageProps extends RouteComponentProps {}

const ProfileVIPPage: React.FC<ProfileVIPPageProps> = ({ history, location, match }) => {
  const { t } = useTranslation();
  const routerProps = { history, location, match };
  const dispatch = useDispatch();

  const onLanguageChange = () => {
    dispatch(getVips());
  };

  useIonViewDidEnter(() => {
    dispatch(syncBonusPoints());
  }, []);

  const onSalePointChange = async () => {
    await dispatch(getVips());
  };

  usePageLanguageReload({ routerProps, callback: onLanguageChange });
  usePageSalePointReload({ routerProps, callback: onSalePointChange });

  return (
    <ProfilePageLayout
      title={t('Very Important Pizza')}
      meta={{
        title: t('-SEO Profile VIPs page'),
        description: t('-SEO Profile VIPs page description'),
      }}
      {...routerProps}
    >
      <VIP {...routerProps} />
    </ProfilePageLayout>
  );
};

export default ProfileVIPPage;
