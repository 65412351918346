import { IonText } from '@ionic/react';

import CampaignOrderItem, { IPaidProduct, IFreeProduct, IFreePartial } from 'src/components/CampaignOrderItem';
import QtyPicker from 'src/components/QtyPicker';
import { useDispatch } from 'src/store';
import { setCartItemQty } from 'src/slices/cart';
import { useCartPriceGetter } from 'src/hooks';
import { formatPrice } from 'src/utils';
import { PRODUCT_MAX_COUNT } from 'src/constants';
import classes from './OrderSummary.module.scss';
import textClasses from 'src/styles/text.module.scss';

interface CampaignOrderSummaryItemProps {
  cartItemId: string;
  qty: number;
  onRemove: (id: string) => void | Promise<void>;
  orderItemProps: {
    campaignName: string;
    paidProducts: IPaidProduct[];
    freeProducts?: IFreeProduct[];
    freePartials?: IFreePartial[];
  };
}

const CampaignOrderSummaryItem: React.FC<CampaignOrderSummaryItemProps> = ({
  cartItemId,
  qty,
  onRemove,
  orderItemProps,
}) => {
  const dispatch = useDispatch();
  const cartItemPrices = useCartPriceGetter();

  const onAddQty = () => {
    if (qty >= PRODUCT_MAX_COUNT) return;
    dispatch(setCartItemQty({ cartItemId, qty: qty + 1 }));
  };

  const onRemoveQty = () => {
    if (qty <= 1) {
      onRemove(cartItemId);
      return;
    }
    dispatch(setCartItemQty({ cartItemId, qty: qty - 1 }));
  };

  return (
    <CampaignOrderItem {...orderItemProps} quantity={qty}>
      <div className={classes.itemQty}>
        <QtyPicker
          qty={qty}
          onAddQty={onAddQty}
          onRemoveQty={onRemoveQty}
          minDisabled={false}
          buttonSize="38px"
          textMargin="0 10px"
        />
        <IonText color="primary" className={textClasses.textL}>
          {formatPrice(cartItemPrices[cartItemId]?.total)}
        </IonText>
      </div>
    </CampaignOrderItem>
  );
};

export default CampaignOrderSummaryItem;
