import { IonButton, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';

import classes from './NavigationTabs.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';

interface NavigationTabProps extends RouteComponentProps {}

const NavigationTabs: React.FC<NavigationTabProps> = ({ location }) => {
  const { t } = useTranslation();

  return (
    <div className="ion-text-center">
      <IonButton
        color={
          location.pathname === '/tabs/profile' || location.pathname === '/tabs/profile/my-data' ? 'primary' : 'light'
        }
        className={classNames(btnClasses.chipBtn, classes.tabBtn)}
        routerLink="/tabs/profile/my-data"
        routerDirection="root"
      >
        <IonText className={classes.tabBtnText}>{t('My data')}</IonText>
      </IonButton>
      <IonButton
        color={location.pathname === '/tabs/profile/orders' ? 'primary' : 'light'}
        className={classNames(btnClasses.chipBtn, classes.tabBtn)}
        routerLink="/tabs/profile/orders"
        routerDirection="root"
      >
        <IonText className={classes.tabBtnText}>{t('Orders history')}</IonText>
      </IonButton>
      <IonButton
        color={location.pathname === '/tabs/profile/vip' ? 'primary' : 'light'}
        className={classNames(btnClasses.chipBtn, classes.tabBtn)}
        routerLink="/tabs/profile/vip"
        routerDirection="root"
      >
        <IonText className={classes.tabBtnText}>{t('VIP')}</IonText>
      </IonButton>
    </div>
  );
};

export default NavigationTabs;
