import { useTranslation } from 'react-i18next';
import { IonButton, IonIcon, IonImg, useIonViewWillEnter } from '@ionic/react';
import { Capacitor } from '@capacitor/core';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';

import MainLayout from 'src/components/layout/MainLayout';
import DetailsLayout from 'src/components/layout/DetailsLayout';
import ContactWorkingDaysLoader from 'src/components/loaders/ContactWorkingDaysLoader';
import WorkingDays from 'src/components/contact/WorkingDays';
import AvailableTimes from 'src/components/contact/AvailableTimes';
import ContactButtons from 'src/components/contact/ContactButtons';
import StaticPageList from 'src/components/contact/StaticPageList';
import HeaderText from 'src/components/contact/HeaderText';
import SetLocationButton from 'src/components/contact/SetLocationButton';
import NoSalePointPlaceholder from 'src/components/contact/NoSalePointPlaceholder';
import { useDispatch, useSelector } from 'src/store';
import { getStaticPages } from 'src/slices/system';
import { getSalePointOpeningHours } from 'src/slices/salePoint';
import { useBreakpoint, usePageLanguageReload } from 'src/hooks';
import appStoreBadge from 'src/assets/img/app-store-badge.svg';
import googlePlayBadge from 'src/assets/img/google-play-badge.svg';
import logo from 'src/assets/img/pizzakiosk-logo.svg';
import classes from 'src/pages/ContactTab.module.scss';
import layoutClasses from 'src/styles/layout.module.scss';
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from 'src/constants';

interface ContactTabProps extends RouteComponentProps {}

const ContactTab: React.FC<ContactTabProps> = ({ history, location, match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobileView, isDesktopView } = useBreakpoint();
  const { selectedSalePointId, salePoints, loading, salePointWorkingDays, salePointAvailableTimes } = useSelector(
    (state) => state.salePoint
  );
  const staticPages = useSelector((state) => state.system.staticPages);
  const salePoint = salePoints.find((s) => s.id === selectedSalePointId);
  const isLoading = loading.workingDays;
  const routerProps = { history, location, match };
  const meta = {
    title: t('-SEO Contact page'),
    description: t('-SEO Contact page description'),
  };

  useIonViewWillEnter(() => {
    if (isMobileView && !staticPages) {
      dispatch(getStaticPages());
    }
    if (!salePointWorkingDays || !salePointAvailableTimes) {
      dispatch(getSalePointOpeningHours());
    }
  }, [isMobileView, staticPages, salePointWorkingDays]);

  const onLanguageChange = () => {
    if (isMobileView) {
      dispatch(getStaticPages());
    }
  };

  usePageLanguageReload({ routerProps, callback: onLanguageChange });

  return (
    <>
      {isDesktopView && (
        <DetailsLayout meta={meta} contentHeaderTitle={t('Contact')} {...routerProps}>
          <div className={classNames(layoutClasses.container, classes.container)}>
            {!salePoint && (
              <>
                <NoSalePointPlaceholder />
                <SetLocationButton salePoint={salePoint} />
              </>
            )}
            {salePoint && (
              <div className={classes.columns}>
                <div className={classes.column}>
                  <HeaderText salePoint={salePoint} />
                  <ContactButtons />
                  <SetLocationButton salePoint={salePoint} />
                </div>
                <div className={classes.column}>
                  {isLoading && <ContactWorkingDaysLoader />}
                  {!isLoading && (
                    <>
                      <AvailableTimes />
                      <WorkingDays />
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </DetailsLayout>
      )}
      {isMobileView && (
        <MainLayout meta={meta} {...routerProps}>
          <div className={classes.content}>
            <IonIcon src={logo} className={classes.logo} color="primary" />
            {salePoint && (
              <>
                <HeaderText salePoint={salePoint} />
                {isLoading && <ContactWorkingDaysLoader />}
                {!isLoading && (
                  <>
                    <AvailableTimes />
                    <WorkingDays />
                  </>
                )}
                <ContactButtons />
              </>
            )}
            {!salePoint && <NoSalePointPlaceholder />}
            <SetLocationButton salePoint={salePoint} />
            {!Capacitor.isNativePlatform() && (
              <div className={classes.storeBadges}>
                <IonButton
                  color="transparent"
                  className={classes.storeBadgeBtn}
                  href={GOOGLE_PLAY_LINK}
                  target="_blank"
                >
                  <IonImg src={googlePlayBadge} className={classes.storeBadgeImg} />
                </IonButton>
                <IonButton color="transparent" className={classes.storeBadgeBtn} href={APP_STORE_LINK} target="_blank">
                  <IonImg src={appStoreBadge} className={classes.storeBadgeImg} />
                </IonButton>
              </div>
            )}
            <StaticPageList />
          </div>
        </MainLayout>
      )}
    </>
  );
};

export default ContactTab;
