import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonButton } from '@ionic/react';
import { Capacitor } from '@capacitor/core';
import { chain } from 'lodash';
import classNames from 'classnames';

import Scrollbar from 'src/components/Scrollbar';
import { useDispatch, useSelector } from 'src/store';
import { selectCategory } from 'src/slices/product';
import { isMobileUserAgent } from 'src/utils';
import classes from 'src/components/menu/Categories.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';
import layoutClasses from 'src/styles/layout.module.scss';

interface CategoriesProps {
  sticky: boolean;
}

const Categories = React.forwardRef<HTMLDivElement, CategoriesProps>(
  ({ sticky }, ref: React.MutableRefObject<HTMLDivElement>) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.product.categories) || [];
    const selectedCategoryId = useSelector((state) => state.product.selectedCategoryId);
    const [isNativePlatform, setIsNativePlatform] = useState<boolean>(false);
    const selectedCategory = categories.find((c) => c.id === selectedCategoryId);
    const rootCategorySelected = !selectedCategory?.parentId;
    const rootCategories = chain(categories)
      .filter((c) => c.parentId === null && !c.isComponent && !c.isAddOnSale)
      .orderBy(['parentOrderNumber'], ['asc'])
      .value();
    const subCategories = chain(categories)
      .filter((c) => c.parentId && c.parentId === (selectedCategory?.parentId || selectedCategoryId))
      .orderBy(['childOrderNumber'], ['asc'])
      .value();

    useEffect(() => {
      const isNative = Capacitor.isNativePlatform();
      setIsNativePlatform(isNative);
    }, [Capacitor, setIsNativePlatform]);

    const onCategoryClick = (id: number) => {
      dispatch(selectCategory(id));
    };
    return (
      <>
        <div
          ref={ref}
          className={classNames(classes.categories, {
            [classes.stickyWrapper]: sticky,
            [classes.scrollBarOffset]: sticky && !isNativePlatform,
          })}
        >
          <div className={classNames({ [layoutClasses.container]: sticky, [classes.stickyContainer]: sticky })}>
            <Scrollbar>
              <div
                className={classNames(classes.categoryButtons, {
                  [classes.hideScrollBar]: isNativePlatform,
                  [classes.categoryButtonsMargin]: isMobileUserAgent(),
                })}
              >
                {rootCategories.map((c) => (
                  <IonButton
                    key={c.id}
                    onClick={() => onCategoryClick(c.id)}
                    color={selectedCategoryId === c.id || selectedCategory?.parentId === c.id ? 'primary' : 'light'}
                    className={classNames(btnClasses.chipBtn, classes.categoryBtn)}
                  >
                    {c.name}
                  </IonButton>
                ))}
              </div>
            </Scrollbar>
            <Scrollbar>
              <div
                className={classNames(classes.categoryButtons, {
                  [classes.hideScrollBar]: isNativePlatform,
                  [classes.categoryButtonsMargin]: isMobileUserAgent(),
                })}
              >
                <IonButton
                  onClick={() => onCategoryClick(selectedCategory?.parentId || selectedCategory?.id)}
                  color={rootCategorySelected ? 'primary' : 'light'}
                  className={classNames(btnClasses.chipBtn, classes.categoryBtn)}
                >
                  {t('All')}
                </IonButton>
                {subCategories.map((c) => (
                  <IonButton
                    key={c.id}
                    onClick={() => onCategoryClick(c.id)}
                    color={selectedCategoryId === c.id ? 'primary' : 'light'}
                    className={classNames(btnClasses.chipBtn, classes.categoryBtn)}
                  >
                    {c.name}
                  </IonButton>
                ))}
              </div>
            </Scrollbar>
          </div>
        </div>
        {sticky && <div style={{ height: `${ref.current?.offsetHeight}px` }} />}
      </>
    );
  }
);

export default Categories;
