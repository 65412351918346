import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import type { ScrollBarProps as PerfectScrollbarProps } from 'react-perfect-scrollbar';

import { isMobileUserAgent } from 'src/utils';

interface ScrollbarProps extends PerfectScrollbarProps {}

const Scrollbar = forwardRef<HTMLDivElement, ScrollbarProps>((props, ref) => {
  const { children, ...other } = props;

  if (isMobileUserAgent()) {
    return (
      <div ref={ref} style={{ overflowX: 'auto' }}>
        {children}
      </div>
    );
  }

  return (
    <PerfectScrollbar
      // @ts-ignore
      ref={ref}
      style={{ paddingBottom: '15px' }}
      {...other}
    >
      {children}
    </PerfectScrollbar>
  );
});

Scrollbar.propTypes = {
  children: PropTypes.node,
};

export default Scrollbar;
