import { IonModal } from '@ionic/react';

import SalePointPicker from 'src/components/SalePointPicker';
import { useDispatch, useSelector } from 'src/store';
import { openModal } from 'src/slices/salePoint';

const SalePointPickerModal: React.FC = () => {
  const dispatch = useDispatch();
  const { isSalePointPickerOpened } = useSelector((state) => state.salePoint);

  return (
    <IonModal isOpen={isSalePointPickerOpened} onDidDismiss={() => dispatch(openModal(false))}>
      <SalePointPicker />
    </IonModal>
  );
};

export default SalePointPickerModal;
