import { IonText } from '@ionic/react';
import classNames from 'classnames';

import { SalePointDto } from 'src/types/generated';
import classes from './HeaderText.module.scss';
import textClasses from 'src/styles/text.module.scss';

interface HeaderTextProps {
  salePoint: SalePointDto;
}

const HeaderText: React.FC<HeaderTextProps> = ({ salePoint }) => {
  return (
    <IonText className="ion-text-center">
      <h1 className={classNames(textClasses.headerLBold, classes.headerText)}>{salePoint.name}</h1>
      <p className={classNames(textClasses.title, classes.headerText)}>{salePoint.address}</p>
    </IonText>
  );
};

export default HeaderText;
