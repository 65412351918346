import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Capacitor } from '@capacitor/core';
import { add, isBefore } from 'date-fns';

import API from 'src/api';
import type { AppThunk } from 'src/store';
import { getStorageItem, removeStorageItem, setStorageItem } from 'src/utils/storage';
import { selectLanguage, setLoading as setSystemLoading } from 'src/slices/system';
import {
  AuthPublicGatewayCreateRequestDto,
  AuthPublicGatewayStatusDto,
  AuthPublicRegisterRequestDto,
  ClientDto,
  ClientUpdateMeDto,
} from 'src/types/generated';
import { shouldRestoreCartFromStorage } from 'src/utils/order';

interface LoadingState {
  gateway?: boolean;
  register?: boolean;
  profile?: boolean;
}

type GatewayStatusDataDto = Omit<AuthPublicGatewayStatusDto, 'isRegistered'>;

interface GatewaySessionPayload {
  gatewaySessionToken: string;
  gatewayExpiresAt: string;
  returnToken?: string;
}

interface RegisterPayload {
  email: string | null;
  phoneNumber: string;
  isSubscribedToNewsletter: boolean;
}

interface UpdateMePayload extends ClientUpdateMeDto {}

interface AuthState {
  isAuthModalOpened: boolean;
  gatewaySessionToken: string;
  returnToken: string;
  gatewayExpiresAt: string;
  gatewayStatusData: GatewayStatusDataDto;
  user: ClientDto;
  isAuthenticated: boolean;
  loading: LoadingState;
}

const initialState: AuthState = {
  isAuthModalOpened: false,
  gatewaySessionToken: null,
  returnToken: null,
  gatewayExpiresAt: null,
  gatewayStatusData: null,
  user: null,
  isAuthenticated: false,
  loading: {
    gateway: false,
    register: false,
    profile: false,
  },
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading(state: AuthState, action: PayloadAction<LoadingState>): void {
      state.loading = { ...state.loading, ...action.payload };
    },
    openModal(state: AuthState, action: PayloadAction<boolean>): void {
      state.isAuthModalOpened = action.payload;
    },
    setGatewaySession(state: AuthState, action: PayloadAction<GatewaySessionPayload>): void {
      const { gatewaySessionToken, gatewayExpiresAt, returnToken } = action.payload;
      state.gatewaySessionToken = gatewaySessionToken;
      state.gatewayExpiresAt = gatewayExpiresAt;
      if (returnToken) state.returnToken = returnToken;
    },
    setUser(state: AuthState, action: PayloadAction<ClientDto>): void {
      state.user = action.payload;
      state.isAuthenticated = Boolean(action.payload);
    },
    setReturnToken(state: AuthState, action: PayloadAction<string>): void {
      state.returnToken = action.payload;
    },
    setGatewayStatusData(state: AuthState, action: PayloadAction<GatewayStatusDataDto>): void {
      state.gatewayStatusData = action.payload;
    },
  },
});

export const { reducer } = slice;

export const openModal =
  (modalState: boolean): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.openModal(modalState));
  };

export const gatewayCreate =
  (payload?: AuthPublicGatewayCreateRequestDto): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setLoading({ gateway: true }));
    try {
      const { gatewaySessionToken, gatewayExpiresAt } = getState().auth;
      if (!gatewaySessionToken || isBefore(new Date(gatewayExpiresAt), add(new Date(), { minutes: 5 }))) {
        const response = await API.auth.gatewayCreate(payload);
        const { sessionToken, expiresIn } = response || {};
        if (sessionToken) {
          const expiresAt = add(new Date(), { seconds: expiresIn || 0 }).toISOString();
          dispatch(
            slice.actions.setGatewaySession({
              gatewaySessionToken: sessionToken,
              gatewayExpiresAt: expiresAt,
            })
          );
        }
      }
    } finally {
      dispatch(slice.actions.setLoading({ gateway: false }));
    }
  };

export const getGatewayStatus =
  (returnToken: string): AppThunk =>
  async (dispatch, getState): Promise<void | boolean> => {
    const { gatewaySessionToken } = getState().auth;
    if (!gatewaySessionToken && !Capacitor.isNativePlatform()) return;

    dispatch(slice.actions.setLoading({ gateway: true }));
    let userRegistered = false;
    try {
      const { isRegistered, ...gatewayData } = await API.auth.gatewayStatus(returnToken);
      userRegistered = isRegistered;
      dispatch(slice.actions.setReturnToken(returnToken));
      if (isRegistered) {
        const { token, user } = await API.auth.login({ token: returnToken });
        if (token && user) {
          await setStorageItem('accessToken', token);
          dispatch(slice.actions.setUser(user));
          if (user.languageId) {
            dispatch(selectLanguage({ languageId: user.languageId, saveSelection: false }));
          }
          dispatch(slice.actions.openModal(false));
        }
      }
      dispatch(slice.actions.setGatewayStatusData(gatewayData));
    } finally {
      dispatch(slice.actions.setLoading({ gateway: false }));
    }
    return userRegistered;
  };

export const initAuth =
  (): AppThunk =>
  async (dispatch): Promise<ClientDto> => {
    const accessToken = await getStorageItem('accessToken');
    const storageSalePointId = await getStorageItem('selectedSalePointId');
    const restoreCartFromStorage = await shouldRestoreCartFromStorage();

    let user;
    if (accessToken) {
      try {
        const userData = await API.auth.me();
        if (userData?.id) {
          user = {
            ...userData,
            favoriteSalePointId: restoreCartFromStorage
              ? storageSalePointId || userData.favoriteSalePointId
              : userData.favoriteSalePointId,
          };
          dispatch(slice.actions.setUser(user));
        }
      } catch (error) {
        await removeStorageItem('accessToken');
      }
    }
    return user;
  };

export const register =
  (payload: RegisterPayload): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setLoading({ register: true }));
    try {
      const { returnToken } = getState().auth;
      const { selectedSalePointId } = getState().salePoint;
      const { selectedLanguageId } = getState().system;
      const registerRequestData: AuthPublicRegisterRequestDto = {
        token: returnToken,
        phoneNumber: payload.phoneNumber,
        email: payload.email,
        isSubscribedToNewsletter: payload.isSubscribedToNewsletter,
        favoriteSalePointId: selectedSalePointId,
        languageId: selectedLanguageId,
      };
      const { token, user } = await API.auth.register(registerRequestData);
      if (token && user) {
        await setStorageItem('accessToken', token);
        dispatch(slice.actions.setUser(user));
        dispatch(slice.actions.openModal(false));
      }
    } finally {
      dispatch(slice.actions.setLoading({ register: false }));
    }
  };

export const updateMe =
  (data: UpdateMePayload): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setLoading({ profile: true }));
    try {
      const userData = await API.auth.updateMe(data);
      dispatch(slice.actions.setUser(userData));
    } finally {
      dispatch(slice.actions.setLoading({ profile: false }));
    }
  };

export const logOut =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { selectedSalePointId } = getState().salePoint;
    await removeStorageItem('accessToken');
    await setStorageItem('selectedSalePointId', String(selectedSalePointId));
    dispatch(slice.actions.setUser(null));
    dispatch(
      slice.actions.setGatewaySession({
        gatewaySessionToken: null,
        gatewayExpiresAt: null,
        returnToken: null,
      })
    );
    dispatch(slice.actions.setGatewayStatusData(null));
  };

export const syncBonusPoints =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { isAuthenticated } = getState().auth;
    if (!isAuthenticated) return;

    dispatch(setSystemLoading({ app: true }));
    try {
      const user = await API.auth.syncBonusPoints();
      dispatch(slice.actions.setUser(user));
    } finally {
      dispatch(setSystemLoading({ app: false }));
    }
  };

export default slice;
