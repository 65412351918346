import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  add,
  getISODay,
  isAfter,
  isBefore,
  isSameDay,
  isToday,
  max as getMaxDate,
  min as getMinDate,
  set,
} from 'date-fns';
import toast from 'react-hot-toast';

import { getStorageItem, removeStorageItem, setStorageItem } from 'src/utils/storage';
import type { AppThunk } from 'src/store';
import { CampaignCartItemDto, ProductCartItemDto } from '../types/generated';
import { getNextWorkingDayData, getTimeDate } from 'src/utils/salepoint';
import { toSQLDate } from 'src/utils/time';
import { shouldRestoreCartFromStorage } from 'src/utils/order';
import { trackBeginCheckout, trackItemAddedToCart, trackItemRemovedFromCart } from 'src/utils/analytics';
import API from 'src/api';
import { i18next } from 'src/hooks/useDataLoading';

export type CartItem = Partial<ProductCartItemDto & CampaignCartItemDto>;

export interface CartState {
  deadline: string;
  minDeadline: string;
  maxDeadline: string;
  cartItems: CartItem[];
  showClosedSalePointWarning: boolean;
  showSalePointNotAvailableWarning: boolean;
  showTooLongCheckoutTimeWarning: boolean;
  productsCheckoutTime: number;
  wasPaymentInvokedFromCart: boolean;
}

interface InitCartPayload {
  cartItems: CartItem[];
  deadlineData: {
    deadline: string;
    minDeadline: string;
    maxDeadline: string;
  };
}

interface CartQtyPayload {
  cartItemId: string;
  qty: number;
}

interface DeadlineValidationActionPayload {
  newDate?: Date;
  showWarningIfClosed?: boolean;
  extraCheckoutTime?: number; // Can be negative, in case if we want to reduce checkout time during some validation (for example during validation right before order creation)
}

interface DeadlineValidationReducerPayload {
  min?: string;
  max?: string;
  newDeadline?: string;
  showClosedSalePointWarning?: boolean;
  showSalePointNotAvailableWarning?: boolean;
  showTooLongCheckoutTimeWarning?: boolean;
}

interface SetBonusedQtyPayload {
  cartItemId: string;
  bonusedQuantity: number;
}

const deadlineFormat = (date: Date) => {
  return date.toISOString();
};

const initialState: CartState = {
  deadline: null,
  minDeadline: null,
  maxDeadline: null,
  cartItems: [],
  showClosedSalePointWarning: false,
  showSalePointNotAvailableWarning: false,
  showTooLongCheckoutTimeWarning: false,
  productsCheckoutTime: 0,
  // Will regulate if payment page should be displayed, or user should be redirected to cart.
  // Only from /tabs/cart payment page can be opened at the very first time, after from any link.
  // Workaround for ionic-tabs navigation stack cache.
  // After making first order and being redirected from payment page -> thank-you -> main page -
  // cart tab opening should open cart view, not payment.
  wasPaymentInvokedFromCart: false,
};

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    initCart(state: CartState, action: PayloadAction<InitCartPayload>): void {
      const { cartItems, deadlineData } = action.payload;
      state.cartItems = cartItems || [];
      state.minDeadline = deadlineData?.minDeadline || null;
      state.maxDeadline = deadlineData?.maxDeadline || null;
      state.deadline = deadlineData?.deadline || null;
    },
    addCartItem(state: CartState, action: PayloadAction<CartItem>): void {
      const newCartItem = action.payload;
      if (newCartItem.isCampaign) {
        state.cartItems = state.cartItems.concat(newCartItem);
        return;
      }

      const checkPartialsEquality = (partial, otherPartials) => {
        const otherPartial = otherPartials.find((x) => x.partialProductId === partial.partialProductId);
        return partial.quantity === otherPartial?.quantity;
      };
      let areIdenticalCartItems = false;
      state.cartItems = state.cartItems.map((cartItem) => {
        let { quantity } = cartItem;
        if (!areIdenticalCartItems && newCartItem.productId === cartItem.productId) {
          const newPartials = newCartItem?.partials || [];
          const existingPartials = cartItem?.partials || [];
          const newVariantIds = newCartItem?.variantIds || [];
          const existingVariantIds = cartItem?.variantIds || [];
          const areNewPartialsEqual = newPartials.every((p) => checkPartialsEquality(p, existingPartials));
          const areExistingPartialsEqual = existingPartials.every((p) => checkPartialsEquality(p, newPartials));
          const areVariantsEqual = newVariantIds.every((newVariantId) => existingVariantIds.includes(newVariantId));
          if (areNewPartialsEqual && areExistingPartialsEqual && areVariantsEqual) {
            quantity += newCartItem.quantity;
            areIdenticalCartItems = true;
          }
        }
        return { ...cartItem, quantity };
      });
      if (!areIdenticalCartItems) {
        state.cartItems = state.cartItems.concat(newCartItem);
      }
    },
    removeCartItem(state: CartState, action: PayloadAction<string>): void {
      state.cartItems = state.cartItems.filter((cartItem) => cartItem.uniqId !== action.payload);
      if (state.cartItems.length <= 0) {
        state.minDeadline = deadlineFormat(new Date());
        state.maxDeadline = deadlineFormat(new Date());
        state.deadline = deadlineFormat(new Date());
        state.showClosedSalePointWarning = false;
      }
    },
    setDeadline(state: CartState, action: PayloadAction<string>): void {
      state.deadline = action.payload;
    },
    setProductsCheckoutTime(state: CartState, action: PayloadAction<number>): void {
      state.productsCheckoutTime = action.payload;
    },
    setCartItemQty(state: CartState, action: PayloadAction<CartQtyPayload>): void {
      const { cartItemId, qty } = action.payload;
      state.cartItems = state.cartItems.map((cartItem) => {
        return cartItem.uniqId === cartItemId ? { ...cartItem, quantity: qty } : cartItem;
      });
    },
    setDeadlines(state: CartState, action: PayloadAction<DeadlineValidationReducerPayload>): void {
      const {
        min = null,
        max = null,
        newDeadline = null,
        showClosedSalePointWarning = false,
        showSalePointNotAvailableWarning = false,
        showTooLongCheckoutTimeWarning = false,
      } = action.payload;
      state.minDeadline = min;
      state.maxDeadline = max;
      state.deadline = newDeadline || min;
      state.showClosedSalePointWarning = Boolean(showClosedSalePointWarning);
      state.showSalePointNotAvailableWarning = Boolean(showSalePointNotAvailableWarning);
      state.showTooLongCheckoutTimeWarning = Boolean(showTooLongCheckoutTimeWarning);
    },
    setShowClosedSalePointWarning(state: CartState, action: PayloadAction<boolean>): void {
      state.showClosedSalePointWarning = action.payload;
    },
    setShowSalePointNotAvailableWarning(state: CartState, action: PayloadAction<boolean>): void {
      state.showSalePointNotAvailableWarning = action.payload;
    },
    setShowTooLongCheckoutTimeWarning(state: CartState, action: PayloadAction<boolean>): void {
      state.showTooLongCheckoutTimeWarning = action.payload;
    },
    resetCart(state: CartState): void {
      state.cartItems = [];
      state.minDeadline = null;
      state.maxDeadline = null;
      state.deadline = null;
      state.showClosedSalePointWarning = false;
      state.showSalePointNotAvailableWarning = false;
    },
    setBonusedQty(state: CartState, action: PayloadAction<SetBonusedQtyPayload>): void {
      const { bonusedQuantity, cartItemId } = action.payload;
      state.cartItems = state.cartItems.map((cartItem) => {
        if (cartItem.uniqId === cartItemId) {
          return { ...cartItem, bonusedQuantity };
        }

        return cartItem;
      });
    },
    setPaymentInvoked(state: CartState, action: PayloadAction<boolean>): void {
      state.wasPaymentInvokedFromCart = action.payload;
    },
    removeAllBonusedQty(state: CartState): void {
      state.cartItems = state.cartItems.map((cartItem) => {
        return { ...cartItem, bonusedQuantity: 0 };
      });
    },
    updateCart(state: CartState, action: PayloadAction<CartItem[]>): void {
      state.cartItems = action.payload;
    },
  },
});

export const { reducer } = slice;

export const clearCartStorage =
  (): AppThunk =>
  async (_dispatch, getState): Promise<void> => {
    const { isAuthenticated } = getState().auth;
    await removeStorageItem('cartItems');
    await removeStorageItem('deadlineData');
    await removeStorageItem('cartItemsExpired');
    if (isAuthenticated) {
      await removeStorageItem('selectedSalePointId');
    }
  };

export const storeDeadlines =
  (): AppThunk =>
  async (_dispatch, getState): Promise<void> => {
    const cartState = getState().cart;
    await setStorageItem(
      'deadlineData',
      JSON.stringify({
        minDeadline: cartState.minDeadline,
        maxDeadline: cartState.maxDeadline,
        deadline: cartState.deadline,
      })
    );
    await setStorageItem('cartItemsExpired', add(new Date(), { hours: 12 }).toISOString());
  };

export const initCart =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const storedCartItems = await getStorageItem('cartItems');
    const deadlineData = await getStorageItem('deadlineData');
    const restoreCartFromStorage = await shouldRestoreCartFromStorage();
    if (restoreCartFromStorage) {
      let parsedCartItems: CartItem[] = [];
      let verifiedCartItems = parsedCartItems;
      let unverifiedCartItems = [];

      try {
        parsedCartItems = JSON.parse(storedCartItems);
      } catch (error) {
        console.error('Failed to parse cart items from local storage, error: ', error);
        return;
      }

      try {
        const cartVerificationResponse = await API.cart.verifyCartItems(parsedCartItems);
        verifiedCartItems = cartVerificationResponse.verifiedCartItems;
        unverifiedCartItems = cartVerificationResponse.unverifiedCartItems;
      } catch (error) {
        console.error('Error during cart items verification request: ', error);
        dispatch(clearCartStorage());
        return;
      }

      if (unverifiedCartItems.length) {
        toast.error(
          unverifiedCartItems.length === 1
            ? i18next.t('1 cart item was removed')
            : i18next.t('{{cartItemsCount}} cart items were removed', { cartItemsCount: unverifiedCartItems.length })
        );
      }

      await setStorageItem('cartItems', JSON.stringify(verifiedCartItems));
      await setStorageItem('cartItemsExpired', add(new Date(), { hours: 12 }).toISOString());

      if (verifiedCartItems.length) {
        dispatch(slice.actions.initCart({ cartItems: verifiedCartItems, deadlineData: JSON.parse(deadlineData) }));
      } else {
        dispatch(clearCartStorage());
      }
    } else {
      dispatch(clearCartStorage());
    }
  };

export const addCartItem =
  (cartItem: CartItem): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    await dispatch(slice.actions.addCartItem(cartItem));
    const { cart, campaign, product } = getState();
    trackItemAddedToCart(cartItem, product.products, campaign.campaigns);
    await setStorageItem('cartItems', JSON.stringify(cart.cartItems));
    await setStorageItem('cartItemsExpired', add(new Date(), { hours: 12 }).toISOString());
  };

export const removeCartItem =
  (cartItemId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { cart, product, campaign } = getState();
    trackItemRemovedFromCart(
      cart.cartItems.find((item) => item.uniqId === cartItemId),
      product.products,
      campaign.campaigns
    );
    await dispatch(slice.actions.removeCartItem(cartItemId));
    const { cartItems } = getState().cart;
    if (cartItems.length > 0) {
      await setStorageItem('cartItems', JSON.stringify(cartItems));
      await setStorageItem('cartItemsExpired', add(new Date(), { hours: 12 }).toISOString());
    } else {
      dispatch(clearCartStorage());
    }
  };

export const setDeadline =
  (deadline: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await dispatch(slice.actions.setDeadline(deadline));
    await dispatch(storeDeadlines());
  };

export const setCartItemQty =
  (payload: CartQtyPayload): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    await dispatch(slice.actions.setCartItemQty(payload));
    const { cartItems } = getState().cart;
    await setStorageItem('cartItems', JSON.stringify(cartItems));
    await setStorageItem('cartItemsExpired', add(new Date(), { hours: 12 }).toISOString());
  };

export const setShowClosedSalePointWarning =
  (payload: boolean): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setShowClosedSalePointWarning(payload));
  };

export const setShowSalePointNotAvailableWarning =
  (payload: boolean): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setShowSalePointNotAvailableWarning(payload));
  };

export const setShowTooLongCheckoutTimeWarning =
  (payload: boolean): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setShowTooLongCheckoutTimeWarning(payload));
  };

export const resetCart =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    await dispatch(clearCartStorage());
    await dispatch(slice.actions.resetCart());
  };

export const updateCart =
  (payload: CartItem[]): AppThunk =>
  async (dispatch): Promise<void> => {
    await setStorageItem('cartItems', JSON.stringify(payload));
    await setStorageItem('cartItemsExpired', add(new Date(), { hours: 12 }).toISOString());
    await dispatch(slice.actions.updateCart(payload));
  };

export const setBonusedQty =
  (payload: SetBonusedQtyPayload): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setBonusedQty(payload));
    const { cartItems } = getState().cart;
    await setStorageItem('cartItems', JSON.stringify(cartItems));
    await setStorageItem('cartItemsExpired', add(new Date(), { hours: 12 }).toISOString());
  };

export const setProductsCheckoutTime =
  (payload: number): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setProductsCheckoutTime(payload));
  };

export const setPaymentInvoked =
  (payload: boolean, total: number = 0): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { cart, product, campaign } = getState();
    if (payload) trackBeginCheckout(cart, product.products, campaign.campaigns, total);
    dispatch(slice.actions.setPaymentInvoked(payload));
  };

export const getDeadline =
  (): AppThunk =>
  async (dispatch, getState): Promise<string> => {
    const { deadline } = getState().cart;
    return deadline;
  };

export const adjustBonusedQuantityProducts =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { cartItems } = getState().cart;
    const { variants } = getState().product;
    const { bonusPoints, bookedBonusPoints } = getState().auth.user;

    const bonusPointsFinal = bonusPoints - bookedBonusPoints;

    const costBonusPoints = cartItems
      .filter((cartItem) => cartItem.isProduct && cartItem.bonusedQuantity)
      .map((cartItem) => {
        const { variantIds, bonusedQuantity } = cartItem;
        const variantData = variants.find((variant) => variantIds.includes(variant.id) && variant.bonusPointsCost);
        return (variantData?.bonusPointsCost || 0) * bonusedQuantity;
      })
      .reduce((acc, bonusPointsCost) => acc + bonusPointsCost, 0);

    if (costBonusPoints > bonusPointsFinal) {
      dispatch(slice.actions.removeAllBonusedQty());
    }
  };

export const validateDeadline =
  (payload?: DeadlineValidationActionPayload): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { newDate, showWarningIfClosed, extraCheckoutTime } = payload || {};
    const { salePointWorkingDays, salePointAvailableTimes, salePoints, selectedSalePointId } = getState().salePoint;
    const { deadline, productsCheckoutTime } = getState().cart;
    const salePoint = (salePoints || []).find((s) => s.id === selectedSalePointId);

    if (!salePointWorkingDays?.length || !salePoints?.length || !selectedSalePointId) {
      await dispatch(slice.actions.setDeadlines({}));
      dispatch(clearCartStorage());
      return;
    }

    if (!salePoint?.enabled) {
      await dispatch(slice.actions.setDeadlines({ showSalePointNotAvailableWarning: true }));
      return;
    }

    const { minCheckoutTime, isResetCheckoutTime, defaultMinCheckoutTime } = salePoints.find(
      (s) => s.id === selectedSalePointId
    );
    const salePointCheckoutTime = Math.abs(
      (isResetCheckoutTime && defaultMinCheckoutTime && (newDate || deadline) && !isToday(newDate || new Date(deadline))
        ? defaultMinCheckoutTime
        : minCheckoutTime) + (extraCheckoutTime || 0)
    );
    const checkoutTime = salePointCheckoutTime + (productsCheckoutTime || 0); // Total time (in minutes) which will take for sale point to complete an order.
    let currentDate = new Date();
    currentDate = set(currentDate, {
      minutes: Math.ceil(currentDate.getMinutes() / 5) * 5,
      seconds: 0,
      milliseconds: 0,
    });
    const currentDateWithMinCheckoutTime = add(currentDate, { minutes: salePointCheckoutTime });
    const currentDateWithCheckoutTime = add(currentDate, { minutes: checkoutTime });
    const pickupDate =
      newDate ||
      (deadline && isAfter(new Date(deadline), currentDateWithCheckoutTime)
        ? new Date(deadline)
        : currentDateWithCheckoutTime);
    const availableTimesData = (salePointAvailableTimes || []).find((at) => at.date === toSQLDate(pickupDate));
    const workingDaysData = salePointWorkingDays.find((wd) => wd.isoWeekDate === getISODay(pickupDate));
    const { start, end } = availableTimesData || workingDaysData || { start: null, end: null };
    const isNonWorkingDay = !start || !end;
    const isLateForOrderingToday =
      end && isToday(pickupDate) && isAfter(currentDateWithMinCheckoutTime, getTimeDate(pickupDate, end));

    if (isNonWorkingDay || isLateForOrderingToday) {
      const { nextStartWorkingDate, nextEndWorkingDate } = getNextWorkingDayData(
        salePointWorkingDays,
        salePointAvailableTimes,
        pickupDate
      );

      if (!nextStartWorkingDate || !nextEndWorkingDate) {
        await dispatch(slice.actions.setDeadlines({ showSalePointNotAvailableWarning: true }));
        await dispatch(clearCartStorage());

        return;
      }
      const nextStartWorkingDateWithCheckoutTime = add(nextStartWorkingDate, { minutes: checkoutTime });
      const isNextMinDateAfterNextEndDate = isAfter(nextStartWorkingDateWithCheckoutTime, nextEndWorkingDate);
      await dispatch(
        slice.actions.setDeadlines({
          min: deadlineFormat(add(nextStartWorkingDate, { minutes: checkoutTime })),
          max: deadlineFormat(nextEndWorkingDate),
          newDeadline: deadlineFormat(getMinDate([nextStartWorkingDateWithCheckoutTime, nextEndWorkingDate])),
          showClosedSalePointWarning: showWarningIfClosed && !isNextMinDateAfterNextEndDate,
          showTooLongCheckoutTimeWarning: isNextMinDateAfterNextEndDate,
        })
      );
      await dispatch(storeDeadlines());

      return;
    }

    const salePointStartDate = getTimeDate(pickupDate, start); // Date time when sale point opens up
    const salePointEndDate = getTimeDate(pickupDate, end); // Date time when sale point closes down
    const salePointStartDateWithCheckoutTime = add(salePointStartDate, { minutes: checkoutTime });
    const startDateWithCheckoutTime = getMaxDate([currentDateWithCheckoutTime, salePointStartDateWithCheckoutTime]); // First available date time for receiving current order.

    const todayAvailableTimesData = (salePointAvailableTimes || []).find((at) => at.date === toSQLDate(currentDate));
    const todayWorkingDaysData = salePointWorkingDays.find((wd) => wd.isoWeekDate === getISODay(currentDate));
    const { start: todayStart } = todayAvailableTimesData || todayWorkingDaysData || { start: null, end: null };
    const salePointTodayStartDate = todayStart ? getTimeDate(currentDate, todayStart) : salePointStartDate; // Today date time when sale point opens up

    const isMinDateAfterEndDate = isAfter(startDateWithCheckoutTime, salePointEndDate);
    const isLateEvening = isAfter(pickupDate, salePointEndDate);
    const isEarlyMorning = isBefore(pickupDate, salePointStartDateWithCheckoutTime);

    if (isMinDateAfterEndDate) {
      await dispatch(
        slice.actions.setDeadlines({
          min: deadlineFormat(startDateWithCheckoutTime),
          max: deadlineFormat(salePointEndDate),
          newDeadline: deadlineFormat(salePointEndDate),
          showTooLongCheckoutTimeWarning: true,
        })
      );
      await dispatch(storeDeadlines());

      return;
    }

    if (isEarlyMorning || isLateEvening) {
      await dispatch(
        slice.actions.setDeadlines({
          min: deadlineFormat(startDateWithCheckoutTime),
          max: deadlineFormat(salePointEndDate),
          showClosedSalePointWarning: showWarningIfClosed && isBefore(currentDate, salePointTodayStartDate),
        })
      );
      await dispatch(storeDeadlines());

      return;
    }

    const anotherDayWasPicked = !isSameDay(pickupDate, new Date(deadline));
    await dispatch(
      slice.actions.setDeadlines({
        min: deadlineFormat(startDateWithCheckoutTime),
        max: deadlineFormat(salePointEndDate),
        newDeadline: anotherDayWasPicked
          ? deadlineFormat(startDateWithCheckoutTime)
          : deadlineFormat(getMaxDate([pickupDate, startDateWithCheckoutTime])),
        showClosedSalePointWarning: showWarningIfClosed && isBefore(currentDate, salePointTodayStartDate),
      })
    );
    await dispatch(storeDeadlines());
  };

export default slice;
