import OrderItem from 'src/components/OrderItem';
import QtyPicker from 'src/components/QtyPicker';
import BonusPointsCheckBox from 'src/components/cart/BonusPointsCheckBox';
import { IOrderItemPartial } from 'src/components/OrderItemPartials';
import { useCartPriceGetter, useCartBonusPointsGetter, useAvailableBonusPointsGetter } from 'src/hooks';
import { useDispatch, useSelector } from 'src/store';
import { setCartItemQty } from 'src/slices/cart';
import { PRODUCT_MAX_COUNT } from 'src/constants';
import classes from './OrderSummary.module.scss';

interface ProductOrderSummaryItemProps {
  cartItemId: string;
  qty: number;
  bonusPointsCost?: number;
  bonusPointsAccumulator?: number;
  onRemove: (id: string) => void | Promise<void>;
  orderItemProps: {
    productName: string;
    variantsText: string;
    partials: IOrderItemPartial[];
    freePartials?: IOrderItemPartial[];
  };
}

const ProductOrderSummaryItem: React.FC<ProductOrderSummaryItemProps> = ({
  cartItemId,
  qty,
  bonusPointsCost,
  onRemove,
  orderItemProps,
}) => {
  const dispatch = useDispatch();
  const cartItemPrices = useCartPriceGetter();
  const { costBonusPoints } = useCartBonusPointsGetter();
  const { cartItems } = useSelector((state) => state.cart);
  const userAvailableBonusPoints = useAvailableBonusPointsGetter();

  const assignedBonusedQuantity = (cartItems || []).find((cartItem) => cartItem.uniqId === cartItemId)?.bonusedQuantity;
  const bonusPointsChecked = Boolean(assignedBonusedQuantity);
  const availableBonusPoints = userAvailableBonusPoints - costBonusPoints;
  const showBonusPointsCheckBox = bonusPointsCost && userAvailableBonusPoints >= bonusPointsCost;
  const disableBonusPointsCheckBox = bonusPointsCost && availableBonusPoints < bonusPointsCost;
  const bonusedQuantityToAssign = Math.min(qty, Math.floor(availableBonusPoints / bonusPointsCost));

  const onAddQty = () => {
    if (qty >= PRODUCT_MAX_COUNT) return;
    dispatch(setCartItemQty({ cartItemId, qty: qty + 1 }));
  };

  const onRemoveQty = () => {
    if (qty <= 1) {
      onRemove(cartItemId);
      return;
    }
    dispatch(setCartItemQty({ cartItemId, qty: qty - 1 }));
  };

  const orderItemRightHeader = showBonusPointsCheckBox ? (
    <BonusPointsCheckBox
      cartItemId={cartItemId}
      bonusedQuantityToAssign={bonusedQuantityToAssign}
      bonusPointsCost={bonusPointsCost}
      disabled={disableBonusPointsCheckBox}
    />
  ) : null;

  return (
    <OrderItem
      {...orderItemProps}
      price={cartItemPrices[cartItemId]?.total}
      originalPrice={cartItemPrices[cartItemId]?.originalTotal}
    >
      <div className={classes.itemQty}>
        <QtyPicker
          qty={qty}
          onAddQty={onAddQty}
          onRemoveQty={onRemoveQty}
          minDisabled={bonusPointsChecked}
          maxDisabled={bonusPointsChecked}
          buttonSize="38px"
          textMargin="0 10px"
        />
        {orderItemRightHeader}
      </div>
    </OrderItem>
  );
};

export default ProductOrderSummaryItem;
