import { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IonButton, IonIcon, IonText, useIonViewWillEnter } from '@ionic/react';
import { timeOutline } from 'ionicons/icons';
import { get } from 'lodash';
import classNames from 'classnames';

import API from 'src/api';
import DetailsLayout from 'src/components/layout/DetailsLayout';
import Image from 'src/components/Image';
import LoadingOverlay from 'src/components/LoadingOverlay';
import { useDispatch, useSelector } from 'src/store';
import { formatDateTime } from 'src/utils/time';
import { OrderJoinDetailsDto } from 'src/types/generated';
import classes from './ProfileOrderThankYouPage.module.scss';
import textClasses from 'src/styles/text.module.scss';
import layoutClasses from 'src/styles/layout.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';
import locationIcon from 'src/assets/img/location.svg';
import thankYouLogo from 'src/assets/img/thank-you-logo.svg';
import { getStorageItem } from 'src/utils/storage';
import { selectSalePoint } from 'src/slices/salePoint';
import { resetCart } from 'src/slices/cart';
import { trackPurchase } from 'src/utils/analytics';

interface ProfileOrderThankYouPageProps extends RouteComponentProps {}

const HomeButton: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <div className={classes.detailsSection}>
      <IonButton
        expand="block"
        routerDirection="root"
        routerLink="/tabs/menu"
        className={classNames(btnClasses.primaryBtn, textClasses.title)}
        onClick={() => {
          if (onClick) onClick();
        }}
      >
        {t('Home page')}
      </IonButton>
    </div>
  );
};

const ProfileOrderThankYouPage: React.FC<ProfileOrderThankYouPageProps> = ({ history, location, match }) => {
  const { t } = useTranslation();
  const routerProps = { history, location, match };
  const [validated, setValidated] = useState<boolean>(false);
  const [order, setOrder] = useState<OrderJoinDetailsDto>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const products = useSelector((state) => state.product.products);
  const dispatch = useDispatch();
  const orderId = Number(get(match, 'params.id'));
  const token = new URLSearchParams(location.search).get('order-token');

  useIonViewWillEnter(() => {
    const setup = async () => {
      try {
        /** Checking if dealing with a free order (bought everything with bonus points). */
        if (orderId && !token && isAuthenticated) {
          const orderData = await API.order.getMyOrder(orderId);
          setOrder(orderData);
          if (orderData?.status !== 'unpaid' || orderData?.totalPrice === 0) {
            trackPurchase(orderData, products);
            setValidated(true);
            dispatch(resetCart());
          }
        }
        /** Checking if dealing with a normal paid order */
        if (orderId && token) {
          const { paymentCompleted, order: orderData } = await API.payment.validate({ orderId, token });
          setValidated(paymentCompleted);
          setOrder(orderData);
          if (paymentCompleted) {
            trackPurchase(orderData, products);
            dispatch(resetCart());
          }
        }
      } finally {
        const salePointId = await getStorageItem('selectedSalePointId');
        if (!salePointId && !isAuthenticated && order?.salepointId) {
          selectSalePoint({ salePointId: order.salepointId });
        }
        setIsLoading(false);
      }
    };
    setup();
  }, [orderId, token]);

  return (
    <DetailsLayout
      meta={{
        title: t('-SEO Order thank you page'),
        description: t('-SEO Order thank you page description'),
      }}
      hideMobileHeader
      centerContent
      {...routerProps}
    >
      {isLoading && <LoadingOverlay backdrop spinnerColor="primary" />}
      {!order && !isLoading && (
        <div
          className={classNames(
            layoutClasses.containerSmall,
            classes.content,
            'ion-margin-vertical',
            'ion-text-center'
          )}
        >
          <h1 className={textClasses.titleBold}>{t('Order id or payment token are missed in URL')}</h1>
          <HomeButton />
        </div>
      )}
      {order && !isLoading && (
        <div className={classNames(layoutClasses.containerSmall, classes.content, 'ion-text-center')}>
          {!validated && !isLoading && (
            <div className={classNames(classes.content, 'ion-margin-vertical')}>
              <h1 className={textClasses.titleBold}>{t('Payment is not completed')}</h1>
              <HomeButton />
            </div>
          )}
          {validated && (
            <>
              <div className={classes.detailsSection}>
                <Image src={thankYouLogo} className={classes.thankYouLogo} />
              </div>

              <h3 className={classNames(textClasses.headerLBold, classes.detailsSection)}>
                {t('Thank you for your order')}
              </h3>
              <p className={classNames(textClasses.title, classes.detailsSection)}>
                {t('Do not forget to pick up your order!')}
              </p>

              <div className={classNames(classes.detailsSection, 'ion-text-left')}>
                <p className={classNames(textClasses.titleBold, classes.orderInfoRow)}>
                  <IonIcon src={locationIcon} className={classes.orderInfoIcon} />
                  <IonText className={classes.orderInfoTitle}>{t('Sale point')}</IonText>
                </p>
                <p className={classNames(textClasses.textMBold, classes.orderInfoRow)}>{order?.salePointName || '-'}</p>
              </div>

              <div className={classNames(classes.detailsSection, 'ion-text-left')}>
                <p className={classNames(textClasses.titleBold, classes.orderInfoRow)}>
                  <IonIcon icon={timeOutline} className={classes.orderInfoIcon} />
                  <IonText className={classes.orderInfoTitle}>{t('Pick up time')}</IonText>
                </p>
                {order?.deadlineAt && (
                  <p className={classNames(textClasses.textMBold, classes.orderInfoRow)}>
                    {formatDateTime(new Date(order.deadlineAt))}
                  </p>
                )}
              </div>

              <HomeButton />
            </>
          )}
        </div>
      )}
    </DetailsLayout>
  );
};

export default ProfileOrderThankYouPage;
