import DetailsHeader from 'src/components/menu/details/DetailsHeader';
import ProductComponents from 'src/components/menu/details/ProductComponents';
import ProductExtraComponents from 'src/components/menu/details/ProductExtraComponents';
import { useSelector } from 'src/store';
import classes from './ComboCampaignEditComponents.module.scss';

const ComboCampaignEditComponents: React.FC = () => {
  const { selectedProductId, products } = useSelector((state) => state.product);

  const product = (products || []).find((p) => p.id === selectedProductId) || null;

  return (
    <div className={classes.content}>
      <DetailsHeader product={product} />
      <ProductComponents productComponents={product.components} />
      <ProductExtraComponents productId={product.id} />
    </div>
  );
};

export default ComboCampaignEditComponents;
