import { useEffect, useState } from 'react';
import { throttle } from 'lodash';

import { BreakPoints } from 'src/types/interfaces';

const getDeviceConfig = (width: number) => {
  if (width < 576) {
    return BreakPoints.XS;
  }

  if (width >= 576 && width < 768) {
    return BreakPoints.SM;
  }

  if (width >= 768 && width < 992) {
    return BreakPoints.MD;
  }

  if (width >= 992 && width < 1200) {
    return BreakPoints.LG;
  }

  if (width >= 1200) {
    return BreakPoints.XL;
  }

  return null;
};

const getIsMobileView = (breakpoint: BreakPoints) => {
  switch (breakpoint) {
    case BreakPoints.XS:
    case BreakPoints.SM:
      return true;
    default:
      return false;
  }
};

const getIsDesktopView = (breakpoint: BreakPoints) => {
  switch (breakpoint) {
    case BreakPoints.MD:
    case BreakPoints.LG:
    case BreakPoints.XL:
      return true;
    default:
      return false;
  }
};

const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(() => getDeviceConfig(window.innerWidth));
  const isMobileView = getIsMobileView(breakpoint);
  const isDesktopView = getIsDesktopView(breakpoint);

  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      setBreakpoint(getDeviceConfig(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return { breakPoint: breakpoint, isMobileView, isDesktopView };
};
export default useBreakpoint;
