import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useSelector } from 'src/store';

interface HookProps {
  routerProps: RouteComponentProps;
  callback?: (salePointId: number) => void | Promise<void>;
}

const usePageSalePointReload = ({ routerProps, callback }: HookProps) => {
  const { location, match } = routerProps;
  const selectedSalePointId = useSelector((state) => state.salePoint.selectedSalePointId);
  const [prevSalePointId, setPrevSalePointId] = useState<number>(selectedSalePointId);

  useEffect(() => {
    if (prevSalePointId && prevSalePointId !== selectedSalePointId && location.pathname === match.url) {
      if (callback) callback(selectedSalePointId);
      setPrevSalePointId(selectedSalePointId);
    }
    if (!prevSalePointId && selectedSalePointId) setPrevSalePointId(selectedSalePointId);
  }, [selectedSalePointId, prevSalePointId, location.pathname, match.url]);
};

export default usePageSalePointReload;
