import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';

import API from 'src/api';
import { i18next } from 'src/hooks/useDataLoading';
import { ClientVipJoinGeneralDto, ClientVipJoinDetailsDto, ClientVipJoinDetailsCreateDto } from 'src/types/generated';
import type { AppThunk } from 'src/store';

interface LoadingState {
  vips?: boolean;
  vipDetails?: boolean;
  vipCreate?: boolean;
}

interface VipState {
  vips: ClientVipJoinGeneralDto[];
  vipDetails: ClientVipJoinDetailsDto[];
  isAddVipModalOpened: boolean;
  loading: LoadingState;
}

const initialState: VipState = {
  vips: null,
  vipDetails: null,
  isAddVipModalOpened: false,
  loading: {
    vips: false,
    vipDetails: false,
    vipCreate: false,
  },
};

const slice = createSlice({
  name: 'vips',
  initialState,
  reducers: {
    setVips(state: VipState, action: PayloadAction<ClientVipJoinGeneralDto[]>): void {
      state.vips = action.payload;
    },
    addVipDetails(state: VipState, action: PayloadAction<ClientVipJoinDetailsDto>): void {
      if (!action.payload) return;

      state.vipDetails = [...(state.vipDetails || []), action.payload];
    },
    resetVipDetails(state: VipState): void {
      state.vipDetails = [];
    },
    removeVip(state: VipState, action: PayloadAction<number>): void {
      const removedVipId = action.payload;
      state.vips = state.vips ? state.vips.filter((v) => v.id !== removedVipId) : null;
      state.vipDetails = state.vipDetails ? state.vipDetails.filter((v) => v.id !== removedVipId) : null;
    },
    setLoading(state: VipState, action: PayloadAction<LoadingState>): void {
      state.loading = { ...state.loading, ...action.payload };
    },
    openModal(state: VipState, action: PayloadAction<boolean>): void {
      state.isAddVipModalOpened = action.payload;
    },
  },
});

export const { reducer } = slice;

export const getVips =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setLoading({ vips: true }));
    try {
      const data = await API.vip.getMyVips();

      dispatch(slice.actions.setVips(data));
    } finally {
      dispatch(slice.actions.setLoading({ vips: false }));
    }
  };

export const getVipDetails =
  (vipId: number): AppThunk =>
  async (dispatch): Promise<ClientVipJoinDetailsDto> => {
    dispatch(slice.actions.setLoading({ vipDetails: true }));
    let data;
    try {
      data = await API.vip.get(vipId);

      dispatch(slice.actions.addVipDetails(data));
    } finally {
      dispatch(slice.actions.setLoading({ vipDetails: false }));
    }
    return data;
  };

export const resetVipDetails =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.resetVipDetails());
  };

export const createVip =
  (name: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setLoading({ vipCreate: true }));
    try {
      const { cartItems } = getState().cart;
      const { user } = getState().auth;
      const vipData: ClientVipJoinDetailsCreateDto = {
        clientId: user?.id,
        name,
        products: cartItems.map(({ partials, productId, quantity, variantIds }) => ({
          productId,
          quantity,
          partials: partials.map((p) => ({
            partialProductId: p.partialProductId || 0,
            quantity: p.quantity || 0,
          })),
          variants: variantIds,
        })),
      };

      const vipDetails = await API.vip.create(vipData);
      const vips = await API.vip.getMyVips();

      dispatch(slice.actions.setVips(vips));
      dispatch(slice.actions.addVipDetails(vipDetails));
      toast.success(i18next.t('VIP has been saved'));
    } catch (_) {
      toast.error(i18next.t('Error occurred during vip creating'));
    } finally {
      dispatch(slice.actions.setLoading({ vipCreate: false }));
    }
  };

export const deleteVip =
  (vipId: number): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setLoading({ vips: true }));
    try {
      await API.vip.delete(vipId);

      dispatch(slice.actions.removeVip(vipId));
      toast.success(i18next.t('VIP has been deleted'));
    } catch (_) {
      toast.error(i18next.t('Error occurred during vip deleting'));
    } finally {
      dispatch(slice.actions.setLoading({ vips: false }));
    }
  };

export const openModal =
  (state: boolean): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.openModal(state));
  };

export default slice;
