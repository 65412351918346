import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import type { PayloadAction } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';

import API from 'src/api';
import { i18next } from 'src/hooks/useDataLoading';
import {
  OrderCreateReqDto,
  OrderJoinGeneralDto,
  OrderJoinDetailsDto,
  PaymentLinkReqDto,
  ProductJoinInfoDto,
} from 'src/types/generated';
import { CartItem, resetCart, updateCart, validateDeadline } from 'src/slices/cart';
import { BankNames } from 'src/slices/payment';
import type { AppThunk } from 'src/store';
import { IApiPage } from 'src/types/interfaces';
import { getStorageItem, setStorageItem } from 'src/utils/storage';
import { DEFAULT_LOCALE } from 'src/constants';
import { getProducts, getVariants } from './product';
import { getCampaigns } from './campaign';
import { Capacitor } from '@capacitor/core';

const INIT_ORDERS_PAGING = {
  number: 1,
  size: 20,
};

export interface CreateOrderResponse {
  paymentLink: string;
  createdOrderId: string | null;
  hasUnverifiedCartItems?: boolean;
}

interface LoadingState {
  orders?: boolean;
  orderDetails?: boolean;
  orderCreate?: boolean;
}

interface OrderState {
  orders: OrderJoinGeneralDto[];
  ordersPaging: IApiPage;
  ordersTotalCount: number;
  orderDetails: OrderJoinDetailsDto[];
  clientPhoneNumber: string;
  confirmationEmail: string;
  confirmationName: string;
  checkedOrderConfirmationEmail: boolean;
  loading: LoadingState;
}

interface CreateOrderPayload {
  selectedBank: string;
}

const initialState: OrderState = {
  orders: null,
  ordersPaging: INIT_ORDERS_PAGING,
  ordersTotalCount: 0,
  orderDetails: null,
  clientPhoneNumber: null,
  confirmationEmail: null,
  confirmationName: null,
  checkedOrderConfirmationEmail: false,
  loading: {
    orders: false,
    orderDetails: false,
    orderCreate: false,
  },
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    getOrders(state: OrderState, action: PayloadAction<OrderJoinGeneralDto[]>): void {
      state.orders = action.payload;
    },
    getNextOrders(state: OrderState, action: PayloadAction<OrderJoinGeneralDto[]>): void {
      state.orders = (state.orders || []).concat(action.payload);
    },
    getOrderDetails(state: OrderState, action: PayloadAction<OrderJoinDetailsDto>): void {
      state.orderDetails = [...(state.orderDetails || []), action.payload];
    },
    resetOrderDetails(state: OrderState): void {
      state.orderDetails = [];
    },
    setOrdersPaging(state: OrderState, action: PayloadAction<IApiPage>): void {
      state.ordersPaging = { ...state.ordersPaging, ...action.payload };
    },
    setOrdersTotalCount(state: OrderState, action: PayloadAction<number>): void {
      state.ordersTotalCount = action.payload;
    },
    setClientPhoneNumber(state: OrderState, action: PayloadAction<string>): void {
      state.clientPhoneNumber = action.payload;
    },
    setConfirmationData(
      state: OrderState,
      action: PayloadAction<
        Pick<OrderState, 'confirmationEmail' | 'confirmationName' | 'checkedOrderConfirmationEmail'>
      >
    ): void {
      state.confirmationEmail = action.payload.confirmationEmail;
      state.confirmationName = action.payload.confirmationName;
      state.checkedOrderConfirmationEmail = action.payload.checkedOrderConfirmationEmail;
    },
    setLoading(state: OrderState, action: PayloadAction<LoadingState>): void {
      state.loading = { ...state.loading, ...action.payload };
    },
  },
});

export const { reducer } = slice;

export const getOrders =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setLoading({ orders: true }));
    try {
      const { items, total } = await API.order.getMyOrders({
        page: INIT_ORDERS_PAGING,
        sorts: [{ field: 'createdAt', ascending: false }],
      });

      dispatch(slice.actions.getOrders(items));
      dispatch(slice.actions.setOrdersTotalCount(total));
      dispatch(slice.actions.setOrdersPaging(INIT_ORDERS_PAGING));
    } finally {
      dispatch(slice.actions.setLoading({ orders: false }));
    }
  };

export const getNextOrders =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { ordersPaging } = getState().order;
    const nextOrdersPaging = { ...ordersPaging, number: ordersPaging.number + 1 };
    dispatch(slice.actions.setLoading({ orders: true }));
    try {
      const { items, total } = await API.order.getMyOrders({
        page: nextOrdersPaging,
        sorts: [{ field: 'createdAt', ascending: false }],
      });

      dispatch(slice.actions.getNextOrders(items));
      dispatch(slice.actions.setOrdersTotalCount(total));
      dispatch(slice.actions.setOrdersPaging(nextOrdersPaging));
    } finally {
      dispatch(slice.actions.setLoading({ orders: false }));
    }
  };

export const getOrderDetails =
  (orderId: number): AppThunk =>
  async (dispatch): Promise<OrderJoinDetailsDto> => {
    dispatch(slice.actions.setLoading({ orderDetails: true }));
    let data;
    try {
      data = await API.order.getMyOrder(orderId);

      dispatch(slice.actions.getOrderDetails(data));
    } finally {
      dispatch(slice.actions.setLoading({ orderDetails: false }));
    }
    return data;
  };

export const resetOrderDetails =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.resetOrderDetails());
  };

export const setOrdersPaging =
  (paging: IApiPage): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setOrdersPaging(paging));
  };

export const setClientPhoneNumber =
  (payload: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const storedClientPhoneNumber = await getStorageItem('clientPhoneNumber');
    if (storedClientPhoneNumber !== payload) {
      await setStorageItem('clientPhoneNumber', payload);
    }
    dispatch(slice.actions.setClientPhoneNumber(payload));
  };

export const initGuestClientData =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const clientPhoneNumber = await getStorageItem('clientPhoneNumber');
    dispatch(slice.actions.setClientPhoneNumber(clientPhoneNumber));
  };

export const setConfirmationData =
  (payload: Pick<OrderState, 'confirmationEmail' | 'confirmationName' | 'checkedOrderConfirmationEmail'>): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { user } = getState().auth;
    const storedConfirmationEmail = (await getStorageItem('confirmationEmail')) || user?.email;
    const storedConfirmationName = (await getStorageItem('confirmationName')) || `${user?.firstName} ${user?.lastName}`;
    if (storedConfirmationEmail !== payload.confirmationEmail) {
      await setStorageItem('confirmationEmail', payload.confirmationEmail);
    }
    if (storedConfirmationName !== payload.confirmationName) {
      await setStorageItem('confirmationName', payload.confirmationName);
    }
    dispatch(slice.actions.setConfirmationData(payload));
  };

export const initConfirmationData =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { user } = getState().auth;
    const confirmationEmail = (await getStorageItem('confirmationEmail')) || user?.email;
    const confirmationName =
      (await getStorageItem('confirmationName')) || `${user?.firstName || ''} ${user?.lastName || ''}`.trim();
    dispatch(
      slice.actions.setConfirmationData({ confirmationEmail, confirmationName, checkedOrderConfirmationEmail: false })
    );
  };

const _getItemsForOrderCreate = (cartItems: CartItem[], products: ProductJoinInfoDto[]) => {
  const productCartItems = cartItems.filter((ci) => ci.isProduct);
  const campaignCartItems = cartItems.filter((ci) => ci.isCampaign);
  const productOrderItems: OrderCreateReqDto['items'] = productCartItems.map(
    ({ partials, productId, quantity, variantIds, bonusedQuantity }) => ({
      partials,
      productId,
      quantity,
      variantIds,
      bonusedQuantity,
    })
  );
  const campaignOrderItems = campaignCartItems
    .map(({ quantity, uniqId, products: campaignProducts }, index) => [
      ...campaignProducts.map((p) => ({ ...p, quantity, campaignStackNumber: index + 1, campaignStackId: uniqId })),
    ])
    .flat(1)
    .filter(({ productId }) => {
      const { categories } = products.find((p) => p.id === productId) || {};
      return categories && categories.every((c) => !c.isComponent);
    })
    .map(({ campaignStackId, campaignId, productId, variantId, quantity, slotId, campaignStackNumber, partials }) => ({
      campaignStackId,
      productId,
      quantity,
      campaignId,
      campaignProductSlotId: slotId,
      variantIds: variantId ? [variantId] : undefined,
      campaignStackNumber,
      partials: partials
        ? partials.map((partial) => ({
            partialProductId: partial.partialProductId,
            quantity: partial.quantity,
            campaignProductSlotId: partial.slotId,
            campaignStackNumber,
          }))
        : [],
    }));

  const campaignOrderItemFreePartials = campaignCartItems
    .map(({ uniqId, products: campaignProducts }) => [
      ...campaignProducts.map((p) => ({ ...p, campaignStackId: uniqId })),
    ])
    .flat(1)
    .filter(({ productId }) => {
      const { categories } = products.find((p) => p.id === productId) || {};
      return categories && categories.some((c) => c.isComponent);
    });
  campaignOrderItemFreePartials.forEach((campaignOrderItemPartial) => {
    campaignOrderItems.forEach((campaignOrderItem) => {
      if (campaignOrderItem.campaignStackId === campaignOrderItemPartial.campaignStackId) {
        campaignOrderItem.partials = [
          ...(campaignOrderItem.partials || []),
          {
            partialProductId: campaignOrderItemPartial.productId,
            quantity: 1,
            campaignProductSlotId: campaignOrderItemPartial.slotId,
            campaignStackNumber: campaignOrderItem.campaignStackNumber,
          },
        ];
      }
    });
  });

  return [
    ...productOrderItems,
    ...campaignOrderItems.map((x) => omit(x, ['campaignStackId'])),
  ] as OrderCreateReqDto['items'];
};

export const createOrder =
  (payload: CreateOrderPayload): AppThunk =>
  async (dispatch, getState): Promise<CreateOrderResponse> => {
    dispatch(slice.actions.setLoading({ orderCreate: true }));
    dispatch(validateDeadline({ extraCheckoutTime: -10 }));
    let paymentLink = '';
    let createdOrderId = null;
    try {
      const { selectedBank } = payload;
      const { selectedSalePointId } = getState().salePoint;
      const { deadline, cartItems } = getState().cart;
      const { banks } = getState().payment;
      const { user, isAuthenticated } = getState().auth;
      const { languages, selectedLanguageId } = getState().system;
      const { confirmationEmail, confirmationName, checkedOrderConfirmationEmail, clientPhoneNumber } =
        getState().order;
      const { products } = getState().product;
      const userAgent = Capacitor.getPlatform();

      let verifiedCartItems = cartItems;
      let unverifiedCartItems = [];

      try {
        const cartVerificationResponse = await API.cart.verifyCartItems(cartItems);
        verifiedCartItems = cartVerificationResponse.verifiedCartItems;
        unverifiedCartItems = cartVerificationResponse.unverifiedCartItems;
      } catch (error) {
        console.error('Error during cart items verification request: ', error);
      }

      if (unverifiedCartItems.length) {
        dispatch(getVariants());
        dispatch(getProducts());
        dispatch(getCampaigns());
        toast.error(
          unverifiedCartItems.length === 1
            ? i18next.t('1 cart item was removed')
            : i18next.t('{{cartItemsCount}} cart items were removed', { cartItemsCount: unverifiedCartItems.length })
        );

        if (verifiedCartItems.length) {
          dispatch(updateCart(verifiedCartItems));
        } else {
          dispatch(resetCart());
        }
        return { paymentLink, createdOrderId, hasUnverifiedCartItems: true };
      }

      const selectedBankName = banks?.find((bank) => bank.code === selectedBank)?.name;
      const selectedLanguageCode = languages.find((lng) => lng.id === selectedLanguageId)?.isoName;
      let paymentLinkLocale: PaymentLinkReqDto['locale'] = DEFAULT_LOCALE;
      if (selectedLanguageCode === 'en') paymentLinkLocale = 'en';
      if (selectedLanguageCode === 'et') paymentLinkLocale = 'et';
      if (selectedLanguageCode === 'lt') paymentLinkLocale = 'lt';
      if (selectedLanguageCode === 'ru') paymentLinkLocale = 'ru';

      const orderData: OrderCreateReqDto = {
        salepointId: selectedSalePointId,
        clientId: user?.id || null,
        clientPhoneNumber,
        deadlineAt: new Date(deadline).toISOString().replace(/T/gi, ' ').replace(/\..*$/gi, ''),
        items: _getItemsForOrderCreate(cartItems, products),
        confirmationEmail: checkedOrderConfirmationEmail && confirmationEmail ? confirmationEmail : null,
        confirmationName: checkedOrderConfirmationEmail && confirmationName ? confirmationName : null,
        paymentMethod: BankNames[selectedBank] || selectedBankName,
        userAgent,
      };

      const createdOrder = await API.order.createOrder(orderData);
      if (isAuthenticated) {
        await dispatch(getOrders());
      }
      createdOrderId = createdOrder.id;

      if (createdOrder.totalPrice > 0) {
        const paymentLinkModel: PaymentLinkReqDto = {
          orderId: createdOrder.id,
          bank: selectedBank,
          locale: paymentLinkLocale,
          phoneNumber: clientPhoneNumber || '',
        };
        const { link } = await API.payment.getLink(paymentLinkModel);
        paymentLink = link;
      }
    } catch (_) {
      if (_?.response?.data?.code) return;
      toast.error(i18next.t('Error occurred during order creating'));
    } finally {
      dispatch(slice.actions.setLoading({ orderCreate: false }));
    }
    return { paymentLink, createdOrderId };
  };

export default slice;
