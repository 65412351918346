import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import API from 'src/api';
import { CampaignJoinInfoDto, ProductJoinInfoDto } from 'src/types/generated';
import type { AppThunk } from 'src/store';
import { chain } from 'lodash';
import { trackCampaignView } from 'src/utils/analytics';

export enum ComboCampaignModalViews {
  MAIN = 'main',
  PRODUCT_LIST = 'product_list',
  EDIT_COMPONENTS = 'edit_components',
}

type CampaignProduct = CampaignJoinInfoDto['products'][0];

export interface SelectedCampaignProduct extends CampaignProduct {
  partials?: {
    slotId: number;
    partialProductId: number;
    quantity: number;
    isExtraComponent: boolean;
  }[];
}

interface CampaignState {
  campaigns: CampaignJoinInfoDto[];
  selectedCampaignId: number | null;
  isComboCampaignOpened: boolean;
  isComboCampaignProductsOpened: boolean;
  isComboCampaignProductComponentsOpened: boolean;
  comboCampaignModalView: ComboCampaignModalViews;
  selectedSlotProducts: SelectedCampaignProduct[];
  slotParameters: {
    isFreeSlot: boolean;
    slotNumber: number;
  };
}

const initialState: CampaignState = {
  campaigns: null,
  selectedCampaignId: null,
  isComboCampaignOpened: false,
  isComboCampaignProductsOpened: false,
  isComboCampaignProductComponentsOpened: false,
  comboCampaignModalView: ComboCampaignModalViews.MAIN,
  selectedSlotProducts: [],
  slotParameters: {
    isFreeSlot: false,
    slotNumber: null,
  },
};

const slice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    getCampaigns(state: CampaignState, action: PayloadAction<CampaignJoinInfoDto[]>): void {
      state.campaigns = action.payload;
    },
    selectCampaign(state: CampaignState, action: PayloadAction<number>): void {
      state.selectedCampaignId = action.payload;
    },
    openComboCampaign(state: CampaignState, action: PayloadAction<boolean>): void {
      state.isComboCampaignOpened = action.payload;
    },
    initComboCampaign(state: CampaignState, action: PayloadAction<ProductJoinInfoDto[]>): void {
      const campaign = state.campaigns.find((c) => c.id === state.selectedCampaignId);
      const products = action.payload;
      if (!campaign) return;

      state.selectedSlotProducts = chain(campaign.products)
        .uniqBy('slotId')
        .orderBy(['slotId'], ['asc'])
        .map((campaignProduct) => {
          const { productId, variantId } = campaignProduct;
          const product = products.find((p) => p.id === productId);
          let selectedProduct;
          if (productId && (variantId || !product?.variants?.length)) {
            selectedProduct = campaignProduct;
          }
          if (productId && !variantId && product?.variants?.length > 0) {
            selectedProduct = { ...campaignProduct, variantId: product.variants[0].variantId };
          }
          if (!productId && variantId) {
            const productWithVariant = products.find((p) => p.variants?.some((pv) => pv.variantId === variantId));
            selectedProduct = { ...campaignProduct, productId: productWithVariant?.id };
          }
          return selectedProduct;
        })
        .filter('productId')
        .value();
    },
    openComboCampaignProducts(state: CampaignState, action: PayloadAction<boolean>): void {
      state.isComboCampaignProductsOpened = action.payload;
    },
    openComboCampaignProductComponents(state: CampaignState, action: PayloadAction<boolean>): void {
      state.isComboCampaignProductComponentsOpened = action.payload;
    },
    setComboCampaignModalView(state: CampaignState, action: PayloadAction<ComboCampaignModalViews>): void {
      state.comboCampaignModalView = action.payload;
    },
    selectSlotProduct(state: CampaignState, action: PayloadAction<SelectedCampaignProduct>): void {
      const selectedSlotProduct = action.payload;
      const selectedSlotProducts = [...state.selectedSlotProducts];
      const selectedSlotProductIndex = selectedSlotProducts.findIndex((s) => s.slotId === selectedSlotProduct.slotId);

      if (selectedSlotProductIndex !== -1) {
        selectedSlotProducts.splice(selectedSlotProductIndex, 1, selectedSlotProduct);
        state.selectedSlotProducts = selectedSlotProducts;
        return;
      }

      selectedSlotProducts.push(selectedSlotProduct);
      state.selectedSlotProducts = selectedSlotProducts;
    },
    setSlotParameters(state: CampaignState, action: PayloadAction<CampaignState['slotParameters']>): void {
      state.slotParameters = action.payload;
    },
    resetAllCampaignSelections(state: CampaignState): void {
      state.selectedCampaignId = null;
      state.selectedSlotProducts = [];
      state.slotParameters = { isFreeSlot: false, slotNumber: null };
    },
  },
});

export const { reducer } = slice;

export const getCampaigns =
  (): AppThunk =>
  async (dispatch): Promise<CampaignJoinInfoDto[]> => {
    let campaigns: CampaignJoinInfoDto[] = [];
    try {
      campaigns = await API.campaign.getAll();
      dispatch(slice.actions.getCampaigns(campaigns));
    } catch (_) {
      // eslint-disable-next-line no-console
      console.log('Error during fetching campaigns');
    }
    return campaigns;
  };

export const selectCampaign =
  (id?: number): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const campaignId = Number.isFinite(id) && id > 0 ? id : null;
    const { campaigns } = getState().campaign;
    trackCampaignView(campaigns.find((c) => c.id === campaignId));
    dispatch(slice.actions.selectCampaign(campaignId));
  };

export const openComboCampaign =
  (state?: boolean): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.openComboCampaign(state));
  };

export const initComboCampaign =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { products } = getState().product;
    dispatch(slice.actions.initComboCampaign(products));
  };

export const openComboCampaignProducts =
  (state?: boolean): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.openComboCampaignProducts(state));
  };

export const openComboCampaignProductComponents =
  (state?: boolean): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.openComboCampaignProductComponents(state));
  };

export const setComboCampaignModalView =
  (view: ComboCampaignModalViews): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setComboCampaignModalView(view));
  };

export const setSlotParameters =
  (slotParameters: CampaignState['slotParameters']): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setSlotParameters(slotParameters));
  };

export const selectSlotProduct =
  (slotProduct: SelectedCampaignProduct): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.selectSlotProduct(slotProduct));
  };

export const resetAllCampaignSelections =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.resetAllCampaignSelections());
  };

export default slice;
