export const MOBILE_VIEW = 'ion-hide-md-up';
export const DESKTOP_VIEW = 'ion-hide-md-down';
export const FACEBOOK_LINK = 'https://www.facebook.com/pizzakiosk.ee/';
export const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.pizzakiosk.app';
export const APP_STORE_LINK = 'https://apps.apple.com/us/app/pizzakiosk/id1605963089';
export const INSTAGRAM_LINK = 'https://www.instagram.com/pizzakiosk/';
export const VIP_ORDERS_LIMIT = 10;
export const DEFAULT_LOCALE = 'et';
export const PRODUCT_MAX_COUNT = 99; // Max count for certain product.
export const COMPONENT_MAX_COUNT = 3; // Max count of certain component in one product.
export const REMOVED_COMPONENT_MAX_AMOUNT = 2; // Max amount of original product components that can be removed.
export const EXTRA_COMPONENTS_MAX_AMOUNT = 4; // Max amount of extra components in one product.
export const CUSTOMIZED_MAX_AMOUNT = 4; // Max amount of free components for customized pizzas.
export const ERROR_CODES = {
  CLIENT_PERSONAL_CODE_ALREADY_REGISTERED: 1000,
  CLIENT_PHONE_NUMBER_ALREADY_REGISTERED: 1001,
  CLIENT_EMAIL_ALREADY_REGISTERED: 1002,
  SALEPOINT_CLOSED: 1004,
  AUTHENTICATION_ERROR: 401,
};
