import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { IonText } from '@ionic/react';
import classNames from 'classnames';

import { formatPrice } from 'src/utils';
import classes from './CampaignOrderItem.module.scss';
import textClasses from 'src/styles/text.module.scss';
import OrderItemPartials, { IOrderItemPartial } from './OrderItemPartials';
import { getCustomizedPizzaComponents } from 'src/utils/product';

export interface IPaidProduct {
  id: number;
  name: string;
  price: number;
  partials?: IOrderItemPartial[];
  isCustomized?: boolean;
}
export interface IFreeProduct {
  id: number;
  name: string;
}
export interface IFreePartial {
  id: number;
  name: string;
}

interface CampaignOrderItemProps {
  campaignName: string;
  paidProducts: IPaidProduct[];
  freeProducts?: IFreeProduct[];
  freePartials?: IFreePartial[];
  price?: number;
  quantity?: number;
  children?: ReactNode;
}

const CampaignOrderItem: React.FC<CampaignOrderItemProps> = ({
  campaignName,
  paidProducts,
  freeProducts,
  freePartials,
  price,
  quantity,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <li className={classes.item}>
      <div className={classes.itemContent}>
        <div className={classes.itemHeader}>
          <h3 className={classNames(textClasses.headerMBold, classes.itemHeaderText)}>
            {quantity ? `${quantity}x ` : ''}
            {campaignName}
          </h3>
          {Number.isFinite(price) && price !== null && (
            <IonText color="primary" className={classNames(textClasses.textL, classes.itemHeaderPrice)}>
              {formatPrice(price)}
            </IonText>
          )}
        </div>

        {paidProducts?.length > 0 && (
          <div className={classes.productsContainer}>
            <h4 className={classNames(textClasses.textS, classes.productTitle)}>{t('Chosen paid products')}</h4>

            {paidProducts.map(({ id, name, price: productPrice, partials, isCustomized }) => {
              let freePartialsData = [];
              let paidPartialsData = partials;
              if (isCustomized) {
                const { freeComponents, paidComponents } = getCustomizedPizzaComponents<IOrderItemPartial>(partials);
                freePartialsData = freeComponents;
                paidPartialsData = paidComponents;
              }

              return (
                <div key={id}>
                  <div className={classes.productRow}>
                    <IonText className={classNames(classes.productRowText, textClasses.textMBold)}>{name}</IonText>
                    <IonText color="primary" className={classNames(textClasses.textSBold, classes.productRowPrice)}>
                      {formatPrice(productPrice)}
                    </IonText>
                  </div>
                  <div className={classes.productRowPartials}>
                    {!!freePartialsData.length && (
                      <OrderItemPartials partials={freePartialsData} title={t('Chosen free extras')} isFree />
                    )}
                    <OrderItemPartials
                      partials={paidPartialsData}
                      title={freePartialsData?.length > 0 ? t('Chosen paid extras') : null}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {freeProducts?.length > 0 && (
          <div className={classes.productsContainer}>
            <h4 className={classNames(textClasses.textS, classes.productTitle)}>{t('Chosen free products')}</h4>

            {freeProducts.map(({ id, name }) => (
              <div key={id} className={classes.productRow}>
                <IonText className={classNames(classes.productRowText, textClasses.textMBold)}>{name}</IonText>
              </div>
            ))}
          </div>
        )}

        {freePartials?.length > 0 && (
          <div className={classes.productsContainer}>
            <h4 className={classNames(textClasses.textS, classes.productTitle)}>{t('Chosen free partials')}</h4>

            {freePartials.map(({ id, name }) => (
              <div key={id} className={classes.productRow}>
                <IonText className={classNames(classes.productRowText, textClasses.textMBold)}>{name}</IonText>
              </div>
            ))}
          </div>
        )}

        {children}
      </div>
    </li>
  );
};

export default CampaignOrderItem;
