import { IonButton, IonIcon, IonText } from '@ionic/react';
import classNames from 'classnames';

import { PRODUCT_MAX_COUNT } from 'src/constants';
import classes from './QtyPicker.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';
import textClasses from 'src/styles/text.module.scss';
import minusSignIcon from 'src/assets/img/minus-sign.svg';
import plusSignIcon from 'src/assets/img/plus-sign.svg';

interface QtyPickerProps {
  qty: number;
  onAddQty?: () => void | Promise<void>;
  onRemoveQty?: () => void | Promise<void>;
  buttonSize?: string;
  textMargin?: string;
  minDisabled?: boolean;
  maxDisabled?: boolean;
}

const QtyPicker: React.FC<QtyPickerProps> = ({
  qty,
  onAddQty,
  onRemoveQty,
  buttonSize,
  textMargin,
  minDisabled,
  maxDisabled,
}) => {
  const styles = {
    button: {},
    buttons: {},
    text: {},
  };
  if (buttonSize) {
    styles.button = { ...styles.button, width: buttonSize, height: buttonSize };
    styles.buttons = { ...styles.buttons, borderRadius: buttonSize };
  }
  if (textMargin) {
    styles.text = { ...styles.text, margin: textMargin };
  }

  const onAddQtyClick = async () => {
    if (onAddQty) {
      await onAddQty();
    }
  };

  const onRemoveQtyClick = async () => {
    if (onRemoveQty) {
      await onRemoveQty();
    }
  };

  return (
    <div className={classes.qtyButtons} style={styles.buttons}>
      <IonButton
        color="white"
        shape="round"
        className={classNames(btnClasses.controlBtn, classes.qtyBtn)}
        style={styles.button}
        onClick={onRemoveQtyClick}
        disabled={minDisabled === true || minDisabled === false ? minDisabled : qty <= 1}
      >
        <IonIcon color="primary" slot="icon-only" icon={minusSignIcon} className={classes.qtyBtnIcon} />
      </IonButton>
      <IonText className={textClasses.textLBold} style={styles.text} color="dark">
        {qty}
      </IonText>
      <IonButton
        color="white"
        shape="round"
        className={classNames(btnClasses.controlBtn, classes.qtyBtn)}
        style={styles.button}
        onClick={onAddQtyClick}
        disabled={maxDisabled === true || maxDisabled === false ? minDisabled : qty >= PRODUCT_MAX_COUNT}
      >
        <IonIcon color="success" slot="icon-only" icon={plusSignIcon} className={classes.qtyBtnIcon} />
      </IonButton>
    </div>
  );
};

export default QtyPicker;
