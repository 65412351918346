import { RouteComponentProps } from 'react-router-dom';
import { IonText } from '@ionic/react';
import classNames from 'classnames';

import BackButton from 'src/components/BackButton';
import { MOBILE_VIEW } from 'src/constants';
import classes from './MobileHeader.module.scss';
import textClasses from 'src/styles/text.module.scss';

interface MobileHeaderProps extends RouteComponentProps {
  title?: string;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({ history, title }) => {
  const onBackButtonClickMobile = () => {
    history.goBack();
  };

  return (
    <div className={classNames(classes.header, MOBILE_VIEW)}>
      <BackButton onClick={onBackButtonClickMobile} />
      <IonText className={classNames(textClasses.headerM, classes.headerTitle)}>{title}</IonText>
      <div className={classes.hiddenPlaceholderBackBtn}>
        <BackButton />
      </div>
    </div>
  );
};

export default MobileHeader;
