import { chain } from 'lodash';
import { CUSTOMIZED_MAX_AMOUNT } from 'src/constants';
import store from '../store';

interface IComponent {
  quantity?: number;
  qty?: number;
  componentId?: number;
  partialProductId?: number;
  id?: number;
  price?: number;
}

export const getCustomizedPizzaComponents = <T extends IComponent>(components: T[]) => {
  const { products: componentsWithPrices } = store.getState().product;

  let customizedAmount = 0;
  const freeComponents: T[] = [];
  const paidComponents: T[] = chain(components)
    .map((c) => {
      const componentId = c.id || c.partialProductId || c.componentId;
      const componentData = (componentsWithPrices || []).find((cwp) => cwp.id === componentId);
      return { ...c, price: componentData?.price ?? c.price };
    })
    .orderBy(['price'], ['desc'])
    .value();

  const arrayForLoop = [...paidComponents];

  /* eslint-disable-next-line */
  for (const component of arrayForLoop) {
    const componentQuantity = component.quantity || component.qty || 0;
    const nextCustomizedAmount = componentQuantity + customizedAmount;

    paidComponents.shift();
    customizedAmount = Math.min(nextCustomizedAmount, CUSTOMIZED_MAX_AMOUNT);

    if (nextCustomizedAmount < CUSTOMIZED_MAX_AMOUNT) {
      freeComponents.push({ ...component, quantity: componentQuantity, qty: componentQuantity });
    }

    if (nextCustomizedAmount === CUSTOMIZED_MAX_AMOUNT) {
      freeComponents.push({ ...component, quantity: componentQuantity, qty: componentQuantity });
      break;
    }

    if (nextCustomizedAmount > CUSTOMIZED_MAX_AMOUNT) {
      freeComponents.push({
        ...component,
        quantity: componentQuantity - (nextCustomizedAmount - CUSTOMIZED_MAX_AMOUNT),
        qty: componentQuantity - (nextCustomizedAmount - CUSTOMIZED_MAX_AMOUNT),
      });
      paidComponents.unshift({
        ...component,
        quantity: nextCustomizedAmount - CUSTOMIZED_MAX_AMOUNT,
        qty: nextCustomizedAmount - CUSTOMIZED_MAX_AMOUNT,
      });
      break;
    }
  }

  return { freeComponents, paidComponents };
};
