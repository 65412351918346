import { ReactNode } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';

import DetailsLayout from 'src/components/layout/DetailsLayout';
import MainLayout from 'src/components/layout/MainLayout';
import ProfileHeader from 'src/components/profile/Header';
import NavigationTabs from 'src/components/profile/NavigationTabs';
import LoadingOverlay from 'src/components/LoadingOverlay';
import { MetaHeadProps } from 'src/components/MetaHead';
import { useSelector } from 'src/store';
import { useBreakpoint } from 'src/hooks';
import classes from './ProfilePageLayout.module.scss';
import layoutClasses from 'src/styles/layout.module.scss';

interface ProfilePageLayoutProps extends RouteComponentProps {
  children?: ReactNode;
  title?: string;
  meta?: MetaHeadProps;
}
interface DesktopViewProps extends RouteComponentProps {
  children?: ReactNode;
  meta?: MetaHeadProps;
}
interface MobileViewProps extends RouteComponentProps {
  children?: ReactNode;
  meta?: MetaHeadProps;
  title?: string;
}

const DesktopView: React.FC<DesktopViewProps> = ({ history, location, match, children, meta }) => {
  const routerProps = { history, location, match };
  const { profile: isLoading } = useSelector((state) => state.auth.loading);
  return (
    <MainLayout meta={meta} {...routerProps}>
      <ProfileHeader />
      <NavigationTabs {...routerProps} />
      <div className={classNames(classes.content, layoutClasses.containerMedium)}>
        {isLoading && <LoadingOverlay backdrop spinnerColor="primary" />}
        {children}
      </div>
    </MainLayout>
  );
};

const MobileView: React.FC<MobileViewProps> = ({ history, location, match, children, title, meta }) => {
  const routerProps = { history, location, match };
  const { profile: isLoading } = useSelector((state) => state.auth.loading);
  return (
    <DetailsLayout meta={meta} headerTitle={title} {...routerProps}>
      <div className={classes.content}>
        {isLoading && <LoadingOverlay backdrop spinnerColor="primary" />}
        {children}
      </div>
    </DetailsLayout>
  );
};

const ProfilePageLayout: React.FC<ProfilePageLayoutProps> = ({ history, location, match, children, title, meta }) => {
  const routerProps = { history, location, match };
  const { isMobileView, isDesktopView } = useBreakpoint();

  return (
    <>
      {isDesktopView && (
        <DesktopView meta={meta} {...routerProps}>
          {children}
        </DesktopView>
      )}
      {isMobileView && (
        <MobileView title={title} meta={meta} {...routerProps}>
          {children}
        </MobileView>
      )}
    </>
  );
};

export default ProfilePageLayout;
