import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react';
import classNames from 'classnames';

import { useSelector } from 'src/store';
import { formatDate } from 'src/utils/time';
import classes from './OpeningsHours.module.scss';
import textClasses from 'src/styles/text.module.scss';

const AvailableTimes: React.FC = () => {
  const { t } = useTranslation();
  const { salePointAvailableTimes } = useSelector((state) => state.salePoint);

  if (!salePointAvailableTimes?.length) return null;

  return (
    <>
      <h3 className={classNames(textClasses.titleBold, classes.heading, 'ion-padding-bottom')}>{t('Exceptions')}</h3>
      <IonGrid className="ion-no-padding">
        {orderBy(salePointAvailableTimes, ['date'], ['asc']).map(({ id, date, start, end }) => {
          const dateFormatted = formatDate(new Date(date), "EEEE '('dd.MM.yyyy')'");
          const hours = start && end ? `${start}-${end}` : t('Closed');
          return (
            <IonRow key={id} className="ion-justify-content-between ion-margin-vertical">
              <IonCol className="ion-text-left ion-text-capitalize ion-no-padding" size="auto">
                <IonText className={textClasses.title}>{dateFormatted}</IonText>
              </IonCol>
              <IonCol className="ion-text-right ion-no-padding">
                <IonText color="medium" className={textClasses.title}>
                  {hours}
                </IonText>
              </IonCol>
            </IonRow>
          );
        })}
      </IonGrid>
    </>
  );
};

export default AvailableTimes;
