import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IonList, IonText, useIonViewWillEnter } from '@ionic/react';
import { get, orderBy } from 'lodash';
import classNames from 'classnames';

import DetailsLayout from 'src/components/layout/DetailsLayout';
import LoadingOverlay from 'src/components/LoadingOverlay';
import OrderItem from 'src/components/OrderItem';
import BackButton from 'src/components/BackButton';
import ReorderButton from 'src/components/profile/ReorderButton';
import { useDispatch, useSelector } from 'src/store';
import { getVipDetails, resetVipDetails } from 'src/slices/vip';
import { getCategories, getProducts } from 'src/slices/product';
import { useBreakpoint, usePageLanguageReload, usePageSalePointReload } from 'src/hooks';
import { formatPrice } from 'src/utils';
import { getPartialsPrice, getRemovedPartialsDiscount, getTotalPrice } from 'src/utils/order';
import { DESKTOP_VIEW } from 'src/constants';
import classes from './ProfileVIPDetailsPage.module.scss';
import textClasses from 'src/styles/text.module.scss';
import layoutClasses from 'src/styles/layout.module.scss';
import { getCustomizedPizzaComponents } from '../utils/product';
import { ClientVipJoinDetailsDto } from '../types/generated';

interface ProfileVIPDetailsPageProps extends RouteComponentProps {}

const ProfileVIPDetailsPage: React.FC<ProfileVIPDetailsPageProps> = ({ history, location, match }) => {
  const { t } = useTranslation();
  const routerProps = { history, location, match };
  const { isMobileView } = useBreakpoint();
  const vipDetailsData = useSelector((state) => state.vip.vipDetails);
  const { products, categories } = useSelector((state) => state.product);
  const { vipDetails: isLoading } = useSelector((state) => state.vip.loading);
  const pageVipId = Number(get(match, 'params.id'));
  const dispatch = useDispatch();
  const vipDetails = vipDetailsData ? vipDetailsData.find((v) => v.id === pageVipId) : null;

  useIonViewWillEnter(() => {
    const setup = async () => {
      if (!vipDetailsData || !vipDetailsData.some((v) => v.id === pageVipId)) {
        await dispatch(getVipDetails(Number(pageVipId)));
      }
    };
    if (!products) {
      dispatch(getProducts());
    }
    if (!categories) {
      dispatch(getCategories());
    }
    setup();
  }, [vipDetailsData, pageVipId, products, categories]);

  const onLanguageChange = async () => {
    await dispatch(resetVipDetails());
    await dispatch(getVipDetails(Number(pageVipId)));
  };

  const onSalePointChange = async () => {
    await dispatch(resetVipDetails());
    await dispatch(getVipDetails(Number(pageVipId)));
  };

  usePageLanguageReload({ routerProps, callback: onLanguageChange });
  usePageSalePointReload({ routerProps, callback: onSalePointChange });

  const getOrderProducts = async () => {
    return Promise.resolve(vipDetails?.products);
  };

  const onReordered = () => {
    if (isMobileView) {
      history.push('/tabs/profile');
    }
  };

  return (
    <DetailsLayout
      meta={{
        title: t('-SEO Profile VIP details page'),
        description: t('-SEO Profile VIP details page description'),
      }}
      headerTitle={vipDetails?.name}
      contentHeaderTitle={vipDetails?.name}
      {...routerProps}
    >
      {isLoading && <LoadingOverlay backdrop spinnerColor="primary" />}
      {!vipDetails && !isLoading && <h1>{t('VIP order not found')}</h1>}
      {vipDetails && !isLoading && (
        <div className={classNames(layoutClasses.containerMedium, classes.content)}>
          <div className={classNames(classes.detailsSection, DESKTOP_VIEW)}>
            <BackButton routerLink="/tabs/profile/vip">
              <IonText className={classNames(textClasses.textL, 'ion-margin-horizontal')} color="primary">
                {t('Back to VIPs')}
              </IonText>
            </BackButton>
          </div>
          <div className={classes.detailsSection}>
            <div className={classes.listHeader}>
              <h3 className={classNames(textClasses.titleBold, classes.listHeaderTitle)}>{t('VIP summary')}:</h3>
              <ReorderButton getOrderProducts={getOrderProducts} onReordered={onReordered} />
            </div>
            <IonList className={classes.list}>
              {vipDetails.products.map(
                ({ name, price, quantity, partials: productPartials, variants, isCustomized }, index) => {
                  const mapPartialsData = (partialsArr) =>
                    orderBy(
                      partialsArr.map(
                        ({ partialProductId, name: partialName, quantity: partialQty, price: partialPrice }) => {
                          return {
                            id: partialProductId,
                            name: partialName,
                            price: partialPrice,
                            quantity: partialQty,
                          };
                        }
                      ),
                      ['quantity', 'name'],
                      ['asc', 'asc']
                    );
                  let freePartialsData = [];
                  let paidPartialsData = productPartials;
                  if (isCustomized) {
                    const customizedPizzaComponents =
                      getCustomizedPizzaComponents<ClientVipJoinDetailsDto['products'][0]['partials'][0]>(
                        productPartials
                      );
                    freePartialsData = customizedPizzaComponents.freeComponents;
                    paidPartialsData = customizedPizzaComponents.paidComponents;
                  }
                  const partials = mapPartialsData(paidPartialsData);
                  const freePartials = mapPartialsData(freePartialsData);
                  const variantsText = variants.map((variant) => variant.name).join(', ');
                  const variantPrice = variants.find((v) => v.price > 0)?.price || 0;
                  const partialsPrice = getPartialsPrice(paidPartialsData);
                  const removedPartialsDiscount = getRemovedPartialsDiscount(productPartials);
                  const productTotalPrice = getTotalPrice({
                    productPrice: price,
                    variantPrice,
                    partialsPrice,
                    removedPartialsDiscount,
                    quantity,
                  });
                  return (
                    <OrderItem
                      key={index}
                      productName={name}
                      variantsText={variantsText}
                      partials={partials}
                      freePartials={freePartials}
                      price={productTotalPrice}
                      quantity={quantity}
                    />
                  );
                }
              )}
            </IonList>
          </div>
          <div className={classNames(classes.footer, classes.detailsSection)}>
            <h3 className={classNames(textClasses.titleBold, classes.footerText)}>{t('Total')}</h3>
            <IonText color="primary" className={classNames(textClasses.titleBold, classes.footerPrice)}>
              {formatPrice(vipDetails.price)}
            </IonText>
          </div>
        </div>
      )}
    </DetailsLayout>
  );
};

export default ProfileVIPDetailsPage;
