import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonList,
  IonText,
  useIonViewWillEnter,
} from '@ionic/react';
import classNames from 'classnames';

import LoadingOverlay from 'src/components/LoadingOverlay';
import ReorderButton, { ReorderBtnVariants } from 'src/components/profile/ReorderButton';
import { useDispatch, useSelector } from 'src/store';
import { useBreakpoint } from 'src/hooks';
import { getOrders, getNextOrders, getOrderDetails } from 'src/slices/order';
import { formatPrice } from 'src/utils';
import { formatDateTime } from 'src/utils/time';
import { OrderJoinDetailsDto } from 'src/types/generated';
import classes from './Orders.module.scss';
import textClasses from 'src/styles/text.module.scss';
import layoutClasses from 'src/styles/layout.module.scss';

interface OrdersPageProps extends RouteComponentProps {}

const Orders: React.FC<OrdersPageProps> = ({ history }) => {
  const { t } = useTranslation();
  const { orders, ordersPaging, ordersTotalCount, loading } = useSelector((state) => state.order);
  const { orders: isLoading } = loading;
  const maxPageNumber = Math.ceil(ordersTotalCount / ordersPaging.size);
  const nextPageNumber = ordersPaging.number + 1;
  const dispatch = useDispatch();
  const { isMobileView } = useBreakpoint();

  useIonViewWillEnter(() => {
    if (!orders) {
      dispatch(getOrders());
    }
  }, [orders]);

  const loadOrders = async (ev: any) => {
    await dispatch(getNextOrders());
    ev.target.complete();
  };

  const getOrderProducts = async (orderId: number) => {
    const orderDetails = (await dispatch(getOrderDetails(orderId))) as unknown as OrderJoinDetailsDto;
    return orderDetails.items;
  };

  const onReordered = () => {
    if (isMobileView) {
      history.goBack();
    }
  };

  return (
    <>
      {isLoading && !orders && <LoadingOverlay backdrop spinnerColor="primary" />}
      {orders?.length === 0 && (
        <div>
          <h3 className={classNames(textClasses.textLBold, 'ion-text-center', 'ion-margin-vertical')}>
            {t('You have not ordered anything yet')}
          </h3>
        </div>
      )}
      {orders?.length > 0 && (
        <>
          <div className={layoutClasses.containerMedium}>
            <h2 className={textClasses.titleBold}>{t('Orders history')}</h2>
            <IonList className={classes.ordersList}>
              {orders?.length > 0 &&
                orders.map((order) => {
                  return (
                    <IonItem
                      key={order.id}
                      className={classes.orderListItem}
                      detail={false}
                      lines="none"
                      button
                      routerLink={`/tabs/profile/orders/${order.id}`}
                    >
                      <div className={classes.itemContent}>
                        <div className={classes.itemHeader}>
                          <div className={classes.itemHeaderTexts}>
                            <h3 className={classNames(textClasses.headerMBold, classes.itemTitle)}>
                              {order.salePointName}
                            </h3>
                            <IonText color="medium">
                              <p className={classNames(textClasses.textSBold, classes.itemDescription)}>
                                {order.description}
                              </p>
                            </IonText>
                          </div>
                          <ReorderButton
                            getOrderProducts={() => getOrderProducts(order.id)}
                            buttonVariant={ReorderBtnVariants.icon}
                            onReordered={onReordered}
                          />
                        </div>

                        <div className={classes.itemFooter}>
                          <IonText color="medium" className={textClasses.textSBold}>
                            {formatDateTime(new Date(order.deadlineAt))}
                          </IonText>
                          <IonText color="primary" className={textClasses.textL}>
                            {formatPrice(order.totalPrice)}
                          </IonText>
                        </div>
                      </div>
                    </IonItem>
                  );
                })}
            </IonList>
          </div>
          <IonInfiniteScroll onIonInfinite={loadOrders} disabled={nextPageNumber > maxPageNumber} threshold="100px">
            <IonInfiniteScrollContent loadingSpinner="dots" loadingText={`${t('Loading more orders')}...`} />
          </IonInfiniteScroll>
        </>
      )}
    </>
  );
};

export default Orders;
