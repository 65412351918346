import { IonSkeletonText } from '@ionic/react';
import { random } from 'lodash';

import classes from 'src/components/loaders/loaders.module.scss';

const MenuCategoriesLoader: React.FC = () => (
  <div className={classes.categoriesLoader}>
    <div className={classes.categoriesLoaderRow}>
      {[...Array(4)].map((_, index) => {
        const width = `${random(80, 130)}px`;
        return (
          <IonSkeletonText
            key={index}
            animated
            style={{ minWidth: width, width }}
            className={classes.categoriesLoaderItem}
          />
        );
      })}
    </div>
    <div className={classes.categoriesLoaderRow}>
      {[...Array(6)].map((_, index) => {
        const width = `${random(80, 130)}px`;
        return (
          <IonSkeletonText
            key={index}
            animated
            style={{ minWidth: width, width }}
            className={classes.categoriesLoaderItem}
          />
        );
      })}
    </div>
  </div>
);

export default MenuCategoriesLoader;
