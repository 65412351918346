import { IonBackdrop, IonSpinner } from '@ionic/react';
import 'src/components/LoadingOverlay.scss';

interface LoadingOverlayProps {
  backdrop?: boolean;
  spinnerColor?: string;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = (
  { backdrop, spinnerColor } = { backdrop: true, spinnerColor: 'primary' }
) => (
  <>
    {backdrop && <IonBackdrop style={{ zIndex: 999999, position: 'fixed' }} />}
    <div className="spinner-container" style={{ zIndex: 999999 }}>
      <IonSpinner name="crescent" color={spinnerColor} />
    </div>
  </>
);

export default LoadingOverlay;
