import { IonButton, IonIcon, IonText } from '@ionic/react';
import { call, logoFacebook, logoInstagram, mapOutline } from 'ionicons/icons';
import classNames from 'classnames';

import { useSelectedSalePointGetter } from 'src/hooks';
import { FACEBOOK_LINK, INSTAGRAM_LINK } from 'src/constants';
import classes from './ContactButtons.module.scss';
import textClasses from 'src/styles/text.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';

const ContactButtons: React.FC = () => {
  const salePoint = useSelectedSalePointGetter();

  return (
    <div className={classes.contactButtons}>
      <IonButton
        href={`tel:${salePoint?.phoneNumber}`}
        color="white"
        className={classNames(btnClasses.outlineBtn, classes.contactBtn, classes.phoneBtn)}
      >
        <IonIcon slot="start" icon={call} className={classes.contactBtnIcon} />
        <IonText className={classNames(textClasses.title, classes.contactBtnText)}>{salePoint?.phoneNumber}</IonText>
      </IonButton>
      <IonButton
        color="white"
        className={classNames(btnClasses.outlineBtn, classes.contactBtn)}
        href={salePoint?.facebookLink || FACEBOOK_LINK}
        target="_blank"
      >
        <IonIcon icon={logoFacebook} className={classes.contactBtnIcon} />
      </IonButton>
      <IonButton
        color="white"
        className={classNames(btnClasses.outlineBtn, classes.contactBtn)}
        href={salePoint?.instagramLink || INSTAGRAM_LINK}
        target="_blank"
      >
        <IonIcon icon={logoInstagram} className={classes.contactBtnIcon} />
      </IonButton>
      {salePoint?.googleMapsLink && (
        <IonButton
          color="white"
          className={classNames(btnClasses.outlineBtn, classes.contactBtn)}
          href={salePoint?.googleMapsLink}
          target="_blank"
        >
          <IonIcon icon={mapOutline} className={classes.contactBtnIcon} />
        </IonButton>
      )}
    </div>
  );
};

export default ContactButtons;
