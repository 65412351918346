import { format, Locale } from 'date-fns';
import { enUS, et, ru, fi, sv } from 'date-fns/locale';

import store from 'src/store';
import { DEFAULT_LOCALE } from 'src/constants';

const dateLocales = {
  en: enUS,
  et,
  ru,
  fi,
  se: sv,
};

const getDateLocale = (): Locale => {
  const { languages, selectedLanguageId } = store.getState().system;
  const { isoName: languageCode } = (languages || []).find((lng) => lng.id === selectedLanguageId) || {};
  return dateLocales[languageCode] || dateLocales[DEFAULT_LOCALE];
};

export const formatDate = (date: Date, how = 'dd.MM.yyyy'): string => {
  return format(date, how, { locale: getDateLocale() });
};

export const formatTime = (date: Date, how = 'HH:mm'): string => {
  return format(date, how, { locale: getDateLocale() });
};

export const formatDateTime = (date: Date, how = 'dd.MM.yyyy HH:mm'): string => {
  return format(date, how, { locale: getDateLocale() });
};

export const toSQLDateTime = (date: Date, how = 'yyyy-MM-dd HH:mm:ss'): string => {
  return format(date, how);
};

export const toSQLDate = (date: Date, how = 'yyyy-MM-dd'): string => {
  return format(date, how);
};
