import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ProfilePageLayout from 'src/components/layout/ProfilePageLayout';
import Orders from 'src/components/profile/Orders';
import { useDispatch } from 'src/store';
import { getOrders } from 'src/slices/order';
import { usePageLanguageReload } from 'src/hooks';

interface ProfileOrdersPageProps extends RouteComponentProps {}

const ProfileOrdersPage: React.FC<ProfileOrdersPageProps> = ({ history, location, match }) => {
  const { t } = useTranslation();
  const routerProps = { history, location, match };
  const dispatch = useDispatch();

  const onLanguageChange = () => {
    dispatch(getOrders());
  };

  usePageLanguageReload({ routerProps, callback: onLanguageChange });

  return (
    <ProfilePageLayout
      title={t('My orders')}
      meta={{
        title: t('-SEO Profile my orders page'),
        description: t('-SEO Profile my orders page description'),
      }}
      {...routerProps}
    >
      <Orders {...routerProps} />
    </ProfilePageLayout>
  );
};

export default ProfileOrdersPage;
