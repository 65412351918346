import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import API from 'src/api';
import { PaymentBanksDto } from 'src/types/generated';
import type { AppThunk } from 'src/store';

interface LoadingState {
  banks?: boolean;
}

interface PaymentState {
  banks: PaymentBanksDto[];
  loading: LoadingState;
}

const initialState: PaymentState = {
  banks: null,
  loading: {
    banks: false,
  },
};

export enum BankNames {
  HABAEE2X = 'Swedbank',
  EEUHEE2X = 'SEB',
  LHVBEE22 = 'LHV',
  RIKOEE22 = 'Luminor',
  EKRDEE22 = 'COOP',
  PARXEE22 = 'Citadele',
  RVUALT2V = 'Revolut',
  CARD = 'Card payment',
}

const slice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    getBanks(state: PaymentState, action: PayloadAction<PaymentBanksDto[]>): void {
      state.banks = action.payload;
    },
    setLoading(state: PaymentState, action: PayloadAction<LoadingState>): void {
      state.loading = { ...state.loading, ...action.payload };
    },
  },
});

export const { reducer } = slice;

export const getBanks =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setLoading({ banks: true }));
    try {
      const banks = await API.payment.getBanks();

      dispatch(slice.actions.getBanks(banks));
    } finally {
      dispatch(slice.actions.setLoading({ banks: false }));
    }
  };

export default slice;
