import { CampaignCartItemDto, CartItemsDto, ProductCartItemDto } from 'src/types/generated';

import BaseApiModule from 'src/api/base';

interface IVerifyCartItemsResponse {
  verified: CartItemsDto;
  unverified: CartItemsDto;
}

export type ICartItem = Partial<ProductCartItemDto & CampaignCartItemDto>;

class CartApi extends BaseApiModule {
  protected baseRoute = '/cart';

  public async verifyCartItems(model: ICartItem[]): Promise<{
    verifiedCartItems: ICartItem[];
    unverifiedCartItems: ICartItem[];
  }> {
    const { verified, unverified } = (await this._post<IVerifyCartItemsResponse>(
      `${this.baseRoute}/verify-cart-items`,
      {
        productItems: model.filter((c) => c.isProduct) as ProductCartItemDto[],
        campaignItems: model.filter((c) => c.isCampaign) as CampaignCartItemDto[],
      }
    )) || {
      verified: { productItems: [], campaignItems: [] },
      unverified: { productItems: [], campaignItems: [] },
    };
    return {
      verifiedCartItems: [...verified.campaignItems, ...verified.productItems],
      unverifiedCartItems: [...unverified.campaignItems, ...unverified.productItems],
    };
  }
}

export default CartApi;
