import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { IonButton, IonIcon, IonText } from '@ionic/react';
import { reloadOutline } from 'ionicons/icons';
import { sortBy } from 'lodash';
import classNames from 'classnames';
import toast from 'react-hot-toast';

import { useDispatch, useSelector } from 'src/store';
import { addCartItem, CartItem } from 'src/slices/cart';
import { COMPONENT_MAX_COUNT } from 'src/constants';
import { useComponentsGetter } from 'src/hooks';
import { CampaignTypes } from 'src/types/interfaces';
import classes from './ReorderButton.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';

export enum ReorderBtnVariants {
  text = 'text',
  icon = 'icon',
}

interface OrderProducts {
  productId: number;
  campaignType?: string | null;
  quantity: number;
  partials?: {
    partialProductId: number;
    quantity: number;
  }[];
  variants?: {
    variantId: number;
  }[];
}

interface ReorderButtonProps {
  getOrderProducts: () => Promise<OrderProducts[]>;
  buttonVariant?: ReorderBtnVariants;
  onReordered?: () => void;
}

const ReorderButton: React.FC<ReorderButtonProps> = ({
  getOrderProducts,
  buttonVariant = ReorderBtnVariants.text,
  onReordered,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { components } = useComponentsGetter();
  const products = useSelector((state) => state.product.products);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const onReorderBtnClick = async (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    const orderProducts = await getOrderProducts();
    const cartItems: CartItem[] = [];

    /* eslint-disable-next-line */
    for (const orderProduct of orderProducts) {
      const uniqId = uuidv4();
      const { productId, campaignType, quantity, partials = [], variants = [] } = orderProduct;
      const product = products.find((p) => p.id === productId);
      const isComboCampaign = campaignType === CampaignTypes.COMBO;
      const partialsAreCorrect = product
        ? partials.every((partial) => {
            const component = components.find((c) => c.id === partial.partialProductId);
            return component && (component?.maxAmount || COMPONENT_MAX_COUNT) >= partial.quantity;
          })
        : false;
      const variantsAreCorrect = product
        ? variants.every(({ variantId }) => {
            const variantExists = product.variants.some((v) => v.id === variantId);
            return variantExists;
          })
        : false;
      if (isComboCampaign || !product || !partialsAreCorrect || !variantsAreCorrect) {
        setIsDisabled(true);
        toast.error(t('Some of the ordered items are missing. Please make a new order manually.'));
        return;
      }

      cartItems.push({
        productId,
        partials: sortBy(partials, 'price').map(({ partialProductId, quantity: partialProductQty }) => ({
          partialProductId,
          quantity: partialProductQty,
        })),
        variantIds: variants.map(({ variantId }) => variantId),
        quantity,
        uniqId,
        isProduct: true,
      });
    }

    /* eslint-disable-next-line */
    for (const cartItem of cartItems) {
      await dispatch(addCartItem(cartItem));
    }

    toast.success(t('Order added to cart'));

    if (onReordered) {
      onReordered();
    }
  };

  if (!products || !components) return null;

  return (
    <>
      {buttonVariant === ReorderBtnVariants.text && (
        <IonButton
          color="primary"
          className={classNames(btnClasses.chipBtn, classes.textReorderBtn)}
          onClick={onReorderBtnClick}
          disabled={isDisabled}
        >
          <IonText className={classes.textReorderBtnText}>{t('Order again')}</IonText>
        </IonButton>
      )}
      {buttonVariant === ReorderBtnVariants.icon && (
        <IonButton
          color="white"
          className={classNames(btnClasses.controlBtn, classes.iconReorderBtn)}
          onClick={onReorderBtnClick}
          disabled={isDisabled}
        >
          <IonIcon slot="icon-only" icon={reloadOutline} className={classes.iconReorderBtnIcon} />
        </IonButton>
      )}
    </>
  );
};

export default ReorderButton;
