import type { FC } from 'react';
import { IonPage, IonContent } from '@ionic/react';

import LoadingOverlay from 'src/components/LoadingOverlay';
import { MOBILE_VIEW } from 'src/constants';

const SlashScreen: FC = () => (
  <IonPage className={MOBILE_VIEW} style={{ zIndex: 999999 }}>
    <IonContent color="white">
      <LoadingOverlay backdrop={false} spinnerColor="primary" />
    </IonContent>
  </IonPage>
);

export default SlashScreen;
