import { useTranslation } from 'react-i18next';
import { IonButton, IonIcon, IonText } from '@ionic/react';
import classNames from 'classnames';
import toast from 'react-hot-toast';

import QtyPicker from 'src/components/QtyPicker';
import { useDispatch, useSelector } from 'src/store';
import { useBreakpoint, useProductPriceGetter } from 'src/hooks';
import { setProductQty, addProductToCart, openProductDetails } from 'src/slices/product';
import { formatPrice } from 'src/utils';
import { PRODUCT_MAX_COUNT } from 'src/constants';
import { ProductJoinInfoDto } from 'src/types/generated';
import classes from './DetailsFooter.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';
import textClasses from 'src/styles/text.module.scss';
import cartIcon from 'src/assets/img/cart.svg';

interface DetailsFooterProps {
  product: ProductJoinInfoDto;
}

const DetailsFooter: React.FC<DetailsFooterProps> = ({ product }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobileView, isDesktopView } = useBreakpoint();
  const { productQuantities, productExtraComponentQuantities } = useSelector((state) => state.product);
  const { selectedSalePointId } = useSelector((state) => state.salePoint);
  const productId = product.id;
  const productPrice = useProductPriceGetter(productId);
  const productQty = productQuantities[productId] || 1;
  const ionButtonProps = {} as { routerLink?: string };

  if (isMobileView) {
    ionButtonProps.routerLink = '/tabs/menu';
  }

  const onAddQty = () => {
    if (productQty >= PRODUCT_MAX_COUNT) return;
    dispatch(setProductQty({ [productId]: productQty + 1 }));
  };

  const onRemoveQty = () => {
    if (productQty <= 1) return;
    dispatch(setProductQty({ [productId]: productQty - 1 }));
  };

  const onAddToCartBtnClick = () => {
    if (!selectedSalePointId) {
      toast.error(t('Please select sale point'));
      return;
    }
    if (product.isCustomized && productExtraComponentQuantities.every((pec) => pec.qty < 1)) {
      toast.error(t('Please select at least one component'));
      return;
    }
    dispatch(addProductToCart(productId));
    if (isDesktopView) {
      dispatch(openProductDetails(false));
    }
  };

  const priceText = <IonText className={textClasses.textL}>{formatPrice(productPrice * productQty)}</IonText>;

  return (
    <div className={classes.stickyFooter}>
      <div className={classes.container}>
        <div className={classes.qtyPicker}>
          <QtyPicker qty={productQty} onAddQty={onAddQty} onRemoveQty={onRemoveQty} />
        </div>
        <IonButton
          className={classNames(btnClasses.primaryBtn, classes.addToCartBtn, classes.longBtn)}
          onClick={onAddToCartBtnClick}
          {...ionButtonProps}
        >
          <IonText className={classNames(textClasses.textL, classes.addToCartText)}>{t('Add to cart')}&nbsp;</IonText>
          {priceText}
        </IonButton>
        <IonButton
          className={classNames(btnClasses.primaryBtn, classes.addToCartBtn, classes.shortBtn)}
          onClick={onAddToCartBtnClick}
          {...ionButtonProps}
        >
          <IonIcon src={cartIcon} slot="start" className={classes.cartIcon} />
          <IonText className={classNames(textClasses.textL, classes.addToCartText)}>{t('Add')}&nbsp;</IonText>
          {priceText}
        </IonButton>
      </div>
    </div>
  );
};

export default DetailsFooter;
