import { useTranslation } from 'react-i18next';
import { IonText } from '@ionic/react';
import classNames from 'classnames';

import { useCartPriceGetter } from 'src/hooks';
import { formatPrice } from 'src/utils';
import classes from './TotalPrice.module.scss';
import textClasses from 'src/styles/text.module.scss';

const TotalPrice: React.FC = () => {
  const { t } = useTranslation();
  const { total } = useCartPriceGetter();
  return (
    <div className={classes.container}>
      <h2 className={classNames(textClasses.titleBold, classes.label)}>{t('Total')}</h2>
      <IonText className={classNames(textClasses.title, classes.value)} color="primary">
        {formatPrice(total)}
      </IonText>
    </div>
  );
};

export default TotalPrice;
