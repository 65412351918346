import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IonList, IonText, useIonViewWillEnter } from '@ionic/react';
import { get, orderBy, chain } from 'lodash';
import classNames from 'classnames';

import DetailsLayout from 'src/components/layout/DetailsLayout';
import LoadingOverlay from 'src/components/LoadingOverlay';
import OrderItem from 'src/components/OrderItem';
import CampaignOrderItem from 'src/components/CampaignOrderItem';
import BackButton from 'src/components/BackButton';
import ReorderButton from 'src/components/profile/ReorderButton';
import { formatPrice } from 'src/utils';
import { useDispatch, useSelector } from 'src/store';
import { getOrderDetails, resetOrderDetails } from 'src/slices/order';
import { getCategories, getProducts } from 'src/slices/product';
import { getCampaigns } from 'src/slices/campaign';
import { formatDateTime } from 'src/utils/time';
import { getPartialsPrice, getRemovedPartialsDiscount, getTotalPrice } from 'src/utils/order';
import { getCampaignProductName } from 'src/utils/campaign';
import { useBreakpoint, usePageLanguageReload } from 'src/hooks';
import { DESKTOP_VIEW } from 'src/constants';
import { CampaignTypes } from 'src/types/interfaces';
import classes from './ProfileOrderDetailsPage.module.scss';
import textClasses from 'src/styles/text.module.scss';
import layoutClasses from 'src/styles/layout.module.scss';
import { OrderJoinDetailsDto } from '../types/generated';

interface ProfileOrderDetailsPageProps extends RouteComponentProps {}

const mapOrderPartials = (partials: OrderJoinDetailsDto['items'][0]['partials']) => {
  return orderBy(
    partials.map(({ partialProductId, name: partialName, quantity: partialQty, price: partialPrice }) => ({
      id: partialProductId,
      name: partialName,
      price: partialPrice,
      quantity: partialQty,
    })),
    ['quantity', 'name'],
    ['asc', 'asc']
  );
};

const ProfileOrderDetailsPage: React.FC<ProfileOrderDetailsPageProps> = ({ history, location, match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobileView } = useBreakpoint();
  const routerProps = { history, location, match };
  const orderDetailsData = useSelector((state) => state.order.orderDetails);
  const { orderDetails: isLoading } = useSelector((state) => state.order.loading);
  const { products, categories } = useSelector((state) => state.product);
  const campaigns = useSelector((state) => state.campaign.campaigns);

  const pageOrderId = Number(get(match, 'params.id'));
  const orderDetails = orderDetailsData ? orderDetailsData.find((o) => o.id === pageOrderId) : null;
  const orderDetailsItems = orderDetails?.items || [];
  const productOrderItems = orderDetailsItems.filter((item) => item.campaignType !== CampaignTypes.COMBO);
  const comboCampaignOrderItems = chain(orderDetailsItems)
    .filter({ campaignType: CampaignTypes.COMBO })
    .groupBy('campaignStackNumber')
    .value();

  useIonViewWillEnter(() => {
    const setup = async () => {
      if (!orderDetailsData || !orderDetailsData.some((o) => o.id === pageOrderId)) {
        await dispatch(getOrderDetails(Number(pageOrderId)));
      }
    };
    if (!products) {
      dispatch(getProducts());
    }
    if (!categories) {
      dispatch(getCategories());
    }
    if (!campaigns) {
      dispatch(getCampaigns());
    }
    setup();
  }, [orderDetails, pageOrderId, products, categories]);

  const onLanguageChange = async () => {
    await dispatch(resetOrderDetails());
    await dispatch(getOrderDetails(Number(pageOrderId)));
    await dispatch(getCampaigns());
  };

  usePageLanguageReload({ routerProps, callback: onLanguageChange });

  const getOrderProducts = async () => {
    return Promise.resolve(orderDetails?.items);
  };

  const onReordered = () => {
    if (isMobileView) {
      history.push('/tabs/profile');
    }
  };

  return (
    <DetailsLayout
      meta={{
        title: t('-SEO Profile order details page'),
        description: t('-SEO Profile order details page description'),
      }}
      headerTitle={orderDetails?.salePointName}
      contentHeaderTitle={orderDetails?.salePointName}
      {...routerProps}
    >
      {isLoading && <LoadingOverlay backdrop spinnerColor="primary" />}
      {!orderDetails && !isLoading && <h1>{t('Order not found')}</h1>}
      {orderDetails && !isLoading && (
        <div className={classNames(layoutClasses.containerMedium, classes.content)}>
          <div className={classNames(classes.detailsSection, DESKTOP_VIEW)}>
            <BackButton routerLink="/tabs/profile/orders">
              <IonText className={classNames(textClasses.textL, 'ion-margin-horizontal')} color="primary">
                {t('Back to orders')}
              </IonText>
            </BackButton>
          </div>
          <div className={classNames(classes.detailsSection, classes.headerSection)}>
            <div>
              <h3 className={classNames(textClasses.titleBold, classes.pickUpTimeLabel)}>{t('Pick up time')}</h3>
              <IonText color="medium" className={textClasses.title}>
                {formatDateTime(new Date(orderDetails.deadlineAt))}
              </IonText>
            </div>
            <div className="ion-text-right">
              <h3 className={classNames(textClasses.titleBold, classes.pickUpTimeLabel)}>{t('Order number')}</h3>
              <IonText color="medium" className={textClasses.title}>
                {`${orderDetails.compuCashInvoiceId || ''} / ${orderDetails.id}`}
              </IonText>
            </div>
          </div>
          <div>
            <h3 className={classNames(textClasses.titleBold, classes.pickUpTimeLabel)}>{t('Order status')}</h3>
            <IonText color="medium" className={textClasses.title}>
              {orderDetails.status === 'paid' && orderDetails.totalPrice > 0 && t('paid')}
              {orderDetails.status === 'unpaid' && t('unpaid')}
              {orderDetails.paymentMethod === 'bonus_points' && t('paid with bonus points')}
            </IonText>
          </div>
          <div className={classes.detailsSection}>
            <div className={classes.listHeader}>
              <h3 className={classNames(textClasses.titleBold, classes.listHeaderTitle)}>{t('Order summary')}</h3>
              <ReorderButton getOrderProducts={getOrderProducts} onReordered={onReordered} />
            </div>
            {campaigns && (
              <IonList>
                {Object.keys(comboCampaignOrderItems).map((campaignStackNumber, index) => {
                  const campaignOrderItems = comboCampaignOrderItems[campaignStackNumber];
                  const campaignId = campaignOrderItems?.[0]?.campaignId || 0;
                  const campaignData = campaigns.find((c) => c.id === Number(campaignId));
                  if (!campaignData || !campaignOrderItems) return null;

                  const { name: campaignName } = campaignData;
                  const quantity = campaignOrderItems?.[0]?.quantity || 0;
                  const paidOrderItems = campaignOrderItems.filter(
                    ({ isFreeCampaignProduct }) => !isFreeCampaignProduct
                  );
                  const freeOrderItems = campaignOrderItems.filter(
                    ({ isFreeCampaignProduct }) => isFreeCampaignProduct
                  );
                  const freeOrderItemPartials = (paidOrderItems?.[0]?.partials || []).filter(
                    ({ isFreeCampaignProduct }) => isFreeCampaignProduct
                  );
                  const campaignOrderItemsPrices = paidOrderItems.map((orderItem) => {
                    const variantPrice = (orderItem.variants || []).find((v) => v.price > 0)?.price || 0;
                    const paidProductPartials = (orderItem.partials || []).filter((p) => !p.isFreeCampaignProduct);
                    const partialsPrice = getPartialsPrice(paidProductPartials);
                    const removedPartialsDiscount = getRemovedPartialsDiscount(paidProductPartials);
                    const productTotalPrice = getTotalPrice({
                      productPrice: orderItem.price,
                      variantPrice,
                      partialsPrice,
                      removedPartialsDiscount,
                      quantity: orderItem.quantity,
                    });
                    return { orderItemId: orderItem.id, price: productTotalPrice };
                  });
                  const campaignPrice = campaignOrderItemsPrices.reduce((totalPrice, orderItem) => {
                    return totalPrice + orderItem.price;
                  }, 0);
                  const mappedPaidProducts = paidOrderItems.map((orderItem) => {
                    const { id, name: productName, variants, partials, isCustomized } = orderItem;
                    const variantsText = variants?.[0]?.name || '';
                    const campaignProductName = getCampaignProductName({ productName, variantsText });
                    const orderItemPrice = campaignOrderItemsPrices.find((x) => x.orderItemId === orderItem.id);
                    const paidProductPartials = (partials || []).filter((p) => !p.isFreeCampaignProduct);
                    const mappedPaidProductPartials = orderBy(paidProductPartials, ['price'], ['asc']).map(
                      (partial) => ({
                        id: partial.partialProductId,
                        name: partial.name,
                        price: partial.price,
                        quantity: partial.quantity,
                      })
                    );
                    return {
                      id,
                      name: campaignProductName,
                      price: orderItemPrice?.price || 0,
                      partials: mappedPaidProductPartials,
                      isCustomized,
                    };
                  });
                  const mappedFreeProducts = freeOrderItems
                    .filter(({ productId }) => {
                      const { categories: productCategories } = (products || []).find((p) => p.id === productId) || {};
                      return productCategories && productCategories.every((c) => !c.isComponent);
                    })
                    .map(({ id, name: productName, variants }) => {
                      const variantName = variants?.[0]?.name;
                      return { id, name: `${variantName || ''} ${productName}`.trim() };
                    });
                  const mappedFreePartials = freeOrderItemPartials.map(({ id, name: productName }) => {
                    return { id, name: productName };
                  });
                  return (
                    <CampaignOrderItem
                      key={index}
                      campaignName={campaignName}
                      paidProducts={mappedPaidProducts}
                      freeProducts={mappedFreeProducts}
                      freePartials={mappedFreePartials}
                      price={campaignPrice * quantity}
                      quantity={quantity}
                    />
                  );
                })}
              </IonList>
            )}
            <IonList className={classes.list}>
              {productOrderItems.map(({ name, price, quantity, partials, variants, isCustomized }, index) => {
                const variantsText = variants.map((variant) => variant.name).join(', ');
                let paidPartials = partials;
                let freePartials = [];
                if (isCustomized) {
                  paidPartials = partials.filter((p) => p.price > 0);
                  freePartials = partials.filter((p) => p.price === 0);
                }
                const variantPrice = (variants || []).find((v) => v.price > 0)?.price || 0;
                const partialsPrice = getPartialsPrice(paidPartials);
                const removedPartialsDiscount = getRemovedPartialsDiscount(partials);
                const productTotalPrice = getTotalPrice({
                  productPrice: price,
                  variantPrice,
                  partialsPrice,
                  removedPartialsDiscount,
                  quantity,
                });
                return (
                  <OrderItem
                    key={index}
                    productName={name}
                    variantsText={variantsText}
                    partials={mapOrderPartials(paidPartials)}
                    freePartials={mapOrderPartials(freePartials)}
                    price={productTotalPrice}
                    quantity={quantity}
                  />
                );
              })}
            </IonList>
          </div>
          <div className={classNames(classes.footer, classes.detailsSection)}>
            <h3 className={classNames(textClasses.titleBold, classes.footerText)}>{t('Total')}</h3>
            <IonText color="primary" className={classNames(textClasses.titleBold, classes.footerPrice)}>
              {formatPrice(orderDetails.totalPrice)}
            </IonText>
          </div>
        </div>
      )}
    </DetailsLayout>
  );
};

export default ProfileOrderDetailsPage;
