import { useTranslation } from 'react-i18next';
import { isAfter } from 'date-fns';
import { RouteComponentProps } from 'react-router-dom';

import { IonButton, IonIcon, IonText } from '@ionic/react';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'src/store';
import { setPaymentInvoked } from 'src/slices/cart';
import cartIcon from 'src/assets/img/cart.svg';
import classes from './CartFooter.module.scss';
import textClasses from 'src/styles/text.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';
import { useCartPriceGetter } from 'src/hooks';

interface CartFooterProps extends RouteComponentProps {}

const CartFooter: React.FC<CartFooterProps> = ({ history }) => {
  const { t } = useTranslation();
  const { total } = useCartPriceGetter();
  const { minDeadline, maxDeadline } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const minDate = minDeadline ? new Date(minDeadline) : new Date();
  const maxDate = maxDeadline ? new Date(maxDeadline) : new Date();
  const isMinDateAfterEndDate = isAfter(minDate, maxDate);

  const onPaymentButtonClick = async () => {
    if (!isMinDateAfterEndDate) {
      await dispatch(setPaymentInvoked(true, total));
      history.push('/tabs/cart/payment');
    }
  };

  return (
    <div className={classes.container}>
      {isMinDateAfterEndDate && (
        <p className="ion-text-center ion-margin-vertical">
          <IonText color="primary">
            {t('Too many products. Can not be prepared during sale point opening hours.')}
          </IonText>
        </p>
      )}
      <IonButton
        expand="block"
        className={classNames(btnClasses.primaryBtn, classes.confirmBtn, textClasses.title)}
        disabled={isMinDateAfterEndDate}
        onClick={onPaymentButtonClick}
      >
        <IonIcon src={cartIcon} slot="start" className={classes.confirmBtnIcon} />
        <IonText className={classes.confirmBtnText}>{t('Payment')}</IonText>
      </IonButton>
    </div>
  );
};

export default CartFooter;
