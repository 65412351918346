import { Helmet } from 'react-helmet-async';

export interface MetaHeadProps {
  title?: string;
  description?: string;
}

const MetaHead: React.FC<MetaHeadProps> = ({ title = 'Pizzakiosk App', description = 'Pizzakiosk App' }) => {
  return (
    <Helmet>
      {Boolean(title) && <title>{title}</title>}
      {Boolean(description) && <meta name="description" content={description} />}
    </Helmet>
  );
};

export default MetaHead;
