import { useState } from 'react';
import { IonButton, IonIcon, IonItem, IonList, IonPopover, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { checkmarkOutline } from 'ionicons/icons';
import classNames from 'classnames';

import { selectLanguage } from 'src/slices/system';
import { useDispatch, useSelector } from 'src/store';
import classes from './LanguagePicker.module.scss';
import textClasses from 'src/styles/text.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';
import etFlag from 'src/assets/img/flag-icon-et.svg';
import ltFlag from 'src/assets/img/flag-icon-lt.svg';
import lvFlag from 'src/assets/img/flag-icon-lv.svg';
import enFlag from 'src/assets/img/flag-icon-en.svg';
import ruFlag from 'src/assets/img/flag-icon-ru.svg';
import fiFlag from 'src/assets/img/flag-icon-fi.svg';
import seFlag from 'src/assets/img/flag-icon-se.svg';

const LanguagePicker: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const { languages, selectedLanguageId } = useSelector((state) => state.system);
  const { isoName: selectedLanguageCode } = languages
    ? languages.find((lang) => lang.id === selectedLanguageId) || {}
    : { isoName: 'et' };
  const flags = {
    et: etFlag,
    lt: ltFlag,
    lv: lvFlag,
    en: enFlag,
    ru: ruFlag,
    fi: fiFlag,
    se: seFlag,
  };

  const onPickUpDateClick = (e: any) => {
    e.persist();
    setShowPopover({ showPopover: true, event: e });
  };

  const onPopoverItemClick = async (languageId: number) => {
    dispatch(selectLanguage({ languageId, saveSelection: true }));
    setShowPopover({ showPopover: false, event: undefined });
  };

  return (
    <div>
      <IonButton
        color="white"
        shape="round"
        className={classNames(btnClasses.controlBtn, classes.pickerBtn)}
        onClick={onPickUpDateClick}
      >
        <IonIcon slot="icon-only" src={flags[selectedLanguageCode]} className={classes.pickerBtnIcon} />
      </IonButton>

      <IonPopover
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
      >
        <IonList>
          <h4 className={classNames(textClasses.textLBold, classes.popoverTitle)}>{t('Pick a language')}</h4>
          {languages &&
            languages.map((language, index, arr) => {
              const isLast = index + 1 === arr.length;
              const isSelected = language.id === selectedLanguageId;
              return (
                <IonItem
                  key={language.id}
                  button
                  lines={isLast ? 'none' : 'full'}
                  color={isSelected ? 'grey' : ''}
                  onClick={() => onPopoverItemClick(language.id)}
                  detail={false}
                >
                  <div slot="start" className={classes.pickerItemContainer}>
                    <IonIcon src={flags[language.isoName]} className={classes.pickerItemFlagIcon} />
                    <IonText
                      className={classNames(
                        { [textClasses.textM]: !isSelected },
                        { [textClasses.textMBold]: isSelected },
                        classes.pickerItemText,
                        'ion-text-capitalize',
                        'ion-ma'
                      )}
                    >
                      {language.name}
                    </IonText>
                  </div>
                  {isSelected && (
                    <IonIcon
                      slot="end"
                      icon={checkmarkOutline}
                      color="success"
                      className={classes.pickerItemSelectedIcon}
                    />
                  )}
                </IonItem>
              );
            })}
        </IonList>
      </IonPopover>
    </div>
  );
};

export default LanguagePicker;
