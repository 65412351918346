import React from 'react';
import { useTranslation } from 'react-i18next';
import { IonGrid, IonList, IonRow } from '@ionic/react';

import CampaignProductItem from 'src/components/campaign/CampaignProductItem';
import Image from 'src/components/Image';
import { useBreakpoint, useSelectedCampaignGetter } from 'src/hooks';
import { useDispatch, useSelector } from 'src/store';
import classes from './ComboCampaign.module.scss';
import textClasses from 'src/styles/text.module.scss';
import {
  SelectedCampaignProduct,
  ComboCampaignModalViews,
  openComboCampaignProducts,
  setComboCampaignModalView,
  setSlotParameters,
  openComboCampaignProductComponents,
} from 'src/slices/campaign';
import { selectProduct, setProductComponents, setProductExtraComponents } from 'src/slices/product';

const ComboCampaign: React.FC = () => {
  const campaign = useSelectedCampaignGetter();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isDesktopView, isMobileView } = useBreakpoint();
  const products = useSelector((state) => state.product.products);
  const { selectedSlotProducts } = useSelector((state) => state.campaign);
  const { selectedLanguageId } = useSelector((state) => state.system);

  if (!campaign) {
    return <p>{t('Campaign data was not found')}</p>;
  }

  const { name: campaignName = '' } = campaign || {};
  const paidProducts = selectedSlotProducts.filter((sp) => sp.isFreeSlot === false);
  const freeProducts = selectedSlotProducts.filter((sp) => sp.isFreeSlot === true);
  const imageType = isDesktopView ? 'desktop' : 'mobile';
  const image = (campaign.images || []).find((i) => i.imageType === imageType && i.languageId === selectedLanguageId);

  const onSlotEdit = (isFreeSlot: boolean, slotNumber: number) => {
    dispatch(setSlotParameters({ isFreeSlot, slotNumber }));

    if (isDesktopView) {
      dispatch(setComboCampaignModalView(ComboCampaignModalViews.PRODUCT_LIST));
    }

    if (isMobileView) {
      dispatch(openComboCampaignProducts(true));
    }
  };

  const onProductEdit = async (selectedProduct: SelectedCampaignProduct) => {
    const { productId, partials = [], isFreeSlot, slotNumber } = selectedProduct;
    const components = partials.filter((p) => !p.isExtraComponent);
    const extraComponents = partials.filter((p) => p.isExtraComponent);
    await dispatch(setSlotParameters({ isFreeSlot, slotNumber }));
    await dispatch(selectProduct(productId));
    await dispatch(
      setProductComponents(
        components.map((partial) => ({
          productId,
          componentId: partial.partialProductId,
          qty: partial.quantity > 0 ? partial.quantity + 1 : 0,
        }))
      )
    );
    await dispatch(
      setProductExtraComponents(
        extraComponents.map((partial) => ({
          productId,
          componentId: partial.partialProductId,
          qty: partial.quantity,
        }))
      )
    );

    if (isDesktopView) {
      dispatch(setComboCampaignModalView(ComboCampaignModalViews.EDIT_COMPONENTS));
    }

    if (isMobileView) {
      dispatch(openComboCampaignProductComponents(true));
    }
  };

  return (
    <div className={classes.content}>
      <h1 className={textClasses.headerLBold}>{campaignName}</h1>
      <Image src={image?.imageFullUrl} className={classes.campaignImg} />
      <h2 className={textClasses.titleBold}>{t('Choose paid products')}</h2>
      <IonList>
        <IonGrid className="ion-no-padding">
          <IonRow>
            {paidProducts.map((paidProduct) => {
              const product = products.find((p) => p.id === paidProduct.productId);

              return (
                <CampaignProductItem
                  key={`paid${paidProduct.slotNumber}`}
                  campaignProduct={paidProduct}
                  product={product}
                  isFreeSlot={false}
                  onSlotEdit={onSlotEdit}
                  onProductEdit={onProductEdit}
                  showEditButtons
                />
              );
            })}
          </IonRow>
        </IonGrid>
      </IonList>

      <h2 className={textClasses.titleBold}>{t('Choose free products')}</h2>
      <IonList>
        <IonGrid className="ion-no-padding">
          <IonRow>
            {freeProducts.map((freeProduct) => {
              const product = products.find((p) => p.id === freeProduct.productId);

              return (
                <CampaignProductItem
                  key={`free${freeProduct.slotNumber}`}
                  campaignProduct={freeProduct}
                  product={product}
                  isFreeSlot
                  onSlotEdit={onSlotEdit}
                  showEditButtons
                />
              );
            })}
          </IonRow>
        </IonGrid>
      </IonList>
    </div>
  );
};

export default ComboCampaign;
