import classNames from 'classnames';

import SalePointPickerButton from 'src/components/SalePointPickerButton';
import SignInButton from 'src/components/SignInButton';
import VIPButton from 'src/components/VIPButton';
import { useSelector } from 'src/store';
import { MOBILE_VIEW } from 'src/constants';
import classes from './MobileHeader.module.scss';

const MobileMenuHeader: React.FC = () => {
  const { selectedSalePointId, salePoints } = useSelector((state) => state.salePoint);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <div className={classNames(MOBILE_VIEW, classes.header)}>
      <div className={classNames(classes.headerLeft, { [classes.headerEmpty]: !selectedSalePointId })}>
        {salePoints?.length > 0 && <SalePointPickerButton />}
      </div>
      <div className={classes.headerRight}>
        {isAuthenticated && <VIPButton />}
        {!isAuthenticated && <SignInButton />}
      </div>
    </div>
  );
};

export default MobileMenuHeader;
