import React from 'react';
import { Capacitor } from '@capacitor/core';

import classes from './IosSafeAreaTop.module.scss';

const IosSafeAreaTop = () => {
  const platform = Capacitor.getPlatform();
  const isNative = Capacitor.isNativePlatform();

  return (
    <>
      {isNative && platform === 'ios' && (
        <div>
          <div className={classes.iosSafeAreaOffset} />
          <div className={classes.iosSafeAreaPlaceholder} />
        </div>
      )}
    </>
  );
};

export default IosSafeAreaTop;
