import { IonButton, IonIcon, IonImg, IonText } from '@ionic/react';
import { Capacitor } from '@capacitor/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { APP_STORE_LINK, DESKTOP_VIEW, FACEBOOK_LINK, GOOGLE_PLAY_LINK, INSTAGRAM_LINK } from 'src/constants';
import btnClasses from 'src/styles/buttons.module.scss';
import classes from './DesktopBottomNavigation.module.scss';
import facebookIcon from 'src/assets/img/facebook-icon.svg';
import instagramIcon from 'src/assets/img/instagram-icon.svg';
import googlePlayBadge from 'src/assets/img/google-play-badge.svg';
import appStoreBadge from 'src/assets/img/app-store-badge.svg';
import { useOrderedStaticPagesGetter } from 'src/hooks';

interface DesktopBottomNavigationProps {
  footerClass?: string;
}

const DesktopBottomNavigation: React.FC<DesktopBottomNavigationProps> = ({ footerClass }) => {
  const orderedStaticPages = useOrderedStaticPagesGetter();
  const { t } = useTranslation();

  return (
    <div className={classNames(DESKTOP_VIEW, classes.footer, footerClass)}>
      <IonButton color="white" shape="round" className={classes.socialIconButton} href={FACEBOOK_LINK} target="_blank">
        <IonIcon slot="icon-only" src={facebookIcon} className={classes.socialIcon} />
      </IonButton>
      <IonButton color="white" shape="round" className={classes.socialIconButton} href={INSTAGRAM_LINK} target="_blank">
        <IonIcon slot="icon-only" src={instagramIcon} className={classes.socialIcon} />
      </IonButton>
      {!Capacitor.isNativePlatform() && (
        <div>
          <IonButton color="white" className={classes.googleIosBadges} href={GOOGLE_PLAY_LINK} target="_blank">
            <IonImg src={googlePlayBadge} />
          </IonButton>
          <IonButton color="white" className={classes.googleIosBadges} href={APP_STORE_LINK} target="_blank">
            <IonImg src={appStoreBadge} />
          </IonButton>
        </div>
      )}
      <div className={classes.footerLinks}>
        {orderedStaticPages.map((staticPage) => (
          <IonButton
            key={staticPage.id}
            color="white"
            shape="round"
            className={btnClasses.linkBtn}
            routerLink={`/tabs/contact/${staticPage.slug}`}
            routerDirection="root"
          >
            <IonText color={staticPage.slug === 'allergens' ? 'primary' : null}>{staticPage.name}</IonText>
          </IonButton>
        ))}
        <IonButton
          color="white"
          shape="round"
          className={btnClasses.linkBtn}
          routerLink="/tabs/contact"
          routerDirection="root"
        >
          <IonText>{t('Contact')}</IonText>
        </IonButton>
      </div>
    </div>
  );
};

export default DesktopBottomNavigation;
