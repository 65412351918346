import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IonButton, IonText } from '@ionic/react';
import classNames from 'classnames';
import toast from 'react-hot-toast';

import { useDispatch, useSelector } from 'src/store';
import { openModal as openAddVipModal, getVips } from 'src/slices/vip';
import { VIP_ORDERS_LIMIT } from 'src/constants';
import classes from './SaveOrderVIP.module.scss';
import textClasses from 'src/styles/text.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';

const SaveOrderVIP: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const vipOrders = useSelector((state) => state.vip.vips);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated && !vipOrders) {
      dispatch(getVips());
    }
  }, [vipOrders, isAuthenticated]);

  const onSaveBtnClick = () => {
    if (isAuthenticated && (!vipOrders || vipOrders?.length >= VIP_ORDERS_LIMIT)) {
      toast.error(t('You have reached limit for VIP orders!'));
      return;
    }

    dispatch(openAddVipModal(true));
  };

  return (
    <div className={classes.container}>
      <h2 className={textClasses.titleBold}>{t('Save VIP order')}</h2>
      <IonButton
        className={classNames(btnClasses.outlineBtn, classes.saveBtn)}
        color="white"
        shape="round"
        onClick={onSaveBtnClick}
      >
        <IonText className={classNames(classes.saveBtnText, textClasses.headerS)}>{t('Save')}</IonText>
      </IonButton>
    </div>
  );
};

export default SaveOrderVIP;
