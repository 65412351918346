import { useTranslation } from 'react-i18next';
import { IonButton, IonText } from '@ionic/react';
import classNames from 'classnames';

import btnClasses from 'src/styles/buttons.module.scss';
import textClasses from 'src/styles/text.module.scss';
import classes from './VIPButton.module.scss';

const VIPButton: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonButton
      color="white"
      shape="round"
      className={classNames(btnClasses.outlineBtn, classes.btn)}
      routerLink="/tabs/profile/vip"
    >
      <IonText color="primary" className={classNames(classes.btnText, textClasses.textMBold)}>
        {t('VIP')}
      </IonText>
    </IonButton>
  );
};

export default VIPButton;
