import { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'src/store';
import { getStaticPages } from 'src/slices/system';
import useBreakpoint from './useBreakpoint';

const useAppLanguageReload = () => {
  const selectedLanguageId = useSelector((state) => state.system.selectedLanguageId);
  const systemLanguages = useSelector((state) => state.system.languages);
  const selectedIsoName = systemLanguages?.find((language) => language.id === selectedLanguageId)?.isoName;
  const [prevLanguageId, setPrevLanguageId] = useState<number>(selectedLanguageId);
  const { isDesktopView } = useBreakpoint();
  const dispatch = useDispatch();

  useEffect(() => {
    if (prevLanguageId && prevLanguageId !== selectedLanguageId && isDesktopView) {
      dispatch(getStaticPages());
      setPrevLanguageId(selectedLanguageId);
    }
    if (!prevLanguageId && selectedLanguageId) setPrevLanguageId(selectedLanguageId);
  }, [selectedLanguageId, prevLanguageId, isDesktopView]);
  useEffect(() => {
    if (typeof window !== 'undefined' && selectedIsoName) {
      document.documentElement.lang = selectedIsoName;
    }
  }, [selectedIsoName]);
};

export default useAppLanguageReload;
