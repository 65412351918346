import { useState } from 'react';
import { IonAlert, IonButton, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useDispatch } from 'src/store';
import { logOut } from 'src/slices/auth';
import classes from './LogOutButton.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';

const LogOutButton: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showLogOutAlert, setShowLogOutAlert] = useState(false);

  const onLogoutBtnClick = () => {
    setShowLogOutAlert(true);
  };

  const onAlertDismiss = (e: CustomEvent) => {
    setShowLogOutAlert(false);
    if (e.detail.role === 'confirm') {
      dispatch(logOut());
    }
  };

  return (
    <>
      <IonButton
        color="primary"
        fill="outline"
        shape="round"
        className={classNames(btnClasses.linkBtn, classes.btn)}
        onClick={onLogoutBtnClick}
      >
        <IonText>{t('Log out')}</IonText>
      </IonButton>
      <IonAlert
        isOpen={showLogOutAlert}
        onDidDismiss={onAlertDismiss}
        header={t('Warning')}
        message={t('Do you really want to log out?')}
        buttons={[
          {
            text: t('Cancel'),
            role: 'cancel',
          },
          {
            text: t('Confirm'),
            role: 'confirm',
          },
        ]}
      />
    </>
  );
};

export default LogOutButton;
