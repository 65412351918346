import { IonSkeletonText } from '@ionic/react';
import classNames from 'classnames';

import classes from 'src/components/loaders/loaders.module.scss';
import { DESKTOP_VIEW, MOBILE_VIEW } from 'src/constants';
import LoadingOverlay from 'src/components/LoadingOverlay';

const MenuProductsLoader: React.FC = () => (
  <>
    <div className={classNames(DESKTOP_VIEW, classes.productsLoader)}>
      <LoadingOverlay backdrop={false} spinnerColor="primary" />
    </div>

    <div className={classNames(MOBILE_VIEW, classes.productsLoader)}>
      {[...Array(5)].map((_, index) => (
        <div key={index} className={classes.productsLoaderRow}>
          <IonSkeletonText animated className={classes.productsLoaderImage} />
          <div className={classes.productsLoaderTexts}>
            <IonSkeletonText animated className={classes.productsLoaderTitle} />
            <IonSkeletonText animated className={classes.productsLoaderDescription} />
            <IonSkeletonText animated className={classes.productsLoaderDescription} />
            <IonSkeletonText animated className={classes.productsLoaderDescription} />
            <IonSkeletonText animated className={classes.productsLoaderPrice} />
          </div>
        </div>
      ))}
    </div>
  </>
);

export default MenuProductsLoader;
