import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { IonButton, IonIcon, IonText } from '@ionic/react';
import classNames from 'classnames';
import toast from 'react-hot-toast';

import QtyPicker from 'src/components/QtyPicker';
import { useDispatch, useSelector } from 'src/store';
import { useBreakpoint, useSelectedCampaignGetter } from 'src/hooks';
import { openComboCampaign } from 'src/slices/campaign';
import { addCartItem } from 'src/slices/cart';
import { formatPrice } from 'src/utils';
import { getCampaignProductPrice } from 'src/utils/campaign';
import { PRODUCT_MAX_COUNT } from 'src/constants';
import classes from './ComboCampaignFooter.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';
import textClasses from 'src/styles/text.module.scss';
import cartIcon from 'src/assets/img/cart.svg';

const ComboCampaignFooter: React.FC = () => {
  const campaign = useSelectedCampaignGetter();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobileView, isDesktopView } = useBreakpoint();
  const [campaignQty, setCampaignQty] = useState<number>(1);
  const { selectedSalePointId } = useSelector((state) => state.salePoint);
  const selectedSlotProducts = useSelector((state) => state.campaign.selectedSlotProducts);
  const products = useSelector((state) => state.product.products);

  if (!campaign) return null;

  const { id: campaignId = 0 } = campaign || {};
  const paidProducts = selectedSlotProducts.filter((sp) => sp.isFreeSlot === false);
  const campaignPrice = paidProducts
    .map(({ productId, variantId, partials }) => getCampaignProductPrice({ productId, variantId, products, partials }))
    .reduce((totalPrice, price) => totalPrice + price, 0);
  const ionButtonProps = {} as { routerLink?: string };

  if (isMobileView) {
    ionButtonProps.routerLink = '/tabs/menu';
  }

  const onAddQty = () => {
    if (campaignQty >= PRODUCT_MAX_COUNT) return;
    setCampaignQty((prevQty) => prevQty + 1);
  };

  const onRemoveQty = () => {
    if (campaignQty <= 1) return;
    setCampaignQty((prevQty) => prevQty - 1);
  };

  const onAddToCartBtnClick = () => {
    if (!selectedSalePointId) {
      toast.error(t('Please select sale point'));
      return;
    }
    dispatch(
      addCartItem({
        campaignId,
        quantity: campaignQty,
        products: selectedSlotProducts,
        uniqId: uuidv4(),
        isCampaign: true,
      })
    );
    if (isDesktopView) {
      dispatch(openComboCampaign(false));
    }
    toast.success(t('Campaign added to cart'));
  };

  const priceText = (
    <IonText className={classNames(textClasses.textL, classes.addToCartPrice)}>
      {formatPrice(campaignPrice * campaignQty)}
    </IonText>
  );

  return (
    <div className={classes.stickyFooter}>
      <div className={classes.container}>
        <div className={classes.qtyPicker}>
          <QtyPicker qty={campaignQty} onAddQty={onAddQty} onRemoveQty={onRemoveQty} />
        </div>
        <IonButton
          className={classNames(btnClasses.primaryBtn, classes.addToCartBtn, classes.longBtn)}
          onClick={onAddToCartBtnClick}
          {...ionButtonProps}
        >
          <IonText className={classNames(textClasses.textL, classes.addToCartText)}>{t('Add to cart')}&nbsp;</IonText>
          {priceText}
        </IonButton>
        <IonButton
          className={classNames(btnClasses.primaryBtn, classes.addToCartBtn, classes.shortBtn)}
          onClick={onAddToCartBtnClick}
          {...ionButtonProps}
        >
          <IonIcon src={cartIcon} slot="start" className={classes.cartIcon} />
          <IonText className={classNames(textClasses.textL, classes.addToCartText)}>{t('Add')}&nbsp;</IonText>
          {priceText}
        </IonButton>
      </div>
    </div>
  );
};

export default ComboCampaignFooter;
