import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Formik, Form } from 'formik';
import { IonButton, IonText } from '@ionic/react';
import * as Yup from 'yup';

import FormInput from 'src/components/FormInput';
import LoadingOverlay from 'src/components/LoadingOverlay';
import { useDispatch, useSelector } from 'src/store';
import { createVip, openModal as openAddVipModal } from 'src/slices/vip';
import classes from './AddVip.module.scss';
import textClasses from 'src/styles/text.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';

interface FormValuesDto {
  name: string;
}

const AddVip = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { vipCreate: isLoading } = useSelector((state) => state.vip.loading);

  const onFormSubmit = async (values: FormValuesDto) => {
    await dispatch(createVip(values.name));
    await dispatch(openAddVipModal(false));
  };

  return (
    <div className={classes.content}>
      {isLoading && <LoadingOverlay backdrop spinnerColor="primary" />}
      <h2 className={classNames(textClasses.headerLBold, 'ion-text-center', classes.header)}>{t('VIP')}</h2>
      <h3 className={classNames(textClasses.titleBold, 'ion-margin-vertical')}>{t('Save order')}</h3>
      <Formik
        enableReinitialize
        initialValues={{ name: '' }}
        onSubmit={onFormSubmit}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required(t('This is mandatory field')),
        })}
      >
        {({ errors, handleBlur, setFieldValue, touched, values }): JSX.Element => (
          <Form>
            <FormInput
              value={values.name}
              name="name"
              placeholder={t('VIP Order name')}
              error={Boolean(touched.name && errors.name) && errors.name}
              onChange={(value) => setFieldValue('name', value)}
              onBlur={handleBlur}
              required
            />
            <IonButton className={btnClasses.primaryBtn} type="submit" expand="block">
              <IonText className={textClasses.title}>{t('Confirm')}</IonText>
            </IonButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddVip;
