import { IonButton, IonContent, IonIcon, IonPage, IonText } from '@ionic/react';
import { wifiOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'src/store';
import { setInternetConnection } from 'src/slices/system';
import { checkInternetConnection } from 'src/utils';
import logo from 'src/assets/img/pizzakiosk-logo.svg';
import pizzaIcon from 'src/assets/img/pizza-slice.svg';
import deliveringImg from 'src/assets/img/pizza-delivering.svg';
import classes from 'src/pages/NoConnectionPage.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';
import textClasses from 'src/styles/text.module.scss';

const NoConnectionPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hasInternetConnection } = useSelector((state) => state.system);

  const onCheckBtnClick = async () => {
    const isConnected = await checkInternetConnection();
    dispatch(setInternetConnection(isConnected));
  };

  const onReturnBtnClick = async () => {
    const isConnected = await checkInternetConnection();
    dispatch(setInternetConnection(isConnected));
  };

  return (
    <IonPage>
      <IonContent>
        <div className={classes.content}>
          <IonIcon src={logo} className={classes.logo} color="primary" />
          <div className={classNames(textClasses.title, 'ion-text-center')}>
            <p className={textClasses.title}>{t('No internet connection')}.</p>
            <p className={textClasses.title}>{t('Turn on WiFi or 3G')}.</p>
          </div>
          <IonIcon src={deliveringImg} className={classes.deliveringImg} color="primary" />
          {!hasInternetConnection && (
            <IonButton
              className={classNames(btnClasses.outlineBtn, classes.btn)}
              color="primary"
              shape="round"
              onClick={onCheckBtnClick}
            >
              <IonIcon slot="start" src={wifiOutline} color="white" className={classes.btnIcon} />
              <IonText className={classNames(classes.btnText, textClasses.headerS)}>
                {t('Check internet connection')}
              </IonText>
            </IonButton>
          )}
          {hasInternetConnection && (
            <IonButton
              className={classNames(btnClasses.outlineBtn, classes.btn)}
              color="primary"
              shape="round"
              onClick={onReturnBtnClick}
            >
              <IonIcon slot="start" src={pizzaIcon} color="white" className={classes.btnIcon} />
              <IonText className={classNames(classes.btnText, textClasses.headerS)}>{t('Return to menu')}</IonText>
            </IonButton>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NoConnectionPage;
