import { useEffect, useRef, useState } from 'react';
import { IonButton, IonDatetime, IonIcon, IonItem, IonList, IonPopover, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { head, last } from 'lodash';
import { checkmarkOutline, chevronDown } from 'ionicons/icons';
import {
  add,
  format,
  getDate,
  getISODay,
  getHours,
  getMinutes,
  isAfter,
  isToday,
  set,
  isWithinInterval,
} from 'date-fns';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import toast from 'react-hot-toast';

import ClosedSalePointWarning from 'src/components/ClosedSalePointWarning';
import { formatDate, formatTime, toSQLDate } from 'src/utils/time';
import {
  getDeadline,
  setDeadline,
  setShowClosedSalePointWarning,
  setShowSalePointNotAvailableWarning,
  setShowTooLongCheckoutTimeWarning,
  validateDeadline,
} from 'src/slices/cart';
import { useDispatch, useSelector } from 'src/store';
import { useBreakpoint, useSelectedSalePointGetter } from 'src/hooks';
import classes from './PickUpTimePicker.module.scss';
import 'src/components/cart/PickUpTimePicker.scss';
import textClasses from 'src/styles/text.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';
import { getTimeDate } from 'src/utils/salepoint';

interface PickUpTimePickerProps extends RouteComponentProps {}

const dayFormat = (date: Date) => {
  return formatDate(date, 'eeee dd.MM');
};

const mobileDeadlinePickerFormat = (date: Date) => {
  return format(date, 'HH:mm');
};

const PickUpTimePicker: React.FC<PickUpTimePickerProps> = ({ location }) => {
  const { t } = useTranslation();
  const dateTimePickerRef = useRef(null);
  const dispatch = useDispatch();
  const { isDesktopView, isMobileView } = useBreakpoint();
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [timePopoverState, setShowTimePopover] = useState({ showPopover: false, event: undefined });
  const {
    deadline,
    minDeadline,
    maxDeadline,
    showClosedSalePointWarning,
    showSalePointNotAvailableWarning,
    showTooLongCheckoutTimeWarning,
  } = useSelector((state) => state.cart);
  const { salePointWorkingDays, salePointAvailableTimes } = useSelector((state) => state.salePoint);
  const { minCheckoutTime, defaultMinCheckoutTime, isResetCheckoutTime } = useSelectedSalePointGetter();

  const currentDate = new Date();
  const pickupDate = deadline ? new Date(deadline) : new Date();
  let minDate = minDeadline ? new Date(minDeadline) : new Date();
  const maxDate = maxDeadline ? new Date(maxDeadline) : new Date();
  const isMinDateAfterEndDate = isAfter(minDate, maxDate);

  if (isMinDateAfterEndDate) {
    minDate = maxDate;
  }

  useEffect(() => {
    const setup = async () => {
      if (location?.pathname !== '/tabs/cart') {
        return;
      }

      let warningMessage;
      if (showClosedSalePointWarning) {
        await dispatch(setShowClosedSalePointWarning(false)); // nosonar
        warningMessage = t('Selected sale point is closed at the moment and only available for preorders!');
      }

      if (showSalePointNotAvailableWarning) {
        await dispatch(setShowSalePointNotAvailableWarning(false)); // nosonar
        warningMessage = t('Selected sale point is closed and not available for orders!');
      }

      if (showTooLongCheckoutTimeWarning) {
        await dispatch(setShowTooLongCheckoutTimeWarning(false)); // nosonar
        warningMessage = t(
          'Unfortunately the amount of pizzas you chose can not be prepared before closing time.' +
            'Lower the amount or choose another date/time.'
        );
      }

      if (warningMessage) {
        toast((tst) => <ClosedSalePointWarning tst={tst} title={warningMessage} />, {
          duration: Infinity,
          position: isMobileView ? 'bottom-center' : 'top-center',
        });
      }
    };

    setup();
  }, [
    showClosedSalePointWarning,
    showSalePointNotAvailableWarning,
    showTooLongCheckoutTimeWarning,
    location?.pathname,
  ]);

  const onPickUpDateClick = (e: any) => {
    e.persist();
    setShowPopover({ showPopover: true, event: e });
  };

  const onPickUpTimeClick = (e: any) => {
    if (isMobileView && dateTimePickerRef) {
      dateTimePickerRef?.current?.open();
    }

    if (isDesktopView) {
      e.persist();
      setShowTimePopover({ showPopover: true, event: e });
    }
  };

  const onDatePickerValueChange = async (e: CustomEvent) => {
    const newValue = e?.detail?.value;
    const deadlineStore = (await dispatch(getDeadline())) as unknown as string;

    if (!newValue || !deadlineStore || newValue === mobileDeadlinePickerFormat(new Date(deadlineStore))) {
      return;
    }

    const hours = head(newValue?.split?.(':')) as string;
    const minutes = last(newValue?.split?.(':')) as string;
    const isMatchingHours = !!hours.match(/^([0-1]?\d|2[0-3])$/g);
    const isMatchingMinutes = !!minutes.match(/^[0-5]\d$/g);
    if (isMatchingHours && isMatchingMinutes) {
      const newDate = set(new Date(deadlineStore), { hours: Number(hours), minutes: Number(minutes) });
      dispatch(setDeadline(newDate.toISOString()));
    }
  };

  const onDatePopoverItemClick = async (date: Date) => {
    if (!pickupDate) return;

    const newDeadline = set(date, { hours: pickupDate.getHours(), minutes: pickupDate.getMinutes() });
    dispatch(validateDeadline({ newDate: newDeadline, showWarningIfClosed: false }));
    setShowPopover({ showPopover: false, event: undefined });
  };

  const onTimeHoursPopoverItemClick = async (hours: number) => {
    if (!pickupDate) return;

    const minHour = getHours(minDate);
    const maxHour = getHours(maxDate);
    let newHours = hours;
    let newMinutes = getMinutes(pickupDate);

    if (hours < minHour || hours > maxHour) {
      newHours = getHours(pickupDate);
    }

    if (hours === minHour) {
      const minMinutes = getMinutes(minDate);
      newMinutes = Math.ceil(Math.max(newMinutes, minMinutes) / 5) * 5; // In case if minMinutes is not divided by 5.
    }

    if (hours === maxHour) {
      const maxMinutes = getMinutes(maxDate);
      newMinutes = Math.floor(Math.min(newMinutes, maxMinutes) / 5) * 5;
    }

    const newDeadline = set(pickupDate, { hours: newHours, minutes: newMinutes });
    dispatch(validateDeadline({ newDate: newDeadline, showWarningIfClosed: false }));
  };

  const onTimeMinutesPopoverItemClick = async (minutes: number) => {
    if (!pickupDate) return;

    let newMinutes = minutes;
    const isInRange = isWithinInterval(set(pickupDate, { minutes: newMinutes }), { start: minDate, end: maxDate });

    if (!isInRange) {
      newMinutes = getMinutes(pickupDate);
    }
    const newDeadline = set(pickupDate, { minutes: newMinutes });
    dispatch(validateDeadline({ newDate: newDeadline, showWarningIfClosed: false }));
  };

  return (
    <div className={classes.container}>
      <h2 className={classNames(textClasses.titleBold, 'ion-margin-bottom')}>{t('Pick up time')}</h2>
      {isMinDateAfterEndDate && (
        <IonText color="primary">
          {t('Too many products. Can not be prepared during sale point opening hours.')}
        </IonText>
      )}
      <div className={classes.buttons}>
        <IonButton
          color="white"
          shape="round"
          className={classNames(btnClasses.outlineBtn, classes.btn)}
          onClick={onPickUpDateClick}
          disabled={!deadline || !maxDeadline || !minDeadline}
          style={{ minWidth: '190px' }}
        >
          <IonText slot="start" className={classNames('ion-text-capitalize', textClasses.textMBold, classes.btnText)}>
            {deadline ? dayFormat(pickupDate) : '-'}
          </IonText>
          <IonIcon slot="end" icon={chevronDown} />
        </IonButton>

        <IonButton
          color="white"
          shape="round"
          className={classNames(btnClasses.outlineBtn, classes.btn)}
          onClick={onPickUpTimeClick}
          disabled={!deadline || !maxDeadline || !minDeadline || isMinDateAfterEndDate}
          style={{ minWidth: '110px' }}
        >
          <IonText slot="start" className={classNames(textClasses.textMBold, classes.btnText)}>
            {deadline ? formatTime(pickupDate) : '-'}
          </IonText>
          <IonIcon slot="end" icon={chevronDown} />
        </IonButton>
      </div>

      {deadline && maxDeadline && minDeadline && (
        <IonPopover
          event={popoverState.event}
          isOpen={popoverState.showPopover}
          onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
        >
          <IonList>
            <h4 className={classNames(textClasses.textLBold, classes.popoverTitle)}>{t('Pick a date')}</h4>
            {[...Array(8)].map((_, index, arr) => {
              const checkoutTime =
                isResetCheckoutTime && defaultMinCheckoutTime && !isToday(pickupDate)
                  ? defaultMinCheckoutTime
                  : minCheckoutTime;
              const dayDate = add(currentDate, { days: index, minutes: checkoutTime });
              const weekDay = getISODay(dayDate);
              const availableTimesData = (salePointAvailableTimes || []).find((at) => at.date === toSQLDate(dayDate));
              const workingDaysData = (salePointWorkingDays || []).find((wd) => wd.isoWeekDate === weekDay);
              const openingsHoursFallBack = { start: null, end: null };
              const { start, end } = availableTimesData || workingDaysData || openingsHoursFallBack;
              const endTimeDate = end ? getTimeDate(dayDate, end) : null;
              const isLast = index + 1 === arr.length;
              const isSelected = getDate(dayDate) === getDate(pickupDate);
              const isClosed = !start || !end || (isToday(dayDate) && isAfter(dayDate, endTimeDate));
              return (
                <IonItem
                  key={index}
                  button
                  lines={isLast ? 'none' : 'full'}
                  color={isSelected ? 'grey' : ''}
                  onClick={() => onDatePopoverItemClick(dayDate)}
                  detail={false}
                  disabled={isClosed}
                >
                  <IonText
                    className={classNames(
                      { [textClasses.textM]: !isSelected },
                      { [textClasses.textMBold]: isSelected },
                      'ion-text-capitalize'
                    )}
                  >
                    {dayFormat(dayDate)}
                  </IonText>
                  {isSelected && <IonIcon slot="end" icon={checkmarkOutline} color="success" />}
                  {isClosed && <IonText slot="end">({t('Closed')})</IonText>}
                </IonItem>
              );
            })}
          </IonList>
        </IonPopover>
      )}

      {isDesktopView && deadline && maxDeadline && minDeadline && (
        <IonPopover
          event={timePopoverState.event}
          isOpen={timePopoverState.showPopover}
          onDidDismiss={() => setShowTimePopover({ showPopover: false, event: undefined })}
          cssClass="pickUpTimePopover"
        >
          <div className="ion-text-center">
            <h4 className={classNames(textClasses.textLBold, classes.popoverTitle)}>{t('Pick a time')}</h4>
            <div className={textClasses.titleBold}>{formatTime(new Date(deadline))}</div>
          </div>
          <div className={classes.timePopoverLabels}>
            <h4 className={classNames(textClasses.textS, 'ion-text-center')}>{t('Hours')}</h4>
            <h4 className={classNames(textClasses.textS, 'ion-text-center')}>{t('Minutes')}</h4>
          </div>
          <div className={classes.timePopoverContainer}>
            <IonList className={classes.timePopoverList}>
              {[...Array(24)].map((_, index, arr) => {
                const hours = index;
                const formattedHours = hours < 10 ? `0${hours}` : hours;
                const isLast = index + 1 === arr.length;
                const deadlineHours = getHours(pickupDate);
                const isSelected = deadlineHours === hours;
                const isInRange =
                  (getHours(minDate) < hours || (getHours(minDate) === hours && getMinutes(minDate) <= 55)) &&
                  getHours(maxDate) >= hours;

                if (!isInRange) return null;

                return (
                  <IonItem
                    key={index}
                    button
                    lines={isLast ? 'none' : 'full'}
                    color={isSelected ? 'grey' : ''}
                    onClick={() => onTimeHoursPopoverItemClick(hours)}
                    detail={false}
                    className={classes.timePopoverItem}
                  >
                    <IonText
                      className={classNames(
                        { [textClasses.textM]: !isSelected },
                        { [textClasses.textMBold]: isSelected },
                        'ion-text-capitalize'
                      )}
                    >
                      {formattedHours}
                    </IonText>
                  </IonItem>
                );
              })}
            </IonList>
            <IonList className={classes.timePopoverList}>
              {[...Array(12)].map((_, index, arr) => {
                const minutes = index * 5;
                const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
                const isLast = index + 1 === arr.length;
                const deadlineMinutes = getMinutes(pickupDate);
                const isSelected = deadlineMinutes - (deadlineMinutes % 5) === minutes;
                const isInRange = isWithinInterval(set(pickupDate, { minutes }), { start: minDate, end: maxDate });

                if (!isInRange) return null;

                return (
                  <IonItem
                    key={index}
                    button
                    lines={isLast ? 'none' : 'full'}
                    color={isSelected ? 'grey' : ''}
                    onClick={() => onTimeMinutesPopoverItemClick(minutes)}
                    detail={false}
                    className={classes.timePopoverItem}
                  >
                    <IonText
                      className={classNames(
                        { [textClasses.textM]: !isSelected },
                        { [textClasses.textMBold]: isSelected },
                        'ion-text-capitalize'
                      )}
                    >
                      {formattedMinutes}
                    </IonText>
                  </IonItem>
                );
              })}
            </IonList>
          </div>
        </IonPopover>
      )}

      {isMobileView && deadline && maxDeadline && minDeadline && (
        <IonDatetime
          value={mobileDeadlinePickerFormat(new Date(deadline))}
          min={mobileDeadlinePickerFormat(new Date(minDeadline))}
          max={mobileDeadlinePickerFormat(new Date(maxDeadline))}
          onIonChange={onDatePickerValueChange}
          displayFormat="HH:mm"
          pickerFormat="HH:mm"
          cancelText={t('Cancel')}
          doneText={t('Confirm')}
          style={{ display: 'none' }}
          minuteValues="0,5,10,15,20,25,30,35,40,45,50,55"
          ref={dateTimePickerRef}
          disabled={isMinDateAfterEndDate}
        />
      )}
    </div>
  );
};

export default PickUpTimePicker;
