import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import LogOutButton from 'src/components/profile/LogOutButton';
import LanguagePicker from 'src/components/LanguagePicker';
import BonusPoints from 'src/components/BonusPoints';
import { useSelector } from 'src/store';
import { useBreakpoint } from 'src/hooks';
import { DESKTOP_VIEW } from 'src/constants';
import classes from './Header.module.scss';
import textClasses from 'src/styles/text.module.scss';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const { isMobileView, isDesktopView } = useBreakpoint();
  const { firstName } = useSelector((state) => state.auth.user) || {};

  return (
    <>
      <div className={classes.header}>
        <div className={classNames(classes.headerRow, classes.headerMargin)}>
          <h1 className={classNames(textClasses.headerLBold, classes.headerTitle)}>{`${t('Hello')}, ${firstName}!`}</h1>
          {isDesktopView && <LogOutButton />}
          {isMobileView && <LanguagePicker />}
        </div>
        <div className={classes.headerMargin}>
          <BonusPoints />
        </div>
      </div>
      <div className={classNames(DESKTOP_VIEW, classes.divider)} />
    </>
  );
};

export default Header;
