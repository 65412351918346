import { ReactNode, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonContent, IonPage, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import classNames from 'classnames';

import MetaHead, { MetaHeadProps } from 'src/components/MetaHead';
import DesktopTopNavigation from 'src/components/layout/DesktopTopNavigation';
import DesktopBottomNavigation from 'src/components/layout/DesktopBottomNavigation';
import MobileHeader from 'src/components/layout/MobileHeader';
import IosSafeAreaTop from 'src/components/IosSafeAreaTop';
import IosSafeAreaBottom from 'src/components/IosSafeAreaBottom';
import { DESKTOP_VIEW } from 'src/constants';
import { useDispatch } from 'src/store';
import { setBottomNavigationDisplay } from 'src/slices/system';
import classes from './DetailsLayout.module.scss';
import textClasses from 'src/styles/text.module.scss';

interface DetailsLayoutProps extends RouteComponentProps {
  children?: ReactNode;
  meta?: MetaHeadProps;
  headerTitle?: string;
  contentHeader?: React.ReactNode;
  footer?: React.ReactNode;
  contentHeaderTitle?: string;
  sidePadding?: boolean;
  centerContent?: boolean;
  hideMobileHeader?: boolean;
  scrollToTop?: number;
}

const DetailsLayout: React.FC<DetailsLayoutProps> = ({
  children,
  meta,
  headerTitle,
  contentHeader,
  footer,
  contentHeaderTitle,
  sidePadding = true,
  hideMobileHeader = false,
  centerContent = false,
  scrollToTop = 0,
  ...routeProps
}) => {
  const dispatch = useDispatch();
  const contentRef = useRef<HTMLIonContentElement>(null);
  const showMeta = routeProps.match.url === routeProps.location.pathname;

  useIonViewWillEnter(() => {
    dispatch(setBottomNavigationDisplay(false));
  }, []);

  useIonViewWillLeave(() => {
    dispatch(setBottomNavigationDisplay(true));
  }, []);

  useEffect(() => {
    if (scrollToTop && contentRef?.current?.scrollToTop) {
      contentRef.current.scrollToTop(500);
    }
  }, [scrollToTop]);

  return (
    <IonPage>
      {showMeta && <MetaHead {...meta} />}

      <IonContent ref={contentRef}>
        <div className={classes.layout}>
          <IosSafeAreaTop />

          <div className={classes.layoutPadding}>
            <DesktopTopNavigation {...routeProps} />
            {!hideMobileHeader && <MobileHeader title={headerTitle} {...routeProps} />}
            {(contentHeader || contentHeaderTitle) && (
              <div className={classNames(DESKTOP_VIEW, classes.contentHeader)}>
                {contentHeaderTitle && <h1 className={classNames(textClasses.headerLBold)}>{contentHeaderTitle}</h1>}
                {contentHeader}
              </div>
            )}
          </div>

          <div className={classNames({ [classes.layoutPadding]: sidePadding, [classes.layoutCenter]: centerContent })}>
            {children}
          </div>

          <DesktopBottomNavigation footerClass={classNames({ [classes.unstickFooter]: centerContent })} />

          <IosSafeAreaBottom />
        </div>
      </IonContent>
      {footer}
    </IonPage>
  );
};

export default DetailsLayout;
