import { IonInput, IonLabel, IonText } from '@ionic/react';
import { TextFieldTypes } from '@ionic/core';
import classNames from 'classnames';

import classes from './FormInput.module.scss';
import textClasses from 'src/styles/text.module.scss';

interface InputProps {
  value: string | number | null;
  name: string;
  type?: TextFieldTypes;
  placeholder?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  staticLabel?: boolean;
  onChange?: (value: string | undefined | null) => void | Promise<void> | Promise<any>;
  onBlur?: (event: any) => void | Promise<void>;
}

const FormInput: React.FC<InputProps> = ({
  value,
  type,
  name,
  placeholder,
  error,
  required,
  disabled,
  staticLabel,
  onChange,
  onBlur,
}) => {
  const onInputChange = (e: CustomEvent) => {
    if (onChange) {
      onChange(e.detail.value);
    }
  };

  const onInputBlur = (e: any) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  // TODO: Autocomplete is temporary disabled, since there is a bug with IonInput - on autocomplete value pick, ion-input on change does not fire. Solution - replace IonInput with regular html input.
  return (
    <div className={classes.container}>
      <IonInput
        value={value}
        type={type}
        name={name}
        // @ts-ignore
        autocomplete="nope"
        onIonChange={onInputChange}
        onIonBlur={onInputBlur}
        className={classNames(classes.input, textClasses.title, {
          [classes.errorInput]: Boolean(error),
          [classes.inputHasValue]: Boolean(value),
          [classes.disabledInput]: disabled,
        })}
        required={required}
        disabled={disabled}
      >
        {Boolean(placeholder) && (
          <IonLabel className={classNames(classes.label, textClasses.title, { [classes.labelStatic]: staticLabel })}>
            {placeholder}
          </IonLabel>
        )}
      </IonInput>
      {Boolean(error) && <IonText className={classNames(classes.errorText, textClasses.textS)}>{error}</IonText>}
    </div>
  );
};

export default FormInput;
