import { IonContent, IonModal } from '@ionic/react';

import ComboCampaign from 'src/components/campaign/ComboCampaign';
import ComboCampaignProductList from 'src/components/campaign/ComboCampaignProductList';
import ComboCampaignEditComponents from 'src/components/campaign/ComboCampaignEditComponents';
import ComboCampaignEditComponentsFooter from 'src/components/campaign/ComboCampaignEditComponentsFooter';
import CloseButton from 'src/components/CloseButton';
import { useDispatch, useSelector } from 'src/store';
import {
  ComboCampaignModalViews,
  openComboCampaign,
  resetAllCampaignSelections,
  setComboCampaignModalView,
} from 'src/slices/campaign';
import classes from './ComboCampaignModals.module.scss';
import BackButton from '../BackButton';
import ComboCampaignFooter from '../campaign/ComboCampaignFooter';

const ComboCampaignModal: React.FC = () => {
  const dispatch = useDispatch();
  const { isComboCampaignOpened, comboCampaignModalView } = useSelector((state) => state.campaign);

  const onBackBtnClick = () => {
    dispatch(setComboCampaignModalView(ComboCampaignModalViews.MAIN));
  };

  const onModalDismiss = () => {
    dispatch(openComboCampaign(false));
    dispatch(setComboCampaignModalView(ComboCampaignModalViews.MAIN));
    dispatch(resetAllCampaignSelections());
  };

  return (
    <IonModal isOpen={isComboCampaignOpened} onDidDismiss={onModalDismiss} cssClass={classes.mainModal}>
      <IonContent>
        {(comboCampaignModalView === ComboCampaignModalViews.PRODUCT_LIST ||
          comboCampaignModalView === ComboCampaignModalViews.EDIT_COMPONENTS) && (
          <div className={classes.backBtnContainer}>
            <BackButton onClick={onBackBtnClick} />
          </div>
        )}
        <div className={classes.content}>
          {comboCampaignModalView === ComboCampaignModalViews.MAIN && <ComboCampaign />}
          {comboCampaignModalView === ComboCampaignModalViews.PRODUCT_LIST && <ComboCampaignProductList />}
          {comboCampaignModalView === ComboCampaignModalViews.EDIT_COMPONENTS && <ComboCampaignEditComponents />}
        </div>
        <div className={classes.closeBtnContainer}>
          <CloseButton onClose={onModalDismiss} />
        </div>
      </IonContent>
      {comboCampaignModalView === ComboCampaignModalViews.MAIN && <ComboCampaignFooter />}
      {comboCampaignModalView === ComboCampaignModalViews.EDIT_COMPONENTS && <ComboCampaignEditComponentsFooter />}
    </IonModal>
  );
};

export default ComboCampaignModal;
