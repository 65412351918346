import BaseApiModule, { IPaginationOptions } from './base';

export default abstract class BaseReadApiModule<T> extends BaseApiModule {
  protected abstract baseRoute: string;

  list(options?: IPaginationOptions) {
    return this.pagedRequest<T>(this.baseRoute, options);
  }

  getAll() {
    return this._get<T[]>(this.baseRoute);
  }

  get(id?: string | number) {
    if (typeof id === 'undefined') {
      return this._get<T>(this.baseRoute);
    }
    return this._get<T>(`${this.baseRoute}/${id}`);
  }
}
