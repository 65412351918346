import { CartItem, CartState } from 'src/slices/cart';
import { CampaignJoinInfoDto, OrderJoinDetailsDto, ProductJoinInfoDto } from 'src/types/generated';
import { getCampaignProductPrice } from './campaign';
import { SelectedCampaignProduct } from 'src/slices/campaign';

// Google events https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_item_list

// Facebook https://www.facebook.com/business/m/one-sheeters/facebook-pixel-events
// Facebook event params https://developers.facebook.com/docs/meta-pixel/implementation/conversion-tracking/

const getCampaignPrice = (campaignProducts: SelectedCampaignProduct[], products: ProductJoinInfoDto[]) => {
  return (campaignProducts || [])
    .filter((sp) => sp.isFreeSlot === false)
    .map(({ productId, variantId, partials }) => getCampaignProductPrice({ productId, variantId, products, partials }))
    .reduce((totalPrice, price) => totalPrice + price, 0);
};

const normalizeJSFloat = (num: number) =>
  parseFloat((typeof num === 'number' && !Number.isNaN(num) ? num : 0)?.toFixed(2));

const currency = 'EUR' as const;

const getCartItemPrice = (cartItem: CartItem, products: ProductJoinInfoDto[], campaigns: CampaignJoinInfoDto[]) => {
  const product = cartItem.isProduct && cartItem.productId && products.find((p) => p.id === cartItem.productId);
  const campaign = cartItem.isCampaign && cartItem.campaignId && campaigns.find((c) => c.id === cartItem.campaignId);

  let price = 0;
  if (product) {
    price = getCampaignProductPrice({
      products,
      partials: cartItem.partials,
      productId: product.id,
      product,
      variantId: cartItem.variantIds[0],
    });
  } else if (campaign) {
    price = getCampaignPrice(cartItem.products, products);
  }
  return { price: normalizeJSFloat(price), product, campaign };
};

export const trackProductView = (product: ProductJoinInfoDto) => {
  window.gtag('event', 'view_item', {
    currency,
    value: product.price,
    items: [
      {
        item_id: product.id,
        item_name: product.name,
        price: product.price,
      },
    ],
  });

  window.fbq('track', 'ViewContent', {
    currency,
    content_name: product.name,
    content_ids: [product.id],
    contents: [{ id: product.id, title: product.name, price: product.price }],
    value: product.price,
  });
};

export const trackCampaignView = (campaign: CampaignJoinInfoDto) => {
  window.gtag('event', 'view_item', {
    currency,
    value: 0,
    items: [
      {
        item_id: campaign.id,
        item_name: campaign.name,
      },
    ],
  });

  window.fbq('track', 'ViewContent', {
    currency,
    content_name: campaign.name,
    content_ids: [campaign.id],
    contents: [{ id: campaign.id, title: campaign.name, price: 0 }],
    value: 0,
  });
};

export const trackItemAddedToCart = (
  cartItem: CartItem,
  products: ProductJoinInfoDto[],
  campaigns: CampaignJoinInfoDto[]
) => {
  const { price, product, campaign } = getCartItemPrice(cartItem, products, campaigns);
  const itemName = product?.name ?? campaign.name ?? 'name not found';
  const id = product?.id ?? campaign?.id ?? cartItem.uniqId;
  window.gtag('event', 'add_to_cart', {
    currency,
    value: normalizeJSFloat(price * (cartItem.quantity ?? 1)),
    items: [
      {
        item_id: id,
        item_name: itemName,
        price,
        item_variant: product?.ektacoCode,
        quantity: cartItem.quantity,
      },
    ],
  });

  window.fbq('track', 'AddToCart', {
    currency,
    content_name: itemName,
    content_ids: [id],
    contents: [{ id, quantity: cartItem.quantity, price, title: itemName, variant: product?.ektacoCode }],
    value: normalizeJSFloat(price * (cartItem.quantity ?? 1)),
  });
};

export const trackItemRemovedFromCart = (
  cartItem: CartItem,
  products: ProductJoinInfoDto[],
  campaigns: CampaignJoinInfoDto[]
) => {
  const { price, product, campaign } = getCartItemPrice(cartItem, products, campaigns);
  const itemName = product?.name ?? campaign.name ?? 'name not found';
  const id = product?.id ?? campaign?.id ?? cartItem.uniqId;
  window.gtag('event', 'remove_from_cart', {
    currency,
    value: normalizeJSFloat(price * (cartItem.quantity ?? 1)),
    items: [
      {
        item_id: id,
        item_name: itemName,
        price,
        item_variant: product?.ektacoCode,
        quantity: cartItem.quantity,
      },
    ],
  });
};

export const trackBeginCheckout = (
  cart: CartState,
  products: ProductJoinInfoDto[],
  campaigns: CampaignJoinInfoDto[],
  total: number
) => {
  window.gtag('event', 'begin_checkout', {
    currency,
    value: normalizeJSFloat(total),
    items: cart.cartItems.map((cartItem) => {
      const { price, product, campaign } = getCartItemPrice(cartItem, products, campaigns);
      return {
        item_id: product?.id ?? campaign?.id ?? cartItem?.uniqId,
        item_name: product?.name ?? campaign.name ?? 'name not found',
        quantity: cartItem.quantity,
        item_variant: product?.ektacoCode,
        price,
      };
    }),
  });

  const contents = cart.cartItems.map((cartItem) => {
    const { price, product, campaign } = getCartItemPrice(cartItem, products, campaigns);
    const id = product?.id ?? campaign?.id ?? cartItem?.uniqId;
    const productName = product?.name ?? campaign.name ?? 'name not found';
    return {
      id,
      title: productName,
      price,
      quantity: cartItem.quantity,
      variant: product?.ektacoCode,
    };
  });

  window.fbq('track', 'InitiateCheckout', {
    currency,
    content_ids: contents.map((c) => c.id),
    contents,
    value: normalizeJSFloat(total),
    num_items: cart.cartItems.length,
  });
};

export const trackPurchase = (order: OrderJoinDetailsDto, products: ProductJoinInfoDto[]) => {
  if (order.status === 'unpaid') return;

  const getProductPrice = (orderItem: OrderJoinDetailsDto['items'][number]) => {
    const product = (products || []).find((p) => p.id === orderItem.productId);
    const price =
      (orderItem.isFreeCampaignProduct
        ? 0
        : product &&
          getCampaignProductPrice({
            products,
            partials: orderItem.partials,
            productId: product.id,
            product,
            variantId: orderItem.variants?.[0]?.variantId,
          })) ?? orderItem.price;
    return normalizeJSFloat(price);
  };

  window.gtag('event', 'purchase', {
    currency,
    transaction_id: order.id,
    value: order.totalPrice,
    items: order.items.map((orderItem) => ({
      item_id: orderItem.productId,
      item_name: orderItem.name,
      quantity: orderItem.quantity,
      item_variant: orderItem.ektacoCode,
      price: getProductPrice(orderItem),
    })),
  });

  window.fbq('track', 'Purchase', {
    currency,
    value: order.totalPrice,
    contents: order.items.map((orderItem) => ({
      id: orderItem.productId,
      title: orderItem.name,
      quantity: orderItem.quantity,
      price: getProductPrice(orderItem),
      variant: orderItem.ektacoCode,
    })),
    contents_ids: order.items.map((c) => c.productId),
    num_items: order.items.length,
  });
};
