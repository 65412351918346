import { IonButton, IonIcon, IonText } from '@ionic/react';
import classNames from 'classnames';
import { locationOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'src/store';
import { useSelectedSalePointGetter } from 'src/hooks';
import { openModal } from 'src/slices/salePoint';
import btnClasses from 'src/styles/buttons.module.scss';
import textClasses from 'src/styles/text.module.scss';
import classes from './SalePointPickerButton.module.scss';

const SalePointPickerButton: React.FC = () => {
  const dispatch = useDispatch();
  const salePoint = useSelectedSalePointGetter();
  const { t } = useTranslation();
  return (
    <IonButton
      color="white"
      shape="round"
      className={classNames(btnClasses.outlineBtn, textClasses.headerS, classes.btn, {
        [classes.btnPulsing]: !salePoint,
      })}
      onClick={() => dispatch(openModal(true))}
    >
      {!!salePoint && (
        <>
          <IonIcon slot="start" icon={locationOutline} className={classes.btnIcon} />
          <IonText className={classes.btnText}>{salePoint?.name}</IonText>
        </>
      )}
      {!salePoint && <IonText className={classes.btnText}>{t('Choose location')}</IonText>}
    </IonButton>
  );
};

export default SalePointPickerButton;
