import { useEffect, useState } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { useDispatch, useSelector } from 'src/store';
import { getStorageItem } from 'src/utils/storage';
import { getSalePoints, selectSalePoint, openModal } from 'src/slices/salePoint';
import { getLanguages, getStaticPages, selectLanguage, setAppInitialization } from 'src/slices/system';
import { getVariants } from 'src/slices/product';
import { initAuth } from 'src/slices/auth';
import { clearCartStorage, initCart } from 'src/slices/cart';
import { isIntId } from 'src/utils';
import useBreakpoint from './useBreakpoint';
import wait from 'src/utils/wait';
import { ClientDto, LanguageDto } from 'src/types/generated';
import { DEFAULT_LOCALE } from 'src/constants';
import { DeviceService } from 'src/utils/nativeServices';
import { shouldRestoreCartFromStorage } from 'src/utils/order';

i18n.use(initReactI18next).init({
  resources: {},
  lng: DEFAULT_LOCALE,
  fallbackLng: DEFAULT_LOCALE,
  interpolation: {
    escapeValue: false,
  },
});

const useDataLoading = () => {
  const dispatch = useDispatch();
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const { isDesktopView } = useBreakpoint();
  const { isAppInitialized, hasInternetConnection } = useSelector((state) => state.system);

  useEffect(() => {
    const init = async () => {
      try {
        const languages = (await dispatch(getLanguages())) as unknown as LanguageDto[];
        const user = (await dispatch(initAuth())) as unknown as ClientDto;
        const storageSalePointId = await getStorageItem('selectedSalePointId');
        const storageLanguageId = await getStorageItem('selectedLanguageId');
        const mainPagePaths = ['/', '/tabs', '/tabs/', '/tabs/menu', '/tabs/menu/'];
        const isOnMainPage = mainPagePaths.includes(window.location.pathname);

        const deviceLanguage = await DeviceService.getDeviceLanguage(languages);
        const selectedSalePointId = user?.favoriteSalePointId || storageSalePointId;
        const selectedLanguageId = user?.languageId || Number(storageLanguageId) || deviceLanguage?.id;
        const restoreCartFromStorage = await shouldRestoreCartFromStorage();

        if (isIntId(selectedLanguageId)) {
          await dispatch(selectLanguage({ languageId: selectedLanguageId, saveSelection: false }));
        }
        await dispatch(getSalePoints());
        await dispatch(getVariants());
        if (isDesktopView) await dispatch(getStaticPages());
        // set default salepoint on load, only if user has unfinished order (has cart items in local storage).
        if (restoreCartFromStorage && isIntId(selectedSalePointId)) {
          await dispatch(selectSalePoint({ salePointId: Number(selectedSalePointId), saveSelection: false }));
          await dispatch(initCart());
        } else if (isOnMainPage) {
          // Next line is skipping modal animation on opening.
          // This will avoid situation when mobile user first will see a main menu (for a short duration)
          // and only then an opening modal.
          await dispatch(openModal(true));
          await dispatch(clearCartStorage());
          await wait(400);
        }
        // @ts-ignore
        if (!window?.DokobitIdentity) {
          const script = document.createElement('script');
          script.src = `${process.env.REACT_APP_DOKOBIT_URL}/js/dokobit-integration.min.js`;
          document.body.appendChild(script);
        }
        await dispatch(setAppInitialization(true));
      } catch (error) {
        console.error('Error on loading initial app data: ', error);
      }
      setIsDataLoaded(true);
    };
    if (!isAppInitialized) {
      init();
    }
  }, [isAppInitialized, hasInternetConnection]);

  return { isDataLoaded };
};

export const i18next = i18n;
export default useDataLoading;
