import { IonButton, IonContent, IonIcon, IonPage, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import logo from 'src/assets/img/pizzakiosk-logo.svg';
import pizzaIcon from 'src/assets/img/pizza-slice.svg';
import deliveringImg from 'src/assets/img/pizza-delivering.svg';
import classes from './ErrorBoundaryFallback.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';
import textClasses from 'src/styles/text.module.scss';

const ErrorBoundaryFallback: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonContent>
        <div className={classes.content}>
          <IonIcon src={logo} className={classes.logo} color="primary" />
          <div className={classNames(textClasses.title, 'ion-text-center')}>
            <p className={textClasses.title}>{t('Something went wrong')}.</p>
            <p className={textClasses.title}>{t('Last action caused some error')}.</p>
          </div>
          <IonIcon src={deliveringImg} className={classes.deliveringImg} color="primary" />
          <IonButton
            className={classNames(btnClasses.outlineBtn, classes.btn)}
            color="primary"
            shape="round"
            onClick={() => window.location.reload()}
          >
            <IonIcon slot="start" src={pizzaIcon} color="white" className={classes.btnIcon} />
            <IonText className={classNames(classes.btnText, textClasses.headerS)}>{t('Reload application')}</IonText>
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ErrorBoundaryFallback;
