import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IonAlert, IonButton, IonIcon, IonItem, IonList, IonText, useIonViewWillEnter } from '@ionic/react';
import classNames from 'classnames';

import LoadingOverlay from 'src/components/LoadingOverlay';
import ReorderButton from 'src/components/profile/ReorderButton';
import { VIP_ORDERS_LIMIT } from 'src/constants';
import { useDispatch, useSelector } from 'src/store';
import { useBreakpoint } from 'src/hooks';
import { getVips, deleteVip, getVipDetails } from 'src/slices/vip';
import { formatPrice } from 'src/utils';
import classes from './VIP.module.scss';
import textClasses from 'src/styles/text.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';
import trashIcon from 'src/assets/img/trash.svg';
import { ClientVipJoinDetailsDto } from 'src/types/generated';

interface VIPPageProps extends RouteComponentProps {}

const VIP: React.FC<VIPPageProps> = ({ history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobileView } = useBreakpoint();
  const vipOrders = useSelector((state) => state.vip.vips);
  const { vips: isVipsLoading, vipDetails: isVipDetailsLoading } = useSelector((state) => state.vip.loading);
  const [showVipDeleteAlert, setShowVipDeleteAlert] = useState<boolean>(false);
  const [deleteVipId, setDeleteVipId] = useState<number>(null);

  useIonViewWillEnter(() => {
    if (!vipOrders) {
      dispatch(getVips());
    }
  }, [vipOrders]);

  const onVipDeleteBtnClick = (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>, vipId: number) => {
    e.stopPropagation();
    e.preventDefault();
    setDeleteVipId(vipId);
    setShowVipDeleteAlert(true);
  };

  const onAlertDismiss = (e: CustomEvent) => {
    setShowVipDeleteAlert(false);
    if (e.detail.role === 'confirm' && deleteVipId) {
      dispatch(deleteVip(deleteVipId));
    }
  };

  const getOrderProducts = async (vipOrderId: number) => {
    const vipDetails = (await dispatch(getVipDetails(vipOrderId))) as unknown as ClientVipJoinDetailsDto;
    return vipDetails.products;
  };

  const onReordered = () => {
    if (isMobileView) {
      history.goBack();
    }
  };

  return (
    <div>
      {(isVipsLoading || isVipDetailsLoading) && <LoadingOverlay backdrop spinnerColor="primary" />}
      {vipOrders?.length === 0 && (
        <div>
          <h3 className={classNames(textClasses.textLBold, 'ion-text-center', 'ion-margin-vertical')}>
            {t('You do not have any saved VIP orders')}
          </h3>
          <p className={classNames(textClasses.textM, 'ion-text-center', 'ion-margin-vertical')}>
            <IonText color="medium">
              {t('VIP (Very Important Pizza) helps you save your favorite orders and re-order them with a few clicks')}.
            </IonText>
          </p>
        </div>
      )}
      {vipOrders?.length > 0 && (
        <>
          <div className={classes.headerContainer}>
            <h2 className={classNames(textClasses.titleBold, classes.title)}>{t('Saved orders')}</h2>
            <p className={classNames(textClasses.textL, classes.vipCountText)}>{`(${
              vipOrders?.length || 0
            }/${VIP_ORDERS_LIMIT})`}</p>
          </div>
          <IonList className={classes.vipList}>
            {vipOrders.map((vip) => (
              <IonItem
                key={vip.id}
                detail={false}
                lines="none"
                className={classes.vipItem}
                button
                routerLink={`/tabs/profile/vip/${vip.id}`}
              >
                <div className={classes.vipItemContent}>
                  <div className={classes.vipItemHeader}>
                    <div className={classes.vipItemHeaderTexts}>
                      <h3 className={classNames(textClasses.headerMBold, classes.vipItemTitle)}>{vip.name}</h3>
                      <IonText color="medium">
                        <p className={classNames(textClasses.textSBold, classes.vipItemDescription)}>
                          {vip.description}
                        </p>
                      </IonText>
                    </div>
                    <IonButton
                      color="white"
                      className={classNames(btnClasses.controlBtn, classes.deleteBtn)}
                      onClick={(e) => onVipDeleteBtnClick(e, vip.id)}
                    >
                      <IonIcon color="dark" slot="icon-only" src={trashIcon} className={classes.deleteBtnIcon} />
                    </IonButton>
                  </div>
                  <div className={classes.vipItemFooter}>
                    <IonText color="primary" className={classNames(textClasses.textL, classes.price)}>
                      {formatPrice(vip.price)}
                    </IonText>
                    <ReorderButton getOrderProducts={() => getOrderProducts(vip.id)} onReordered={onReordered} />
                  </div>
                </div>
              </IonItem>
            ))}
          </IonList>
        </>
      )}
      <IonAlert
        isOpen={showVipDeleteAlert}
        onDidDismiss={onAlertDismiss}
        header={t('Warning')}
        message={t('Do you really want delete this VIP?')}
        buttons={[
          {
            text: t('Cancel'),
            role: 'cancel',
          },
          {
            text: t('Confirm'),
            role: 'confirm',
          },
        ]}
      />
    </div>
  );
};

export default VIP;
