import { useTranslation } from 'react-i18next';
import { IonButton, IonText } from '@ionic/react';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'src/store';
import { useBreakpoint } from 'src/hooks';
import {
  ComboCampaignModalViews,
  openComboCampaignProductComponents,
  selectSlotProduct,
  setComboCampaignModalView,
} from 'src/slices/campaign';
import { resetProductSelections } from 'src/slices/product';
import { getCampaignProductPrice } from 'src/utils/campaign';
import { formatPrice } from 'src/utils';
import classes from './ComboCampaignEditComponentsFooter.module.scss';
import btnClasses from 'src/styles/buttons.module.scss';
import textClasses from 'src/styles/text.module.scss';

const ComboCampaignEditComponentsFooter: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isDesktopView, isMobileView } = useBreakpoint();
  const { productComponentQuantities, productExtraComponentQuantities, products } = useSelector(
    (state) => state.product
  );
  const { selectedSlotProducts, slotParameters } = useSelector((state) => state.campaign);

  const { isFreeSlot, slotNumber } = slotParameters;
  const selectedSlot = selectedSlotProducts.find((sp) => sp.isFreeSlot === isFreeSlot && sp.slotNumber === slotNumber);
  const partials = productComponentQuantities
    .filter((component) => component.qty !== 1)
    .map((component) => ({
      partialProductId: component.componentId,
      quantity: component.qty > 0 ? component.qty - 1 : 0,
      slotId: selectedSlot.slotId,
      isExtraComponent: false,
    }))
    .concat(
      productExtraComponentQuantities
        .filter((component) => component.qty > 0)
        .map((component) => ({
          partialProductId: component.componentId,
          quantity: component.qty,
          slotId: selectedSlot.slotId,
          isExtraComponent: true,
        }))
    );

  const productPrice = getCampaignProductPrice({
    productId: selectedSlot.productId,
    variantId: selectedSlot.variantId,
    products,
    partials,
  });

  const onSaveBtnClick = () => {
    dispatch(selectSlotProduct({ ...selectedSlot, partials }));
    if (isDesktopView) {
      dispatch(setComboCampaignModalView(ComboCampaignModalViews.MAIN));
    }
    if (isMobileView) {
      dispatch(openComboCampaignProductComponents(false));
    }
    dispatch(resetProductSelections(selectedSlot.productId));
  };

  return (
    <div className={classes.stickyFooter}>
      <div className={classes.container}>
        <IonButton className={classNames(btnClasses.primaryBtn, classes.saveBtn)} onClick={onSaveBtnClick}>
          <IonText className={classNames(textClasses.textL)}>
            {t('Save')} {formatPrice(productPrice)}
          </IonText>
        </IonButton>
      </div>
    </div>
  );
};

export default ComboCampaignEditComponentsFooter;
