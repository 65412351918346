import BaseReadApiModule from 'src/api/baseRead';
import { SystemTranslationDto } from 'src/types/generated';

class SystemTranslationsApi extends BaseReadApiModule<SystemTranslationDto> {
  protected baseRoute = '/system-translations';

  public getByLanguageId(languageId: number | string): Promise<SystemTranslationDto[]> {
    return this._get(`${this.baseRoute}/language/${languageId}`);
  }
}

export default SystemTranslationsApi;
